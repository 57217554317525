import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpModule, JsonpModule } from '@angular/http';
import { RouterModule, Routes } from '@angular/router';

import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  MatButtonModule, MatCardModule, MatMenuModule, MatToolbarModule, MatIconModule, MatTableModule,
  MatSliderModule, MatInputModule, MatCheckboxModule, MatFormFieldModule, MatSnackBarModule, MatDialogModule,
  MatSelectModule, MatTabsModule, MatPaginatorModule 
} from '@angular/material'
import { NgxPaginationModule } from 'ngx-pagination';
import { ChartsModule } from 'ng2-charts';
import { NvD3Module } from 'ng2-nvd3';

import 'd3';
import 'nvd3';

import { enableProdMode } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

import { DataserviceService } from './services/dataservice.service';
import { CalendarService } from './services/calendar.service';
import { GameInformationService } from './services/game-information.service';
import { MessageHandlerService } from './services/message-handler.service';

import { AppComponent } from './app.component';
import { LoginPageComponent } from './components/login-page/login-page.component';
import { HomePageComponent, CreatePatientDialog, PopupGraphDialog } from './components/home-page/home-page.component';
import { QrCodeComponent } from './components/qr-code/qr-code.component';
import { ContactsComponent } from './components/contacts/contacts.component';
import { PlanActivityComponent, ActivityInfoDialog } from './components/plan-activity/plan-activity.component';
import { OverviewActivityComponent, ResultsViewDialog } from './components/overview-activity/overview-activity.component';
import { MessageComponent } from './components/message/message.component';
import { PlanAssessmentComponent, AssessmentInfoDialog } from './components/plan-assessment/plan-assessment.component';
import { ResultOverviewComponent } from './components/result-overview/result-overview.component';
import { MeasurementsComponent } from './components/measurements/measurements.component';
import { DiaryComponent } from './components/diary/diary.component';
import { VideoComponent } from './components/video/video.component';
import { SettingsComponent } from './components/settings/settings.component';
import { MenuComponent } from './menu/menu.component';
import { SideContentComponent } from './side-content/side-content.component';

import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ModalModule } from 'ngx-bootstrap/modal';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { SideContentService } from './side-content/side-content.service';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

enableProdMode();

const appRoutes: Routes = [
  { path: '', component: LoginPageComponent },
  { path: 'home', component: HomePageComponent },
  { path: 'home/settings', component: SettingsComponent },
  { path: 'schedule/exercises', component: PlanActivityComponent },
  { path: 'schedule/assessment', component: PlanAssessmentComponent },
  { path: 'overview', component: OverviewActivityComponent },
  { path: 'overview/results', component: ResultOverviewComponent },
  { path: 'overview/measurements', component: MeasurementsComponent },
  { path: 'overview/diary', component: DiaryComponent },
  { path: 'contact', component: ContactsComponent },
  { path: 'contact/message', component: MessageComponent },
  { path: 'contact/video', component: VideoComponent },
  { path: 'qr', component: QrCodeComponent },
];

@NgModule({
  declarations: [
    AppComponent,
    LoginPageComponent,
    HomePageComponent,
    QrCodeComponent,
    ContactsComponent,
    CreatePatientDialog,
    PopupGraphDialog,
    PlanActivityComponent,
    ActivityInfoDialog,
    OverviewActivityComponent,
    MessageComponent,
    PlanAssessmentComponent,
    AssessmentInfoDialog,
    ResultsViewDialog,
    ResultOverviewComponent,
    MeasurementsComponent,
    DiaryComponent,
    VideoComponent,
    SettingsComponent,
    MenuComponent,
    SideContentComponent
  ],
  imports: [
    BrowserModule,
    HttpModule,
    JsonpModule,
    RouterModule.forRoot(appRoutes),
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatSelectModule,
    MatSliderModule,
    MatSnackBarModule,
    MatTabsModule,
    MatPaginatorModule ,
    BrowserAnimationsModule,
    FormsModule,
    NgxPaginationModule,
    ChartsModule,
    NvD3Module,
    BsDropdownModule.forRoot(),
    TooltipModule.forRoot(),
    ModalModule.forRoot(),
    NgbModule,
    NgMultiSelectDropDownModule.forRoot(),
    MatTableModule
  ],
  providers: [DataserviceService, MessageHandlerService, GameInformationService, CookieService, CalendarService, SideContentService],
  bootstrap: [AppComponent],
  entryComponents: [CreatePatientDialog, ActivityInfoDialog, AssessmentInfoDialog, ResultsViewDialog, PopupGraphDialog]
})
export class AppModule { }
