import {
    NgbDatepicker,
    NgbInputDatepicker,
    NgbDateStruct,
    NgbCalendar,
    NgbDate,
    NgbDateAdapter,
    NgbDateParserFormatter,
    NgbDatepickerI18n
} from '@ng-bootstrap/ng-bootstrap';

const I18N_VALUES = {
    'se': {
        weekdays: ['Må', 'Ti', 'On', 'To', 'Fr', 'Lö', 'Sö'],
        months: ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Juni', 'Juli', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    }
    // other languages you would support
};

const NmbrOfDaysExerciseStillDoable : number = 1;

export default class Utils {

    static GetNmbrOfDaysExerciseStillDoable() {
        return NmbrOfDaysExerciseStillDoable;
    }

    static NgbDateToNgbDateStruct(ngbDate: NgbDate) {
        const date: NgbDateStruct = { year: ngbDate.year, month: ngbDate.month, day: ngbDate.day };
        return date;
    }

    static NgbDateStructToNgbDate(ngbDateStruct: NgbDateStruct) {
        return new NgbDate(ngbDateStruct.year, ngbDateStruct.month, ngbDateStruct.day);
    }

    static NgbDateToJSDate(ngbDate: NgbDate) {
        let date = new Date();
        date.setDate(ngbDate.day);
        date.setMonth(ngbDate.month - 1);
        date.setFullYear(ngbDate.year);
        return date;
    }

    static JSDateToNgbDate(date: Date) {
        if (date == null) return null;
        let ngbDate = new NgbDate(date.getFullYear(), date.getMonth() + 1, date.getDate());
        return ngbDate;
    }

}