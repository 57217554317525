import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DataserviceService } from '../../services/dataservice.service';
import { MessageHandlerService } from '../../services/message-handler.service';

@Component({
  selector: 'app-diary',
  templateUrl: './diary.component.html',
  styleUrls: ['./diary.component.css']
})
export class DiaryComponent implements OnInit {
  showWarning: boolean = false;

  listOfQuestionaries: any[];
  filteredQuestionaries: any[];
  listOfDates: string[];
  filteredDates: string[];
  showSpecificQuestionair: boolean = false;

  title: string;
  mood: number;
  comment: string;
  weight: number;
  temp: number;
  pulse: number;
  saturation: number;
  performedExercise: boolean;
  exerciseInfo: string;
  activities: string;
  durationTime: number;
  exerciseComment: string;

  constructor(private dataservice: DataserviceService, private router: Router, private mhs: MessageHandlerService) { }

  ngOnInit() {
    this.showWarning = this.dataservice.patient == "Ingen vald";
    this.showSpecificQuestionair = false;
    if (!this.showWarning) {
      this.getAllQuestionairs();
    }
  }

  private getAllQuestionairs() {
    this.dataservice.getDiariesFromDate(Date.now()).subscribe(
      (resp) => {
        if (resp && resp.status == 200) {
          let data = resp.json();
          this.listOfQuestionaries = new Array();
          for (let entry of data){
            let day = new Date(entry.date);
            this.listOfQuestionaries.push({
              mood: entry.data.mood,
              weight: entry.data.weight,
              temperature: entry.data.temp,
              pulse: entry.data.pulse,
              saturation: entry.data.saturation,
              comment: entry.data.comment,
              activities: entry.data.activities,
              exerciseComment: entry.data.exerciseComment,
              estimatedTime: entry.data.estimatedTime,
              performedExercise: entry.data.haveExercised,
              date: day.getFullYear() + "-" + (day.getMonth()+1) + "-" + day.getDate(),
              timestamp: entry.date
            });
          }
          this.filteredQuestionaries = this.listOfQuestionaries.sort(function (a, b) {
            return b.timestamp - a.timestamp;
          });;

          if (data.length >= 100){
            let lastDate = this.filteredQuestionaries.reduce(
              (a, b) => a.timestamp < b.timestamp ? a : b
            ).timestamp;
            this.loadMoreDiaries(lastDate);
          }
        }
      });
  }

  private loadMoreDiaries(date : number){
    this.dataservice.getDiariesFromDate(date).subscribe(
      (resp) => {
        if (resp && resp.status == 200) {
          let data = resp.json();
          if (data == null || data == "") { return; }
          for (let entry of data){
            let day = new Date(entry.date);
            this.listOfQuestionaries.push({
              mood: entry.data.mood,
              weight: entry.data.weight,
              temperature: entry.data.temp,
              pulse: entry.data.pulse,
              saturation: entry.data.saturation,
              comment: entry.data.comment,
              activities: entry.data.activities,
              exerciseComment: entry.data.exerciseComment,
              estimatedTime: entry.data.estimatedTime,
              performedExercise: entry.data.haveExercised,
              date: day.getFullYear() + "-" + (day.getMonth()+1) + "-" + day.getDate(),
              timestamp: entry.date
            });
          }
          this.filteredQuestionaries = this.listOfQuestionaries.sort(function (a, b) {
            return b.timestamp - a.timestamp;
          });;
          if (data.length > 100){
            let lastDate = this.filteredQuestionaries.reduce(
              (a, b) => a.timestamp < b.timestamp ? a : b
            ).timestamp;
            this.loadMoreDiaries(lastDate);
          }
        }
      });
  }

  private displaySpecificQuestionair(data: any) {
    this.mood = data.mood;
    this.comment = data.comment;
    this.weight = data.weight;
    this.temp = data.temperature;
    this.pulse = data.pulse;
    this.saturation = data.saturation;
    this.performedExercise = data.performedExercise;
    this.exerciseInfo = data.performedExercise ? 'Ja' : 'Nej';
    this.activities = this.getActivityTypes(data);
    this.durationTime = data.estimatedTime;
    this.exerciseComment = data.exerciseComment;
  }

  private getActivityTypes(data: any): string {
    let str = "";
    let parts = data.activities.split(", ");
    for (let i = 1; i < parts.length; i++) {
      if (parts[i] == "RUNNING") {
        str = str + "Löpning, ";
      }
      else if (parts[i] == "WALKING") {
        str = str + "Gång, ";
      }
      else if (parts[i] == "BIKING") {
        str = str + "Cykling, ";
      }
      else if (parts[i] == "GAME_REHAB") {
        str = str + "Spel-rehabilitering, ";
      }
      else if (parts[i] == "SWIMMING") {
        str = str + "Simning, ";
      }
      else if (parts[i] == "GYM") {
        str = str + "Gym, ";
      }
      else {
        str = str + parts[i] + ", ";
      }
    }
    return str.substring(0, str.length - 2);
  }

  goBack() {
    this.showSpecificQuestionair = false;
    return false;
  }

  filterDates(event: any) {
    if (!event.target.value || event.target.value == 'undefined') {
      this.filteredQuestionaries = this.listOfQuestionaries;
      return false;
    }
    this.filteredQuestionaries = new Array();
    for (let quest of this.listOfQuestionaries) {
      if (quest.date.indexOf(event.target.value) >= 0) {
        this.filteredQuestionaries.push(quest);
      }
    }
    return false;
  }

  dateClick(item: any) {
    this.title = item.date;
    this.showSpecificQuestionair = true;

    this.displaySpecificQuestionair(item);
  }
}
