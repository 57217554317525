import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DataserviceService } from '../../services/dataservice.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { MessageHandlerService } from '../../services/message-handler.service';
import { SideContentService } from '../../side-content/side-content.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {
  @ViewChild('fileInput', { static: true }) fileInput;
  menuIcon: string;
  title: string = "Välj användare";
  username: string;
  patient: string;
  newMessage: boolean = false;
  patients: any[];
  p: number = 1;
  mObs;
  showPatients: boolean = true;
  current_pas: string;
  new_pas: string;
  repeate_pas: string;
  incorrect: boolean = false;
  incorrectFormat: boolean = false;
  warning: string;
  base64textString: string;
  fileName: string = 'Ladda upp bild';
  showWarning: boolean = false;

  constructor(private dataservice: DataserviceService, private router: Router, private dialog: MatDialog,
    public snackBar: MatSnackBar, private mhs: MessageHandlerService, private sideContentService: SideContentService) { }

  ngOnInit() {
  }

  changePassword() {
    this.incorrect = false;
    this.incorrectFormat = false;
    if (!this.current_pas || this.current_pas == 'undefined' || !this.new_pas || this.new_pas == 'undefined' ||
      !this.repeate_pas || this.repeate_pas == 'undefined') {
      this.warning = "Fyll i alla fälten!";
      this.incorrect = true;
      return;
    }
    else if (this.current_pas != this.dataservice.identification) {
      this.warning = "Felaktigt lösenord!";
      this.incorrect = true;
      return;
    }
    else if (this.new_pas != this.repeate_pas) {
      this.warning = "Upprepningen stämmer inte överens!";
      this.incorrect = true;
      return;
    }
    else if (this.new_pas.length < 4 || this.new_pas.length > 16 || this.new_pas.includes(':') || this.new_pas.includes('.')
      || this.new_pas.includes(',') || this.new_pas.includes('?') || this.new_pas.includes(';')) {
      this.warning = "Lösenordet måste vara mellan 4-16 tecken och inte innehålla .,:;? tecken";
      this.incorrect = true;
      return;
    }

    let settings = {
      password: this.new_pas
    };
    this.dataservice.changePassword(settings).subscribe(
      (resp) => {
        if (resp.status == 200) {
          this.mhs.changePassword(settings).subscribe(
            (resp) => {
              if (resp.status == 200) {
                this.dataservice.identification = this.new_pas;
                this.new_pas = '';
                this.current_pas = '';
                this.repeate_pas = '';
                this.showPatients = true;
                this.snackBar.open('Lösenordet är ändrat', 'Sparat', { duration: 4500 });
              }
              else {
                this.snackBar.open('Något gick fel, försök igen eller kontakta support!', 'Error', { duration: 4500 });
                let redo = {
                  password: this.dataservice.identification
                };
                this.dataservice.changePassword(redo).subscribe(
                  (resp) => {
                  });
              }
            });
        }
        else {
          this.snackBar.open('Något gick fel, försök igen eller kontakta support!', 'Error', { duration: 4500 });
        }
      });
  }

  fileChosen() {
    let fileBrowser = this.fileInput.nativeElement;
    if (fileBrowser.files && fileBrowser.files[0]) {
      this.fileName = fileBrowser.files[0].name;
    }
    else {
      this.fileName = 'Ladda upp bild';
    }
  }

  uploadPicture() {
    this.incorrect = false;
    this.incorrectFormat = false;
    let fileBrowser = this.fileInput.nativeElement;
    if (fileBrowser.files && fileBrowser.files[0]) {
      var file = fileBrowser.files[0];
      if (file.name.includes('png') || file.name.includes('jpeg') || file.name.includes('jpg')) {
        var reader = new FileReader();
        reader.onload = this._handleReaderLoaded.bind(this);
        reader.readAsBinaryString(fileBrowser.files[0]);
      }
      else {
        this.incorrectFormat = true;
        this.warning = 'Felaktigt format! Välj en png eller jpeg bild för uppladdning.'
      }
    }
  }

  _handleReaderLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64textString = btoa(binaryString);

    if (this.byteCount(this.base64textString) > 16 * 1000000) { // Check that the base64 encoded string is < 16MB. 
      this.incorrectFormat = true;
      this.warning = 'Bilden är för stor för uppladdning.';
      return;
    }

    var body = {
      picture: this.base64textString
    };
    this.mhs.changeImage(body).subscribe(
      (resp) => {
        if (resp.status != 200) {
          this.incorrectFormat = true;
          this.warning = 'Något gick fel vid uppladdning. Försök igen eller kontakta support.'
        }
        else {
          this.dataservice.userImg = body.picture;
          this.sideContentService.updateImage(body.picture);
        }
      }
    );
  }

  byteCount(s) {
    return encodeURI(s).split(/%..|./).length - 1;
  }
}
