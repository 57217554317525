import { Injectable, Output, EventEmitter } from '@angular/core'

@Injectable()
export class SideContentService {

  @Output() changeImage: EventEmitter<String> = new EventEmitter();

  updateImage(newImage:String) {
    this.changeImage.emit(newImage);
  }

}