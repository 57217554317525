import { Component, OnInit, Inject, ViewChild, ElementRef, Injectable} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { DataserviceService } from '../../services/dataservice.service';
import { GameInformationService } from '../../services/game-information.service';
import { MessageHandlerService } from '../../services/message-handler.service';
import {NgbDate, NgbCalendar, NgbDatepickerI18n, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import Utils from '../utils';

const I18N_VALUES = {
  'se': {
    weekdays: ['Må', 'Ti', 'On', 'To', 'Fr', 'Lö', 'Sö'],
    months: ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Juni', 'Juli', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  }
  // other languages you would support
};

// Define a service holding the language. You probably already have one if your app is i18ned. Or you could also
// use the Angular LOCALE_ID value
@Injectable()
export class I18n {
  language = 'se';
}

// Define custom service providing the months and weekdays translations
@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {

  constructor(private _i18n: I18n) {
    super();
  }

  getWeekdayShortName(weekday: number): string {
    return I18N_VALUES[this._i18n.language].weekdays[weekday - 1];
  }
  getMonthShortName(month: number): string {
    return I18N_VALUES[this._i18n.language].months[month - 1];
  }
  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }
}

@Component({
  selector: 'app-plan-assessment',
  templateUrl: './plan-assessment.component.html',
  styleUrls: ['./plan-assessment.component.css']
})
export class PlanAssessmentComponent implements OnInit {
  @ViewChild('scroller', { static: true }) private myScrollContainer: ElementRef;
  newMessage: boolean = false;
  showWarning: boolean = false;

  settingPage: boolean = false;
  allGames: any[] = new Array();
  choosenGames: any[];
  days: string[] = ["Mån", "Tis", "Ons", "Tor", "Fre", "Lör", "Sön", "Alla"];
  choosenDays: boolean[] = new Array(8).fill(false);
  set: number = 2;
  repetioner: number = 10;
  level: number = 3;
  measureSatAndPul: boolean = false;
  performVasBreath: boolean = false;
  performVasEffort: boolean = false;
  performRpe: boolean = false;
  performCR10: boolean = false;

  calendarContent: any[];
  calenderWeeks1: any[];
  calenderWeeks2: any[];
  month: string;
  monthNumber: number;
  yearNumber: number;
  currentMonth: number;
  currentYear: number;
  startDate: string;
  endDate: string;
  startMonth: string;
  endMonth: string;
  fromDate: Date;
  untilDate: Date;
  today: Date;
  mObs;

  hoveredDate: NgbDate; // NgbDate is a date for the calendar
  model: NgbDateStruct; 
  defaultDaySpan: number = 14;

  constructor(private dataservice: DataserviceService, private router: Router, private gi: GameInformationService,
    private dialog: MatDialog, public snackBar: MatSnackBar, private mhs: MessageHandlerService, calendar: NgbCalendar) {
      this.fromDate = Utils.NgbDateToJSDate(calendar.getToday());
      this.untilDate = Utils.NgbDateToJSDate(calendar.getNext(calendar.getToday(), 'd', this.defaultDaySpan));
    }

  ngOnInit() {
    this.allGames = this.gi.assessmentVersions;
    this.showWarning = this.dataservice.patient == "Ingen vald";
  }

  onDateSelection(ngbDate: NgbDate) {
    let date = Utils.NgbDateToJSDate(ngbDate);
    if (!this.fromDate && !this.untilDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.untilDate && ngbDate.after(Utils.JSDateToNgbDate(this.fromDate))) {
      this.untilDate = date;
    } else {
      this.untilDate = null;
      this.fromDate = date;
    }
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.untilDate && this.hoveredDate && date.after(Utils.JSDateToNgbDate(this.fromDate)) 
      && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return date.after(Utils.JSDateToNgbDate(this.fromDate)) && date.before(Utils.JSDateToNgbDate(this.untilDate));
  }

  isRange(date: NgbDate) {
    return date.equals(Utils.JSDateToNgbDate(this.fromDate)) || date.equals(Utils.JSDateToNgbDate(this.untilDate)) || this.isInside(date) || this.isHovered(date);
  }

  isWeekdayChosen(date: NgbDate) {
    let jsDate = Utils.NgbDateToJSDate(date);
    if (this.choosenDays[7]) return true;
    let dayNumber = jsDate.getDay();
    if (dayNumber == 0) dayNumber =7;
    dayNumber--;
    return this.choosenDays[dayNumber]
  }

  isToday(date: NgbDate) {
    var todayDate = new Date();
    if(date.day == todayDate.getDate()
      && date.month == (todayDate.getMonth() + 1)
      && date.year == todayDate.getFullYear()) {
        return true;
    }
    return false;
  }

  scheduleActivities() {
    let assessmentActivities = new Array();
    for (let activity of this.choosenGames) {
      assessmentActivities = assessmentActivities.concat(activity);
    }
    this.sendPlanToDB(assessmentActivities, "NONE");
  }

  private sendPlanToDB(activities: any[], position: string) {
    let names = new Array();
    for (let activity of activities) {
      names.push(activity.dbName);
    }
    let days = new Array();
    for (let i = 0; i < 7; i++) {
      if (this.choosenDays[i]) {
        days.push(i + 1);
      }
    }
    let settings = {
      level: 0,
      sets: 1,
      repetitions: 0,
      positionType: position
    }
    let body = {
      activityName: names,
      scheduledFrom: this.fromDate.getTime(),
      scheduledUntil: this.untilDate.getTime(),
      daysOfWeek: days,
      settings: settings,
      variant: "ASSESSMENT",
    }
    this.dataservice.scheduleActivity(body).subscribe(
      (resp) => {
        if (resp.status == 200) {
          this.snackBar.open('Övningar planerade!', 'Sparat', { duration: 4500 });
          this.goBack();
        }
      });
  }

  setupSettingPage() {
    if (this.showWarning) {
      this.snackBar.open('Du måste välja en användare först!', 'ERROR', { duration: 4500 });
      return false;
    }
    this.choosenGames = new Array();
    for (let game of this.allGames) {
      if (game.choosen) {
        this.choosenGames = this.choosenGames.concat(game);
      }
    }
    if (this.choosenGames.length < 1) {
      this.snackBar.open('Du måste välja minst en övning att planera!', 'ERROR', { duration: 4500 });
      return false;
    }
    this.settingPage = true;
    this.myScrollContainer.nativeElement.scrollTop = 0;
    return false;
  }

  chooseDay(day: number) {
    if (day == 7) {
      this.choosenDays = new Array(8).fill(!this.choosenDays[7]);
      return false;
    }
    this.choosenDays[7] = false;
    this.choosenDays[day] = !this.choosenDays[day];
    return false;
  }

  chooseExercise(activity: any) {
    activity.choosen = !activity.choosen;
    return false;
  }

  popupInfo(activity: any) {
    let dialogRef = this.dialog.open(AssessmentInfoDialog, {
      width: 'auto',
      data: {
        name: activity.name, picture: activity.picture, information: activity.description
      }
    });
    return false;
  }

  cleanField() {
    for (let game of this.allGames) {
      game.choosen = false;
    }
    return false;
  }

  goBack() {
    for (let game of this.allGames) {
      game.choosen = false;
    }
    this.settingPage = false;
    this.myScrollContainer.nativeElement.scrollTop = 0;
    return false;
  }
}

@Component({
  selector: 'assessment-info',
  templateUrl: 'assessment-info.html',
  styleUrls: ['./plan-assessment.component.css']
})
export class AssessmentInfoDialog {

  constructor(
    public dialogRef: MatDialogRef<AssessmentInfoDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  onNoClick(): void {
    this.dialogRef.close();
  }
}