import { Component, OnInit, ViewChild } from '@angular/core';
import { DataserviceService } from '../../services/dataservice.service';
import { MessageHandlerService } from '../../services/message-handler.service';
import { Router } from '@angular/router'

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.css']
})
export class ContactsComponent implements OnInit {
  requests: string[];
  showFriendRequests: boolean = false;

  patientContacts: Contact[];
  professionalContacts: Contact[];
  
  plannedQuest: boolean = false;
  showWarning: boolean = false;

  constructor(private dataservice: DataserviceService, private router: Router, private mhs: MessageHandlerService) { }

  ngOnInit() {
    this.showWarning = this.dataservice.patient == "Ingen vald";
    this.loadContacts();
  }

  private loadContacts() {
    this.mhs.getAllContactsFromChatService().subscribe(
      (resp) => {
        if (resp && resp.status == 200) {
          let data = resp.json();
          this.patientContacts = new Array();
          this.professionalContacts = new Array();
          for (let entry of data) {
            if (entry.isPatient) {
              this.patientContacts.push({
                name: entry.name,
                instance: entry.instance,
                sessionId: entry.sessionId,
                displayName: entry.name + " (" + entry.instance + ")",
                profession: "",
                showActionBar: false,
                isActive: entry.isActive,
                hasPicture: entry.picture != null,
                picture: 'data:image/png;base64,' + entry.picture
              });
            }
            else {
              this.professionalContacts.push({
                name: entry.name,
                instance: entry.instance,
                sessionId: entry.sessionId,
                displayName: entry.name + " (" + entry.instance + ")",
                profession: entry.profession,
                showActionBar: false,
                isActive: entry.isActive,
                hasPicture: entry.picture != null,
                picture: 'data:image/png;base64,' + entry.picture
              });
            }
          }

          this.professionalContacts.sort(function (a, b) {
            if (a.displayName < b.displayName) { return -1; }
            if (a.displayName > b.displayName) { return 1; }
            return 0;
          });

          this.patientContacts.sort(function (a, b) {
            if (a.displayName < b.displayName) { return -1; }
            if (a.displayName > b.displayName) { return 1; }
            return 0;
          });

        }
      });
  }

  showActionRow(contact: Contact) {
    for (let c of this.patientContacts) {
      if (c.displayName == contact.displayName) {
        c.showActionBar = !c.showActionBar;
      }
    }
    for (let cProf of this.professionalContacts) {
      if (cProf.displayName == contact.displayName) {
        cProf.showActionBar = !cProf.showActionBar;
      }
    }
  }

  selectVideoContact(contact: Contact) {
    this.mhs.setCurrentContact(contact);
    this.router.navigate(['contact/video']);
    return false;
  }

  selectMessageContact(contact: Contact) {
    this.mhs.setCurrentContact(contact);
    this.router.navigate(['contact/message']);
    return false;
  }
}


interface Contact {
  displayName: string;
  name: string;
  instance: string;
  sessionId: string;
  profession: string;
  showActionBar: boolean;
  isActive: boolean;
  hasPicture: boolean;
  picture: string;
}

interface FriendRequest {
  requestBy: string;
  requestInstance: string;
  friendName: string;
  friendInstance: string;
  accepted: boolean;
}