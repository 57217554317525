import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataserviceService } from '../../services/dataservice.service';
import { MessageHandlerService } from '../../services/message-handler.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.css']
})
export class LoginPageComponent implements OnInit {
  userinfo: any;
  loginFaild: boolean;
  noContent: boolean;
  instance: string;
  instances: string[] = ["Välj vårdinstans", "Angered", "Sahlgrenska", "Skövde", "Alkit"]

  constructor(private dataservice: DataserviceService, private router: Router, private mhs: MessageHandlerService, private cookieService: CookieService) { }

  ngOnInit() {
    this.loginFaild = false;
    this.noContent = false;
    this.dataservice.username = null;
    this.dataservice.auth_token = null;
    this.dataservice.patientObject = null;
    this.dataservice.patient = "Ingen vald";
    this.dataservice.url = null;

    var cookieExists = this.cookieService.check('ReactCareUnit');
    if (cookieExists){
      this.instance = this.cookieService.get('ReactCareUnit');
    }
    else{
      this.instance = this.instances[0];
    }
  }

  loginUser(e) {
    this.loginFaild = false;
    this.noContent = false;

    if (!e.target.elements[0].value || !e.target.elements[1].value || !this.instance || this.instance == this.instances[0]) {
      this.noContent = true;
      return false;
    }
    this.dataservice.setCareUnit(this.instance);
    this.userinfo = {
      username: e.target.elements[0].value,
      password: e.target.elements[1].value
    };
    if (this.userinfo.username && this.userinfo.password) {
      this.dataservice.logInCall(this.userinfo.username, this.userinfo.password).subscribe(
        (resp) => {
          if (resp.status == 200) {
            let data = resp.json();
            this.dataservice.auth_token = data.sessionId;
            this.dataservice.username = this.userinfo.username;            
            this.mhs.logIn(this.userinfo.username, this.userinfo.password).subscribe(
              (resp) => {
                if (resp.status == 200){
                  let data = resp.json();
                  this.mhs.auth_token = data.sessionId;
                  this.dataservice.userImg = data.picture;
                  this.cookieService.set( 'ReactCareUnit', this.instance, 300);
                  this.router.navigate(['/home']);
                }
                else{
                  this.loginFaild = true;
                }
              }
            );
          }
          else {
            this.loginFaild = true;
          }
        });
    }
    return false;
  }
}
