import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DataserviceService } from '../../services/dataservice.service';
import { GameInformationService } from '../../services/game-information.service';
import { MessageHandlerService } from '../../services/message-handler.service';
import {
  NgbDatepicker,
  NgbInputDatepicker,
  NgbDateStruct,
  NgbCalendar,
  NgbDate,
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbDatepickerI18n
} from '@ng-bootstrap/ng-bootstrap';

const equals = (one: NgbDateStruct, two: NgbDateStruct) =>
  one && two && two.year === one.year && two.month === one.month && two.day === one.day;

const before = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two ? false : one.year === two.year ? one.month === two.month ? one.day === two.day
    ? false : one.day < two.day : one.month < two.month : one.year < two.year;

const after = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two ? false : one.year === two.year ? one.month === two.month ? one.day === two.day
    ? false : one.day > two.day : one.month > two.month : one.year > two.year;

@Component({
  selector: 'app-result-overview',
  templateUrl: './result-overview.component.html',
  styleUrls: ['./result-overview.component.css']
})
export class ResultOverviewComponent implements OnInit {
  newMessage: boolean = false;
  showWarning: boolean = false;
  ascendingDate: boolean = true;
  ascendingName: boolean = false;

  activities: any[];
  mObs;

  fromDate: NgbDate;
  untilDate: NgbDate;
  defaultDaySpanBefore: number = 7;
  defaultDaySpanAfter: number = 7;
  prevMonth: NgbDate;
  hoveredDate: NgbDateStruct;
  model: any;

  constructor(private dataservice: DataserviceService, private router: Router, private gi: GameInformationService,
    private mhs: MessageHandlerService, calendar: NgbCalendar) {
    this.fromDate = calendar.getPrev(calendar.getToday(), 'd', this.defaultDaySpanBefore);
    this.untilDate = calendar.getNext(calendar.getToday(), 'd', this.defaultDaySpanAfter);
  }

  ngOnInit() {    
    this.showWarning = this.dataservice.patient == "Ingen vald";    
    if (!this.showWarning) {
      let now = new Date();
      this.setupCompleteTable();
    }
  }

  isHovered = date =>
  this.fromDate && !this.untilDate && this.hoveredDate && after(date, this.fromDate) && before(date, this.hoveredDate)
isInside = date => after(date, this.fromDate) && before(date, this.untilDate);
isFrom = date => equals(date, this.fromDate);
isTo = date => equals(date, this.untilDate);

NgbDateToNgbDateStruct(ngbDate: NgbDate) {
  const date: NgbDateStruct = { year: ngbDate.year, month: ngbDate.month, day: ngbDate.day };
  return date;
}

NgbDateStructToNgbDate(ngbDateStruct: NgbDateStruct) {
  return new NgbDate(ngbDateStruct.year, ngbDateStruct.month, ngbDateStruct.day);
}

onDateSelectionActivityFrom(date: NgbDateStruct) {
  this.fromDate = this.NgbDateStructToNgbDate(date);
  this.setupCompleteTable();
}

onDateSelectionActivityTo(date: NgbDateStruct) {
  this.untilDate = this.NgbDateStructToNgbDate(date);
  this.setupCompleteTable();
}

NgbDateToJSDate(ngbDate: NgbDate) {
  let date = new Date();
  date.setDate(ngbDate.day);
  date.setMonth(ngbDate.month - 1);
  date.setFullYear(ngbDate.year);
  return date;
}

JSDateToNgbDate(date: Date) {
  if (date == null) return null;
  let ngbDate = new NgbDate(date.getFullYear(), date.getMonth() + 1, date.getDate());
  return ngbDate;
}

isRange(date: NgbDate) {
  return date.equals(this.fromDate) || date.equals(this.untilDate) || this.isInside(date) || this.isHovered(date);
}

isToday(date: NgbDate) {
  var todayDate = new Date();
  if (date.day == todayDate.getDate()
    && date.month == (todayDate.getMonth() + 1)
    && date.year == todayDate.getFullYear()) {
    return true;
  }
  return false;
}

getJSFromDate() {
  return new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day, 0, 0, 0, 0);
}

getJSUntilDate() {
  return new Date(this.untilDate.year, this.untilDate.month - 1, this.untilDate.day, 23, 59, 59, 99);
}

  sortTableDates() {
    this.ascendingDate = !this.ascendingDate;
    if (this.ascendingDate) {
      this.activities.sort(function (a, b) {
        return a.scheduledFor - b.scheduledFor;
      });
    }
    else {
      this.activities.sort(function (a, b) {
        return b.scheduledFor - a.scheduledFor;
      });
    }
  }

  sortTableNames() {
    this.ascendingName = !this.ascendingName;
    if (this.ascendingName) {
      this.activities.sort(function (a, b) {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      });
    }
    else {
      this.activities.sort(function (a, b) {
        if (a.name > b.name) return -1;
        if (a.name < b.name) return 1;
        return 0;
      });
    }
  }

  sortTablePositions() {
    this.ascendingName = !this.ascendingName;
    if (this.ascendingName) {
      this.activities.sort(function (a, b) {
        if (a.position < b.position) return -1;
        if (a.position > b.position) return 1;
        return 0;
      });
    }
    else {
      this.activities.sort(function (a, b) {
        if (a.position > b.position) return -1;
        if (a.position < b.position) return 1;
        return 0;
      });
    }
  }

  setupCompleteTable() {

    let body = {
      from: this.getJSFromDate().getTime(),
      until: this.getJSUntilDate().getTime(),
      variant: "ALL"
    };

    this.dataservice.getActivityResultsBetweenDates(body).subscribe(
      (resp) => {
        if (resp.status == 200) {
          this.activities = new Array();
          let now = new Date();
          let startOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0);
          let data = resp.json();
          data.sort(function (a, b) {
            return a.scheduledFor - b.scheduledFor;
          });
          let prevDay : Date;
          let isDateColored : boolean = false;
          for (let act of data) {
            let tmp = new Date(act.scheduledFor);
            if (prevDay != null) {
              if (this.isDateDifferent(prevDay, tmp)) {
                isDateColored = !isDateColored;
              }
            }
            prevDay = tmp;
            let day = tmp.getFullYear() + "-" + (tmp.getMonth() + 1) + "-" + tmp.getDate();
            for (let res of act.result) {
              let vas = "--";
              let rpeVal = "--";
              let cr10Val = "--";
              if (res.vasBreath != 0) {
                vas = res.vasBreath + " (af)";
              } else if (res.vasEffort != 0) {
                vas = res.vasEffort + " (as)";
              }
              if (res.rpe != 0) {
                rpeVal = res.rpe;
              } 
              if (res.cr10 != 0) {
                cr10Val = res.cr10;
              }
              let scoreString;
              if (act.type == "DriveForestExercise") {
                scoreString = res.distanceTravelledMeters / 1000 + " km";
              } else if (res.possibleScore == 0) {
                scoreString = res.score + "/" + act.settings.repetitions
              } else {
                scoreString = res.score + "/" + res.possibleScore;
              }
              this.activities.push({
                date: day,
                name: this.gi.gameName(act.type),
                level: act.settings.level == 0 ? "--" : act.settings.level,
                sets: act.settings.sets,
                repetitions: act.settings.repetitions == 0 ? "--" : act.settings.repetitions,
                position: this.gi.positionType(act.settings.positionType),
                score: scoreString,
                duration: act.settings.repetitions == 0 ? '--' : this.gameTime((res.activityEnded - res.activityStarted) / 1000),
                vas: vas,
                rpe: rpeVal,
                cr10: cr10Val,
                scheduledFor: act.scheduledFor,
                isDateColored: isDateColored,
                picture: this.gi.getPictureForGameName(this.gi.gameName(act.type))
              });
            }
          }
        }
      }
    );
  }

  // Does not compare hours!
  isDateDifferent(date1:Date, date2:Date) {
    if (date1.getFullYear() != date2.getFullYear() 
      || date1.getMonth() != date2.getMonth()
      || date1.getDate() != date2.getDate()) {
      return true;
    }
    return false;
  }

  private gameTime(time: number): string {
    if (time / 60 > 1) {
      return Math.round(time / 60).toString() + ' min ' + Math.round(time % 60) + ' s';
    }
    else if (time <= 0) {
      return "--";
    }
    return Math.round(time) + ' s';
  }
}
