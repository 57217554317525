import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { DataserviceService } from '../services/dataservice.service';
import { Router } from '@angular/router';
import { MessageHandlerService } from '../services/message-handler.service';
import { SideContentService } from './side-content.service';

@Component({
  selector: 'app-side-content',
  templateUrl: './side-content.component.html',
  styleUrls: ['./side-content.component.css']
})
export class SideContentComponent implements OnInit {
  @Input()
  side: number;
  @Input()
  active: number;
  @ViewChild('myImage', { static: true }) myImage: any;  
  showImg:boolean

  mObs;
  newMessage: boolean;
  newMsgNr: number = 0;

  username:string;
  patient:string;  
  showWarning: boolean= false;

  constructor(private dataservice: DataserviceService, private router: Router, private mhs: MessageHandlerService, private sideContentService: SideContentService) { }

  ngOnInit(){
    if (this.dataservice.username == 'undefined' || !this.dataservice.username) {
      this.router.navigate(['']);
      return;
    }
    this.dataservice.pingServer().subscribe(
      (resp) => {
        if (resp && (resp == 401 || resp == 503)) {
          this.router.navigate(['']);
          return;
        }
      });
    this.username = this.dataservice.username;
    this.patient = this.dataservice.patient;
    this.showWarning = this.patient == "Ingen vald";
    if (this.dataservice.userImg){
      this.showImg = true;
      this.myImage.nativeElement.width = 100;
      this.myImage.nativeElement.height = 100;
      this.myImage.nativeElement.src = 'data:image/png;base64,' + this.dataservice.userImg;  
    }
    if (this.side == 4){
      this.repeatedCheckIfNewMessages();
    }
    this.sideContentService.changeImage.subscribe(newImage => {
      this.showImg = true;
      this.myImage.nativeElement.width = 100;
      this.myImage.nativeElement.height = 100;
      this.myImage.nativeElement.src = 'data:image/png;base64,' + newImage;
      this.dataservice.userImg = this.myImage.nativeElement.src;
    });
  }

  ngOnDestroy(){ 
    if (this.mObs && this.side==4){
      this.mObs.unsubscribe();
    }
  }

  private repeatedCheckIfNewMessages() {
    this.mhs.getNewMessages().subscribe(
      (resp) => {
        if (resp._body.includes("message")) {
          let messages = resp.json();
          this.newMsgNr = messages.length;
          this.newMessage = true;
        }
      });
    this.mObs = this.mhs.getNewMessagesPeriodically(15000).subscribe(
      (resp) => {
        if (resp._body.includes("message")) {
          this.newMessage = true;
          let messages = resp.json();
          this.newMsgNr = messages.length;
        }
      });
  }

  startConfero() {
    this.dataservice.downloadConfero().subscribe( 
      response => {
     var contentDisposition= response.headers.get('content-disposition');
     var fileName = contentDisposition.substring(20, contentDisposition.length);
     let downloadLink = document.createElement('a');
     let blob:any = new Blob([response.blob()], { type: 'application/sdp' });
     downloadLink.href = window.URL.createObjectURL(blob);
     downloadLink.setAttribute('download', fileName);
     document.body.appendChild(downloadLink);
     downloadLink.click();
    }), error => console.log('Error downloading the file'),
                 () => console.info('File downloaded successfully');
    return false;
  }

  loadPage(path: string) {
    this.router.navigate([path]);
    return false;
  }
}
