import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { Observable } from 'rxjs';
import { DataserviceService } from '../services/dataservice.service';

@Injectable()
export class MessageHandlerService {
  private contact: any;
  // private url: string = "http://192.168.0.174:4300/MessageServiceServer/chat/"; //"https://kol.alkit.se/MessageServiceServer/chat/"; //"http://mandolin:4300/MessageServiceServer/chat/"; //"http://localhost:8080/MessageServiceServer/chat/";//"https://kol.alkit.se/MessageServiceServer/chat/";
  // private url: string = "http://kol-demo.alkit.se/MessageServiceServer/chat/";
  private url: string = "https://kol.alkit.se/MessageServiceServer/chat/"; //
  auth_token: string;

  constructor(private http: Http, private dataservice: DataserviceService) { }

  logIn(user: string, pw: string): Observable<any> {
    let body = {
      username: user,
      password: pw,
      instance: this.dataservice.care_unit_name
    };

    // Set request header and send request.
    let headers = new Headers({ 'Access-Control-Allow-Origin': '*' });
    let options = new RequestOptions({ headers: headers });
    return this.http.post(this.url + 'login', body, options)
      .map(this.data)
      .catch((error: any) => Observable.of(error));
  }

  logOut(): Observable<any> {
    let token = this.auth_token;
    this.auth_token = null;
    let headers = new Headers({ 'content-type': 'application/json', 'auth-token': this.auth_token });
    return this.http.get(this.url + 'logout', { headers: headers })
      .map(this.data)
      .catch((error: any) => Observable.of(error));
  }

  changePassword(body: any){
    let headers = new Headers({ 'content-type': 'application/json', 'auth-token': this.auth_token });
    return this.http.post(this.url + 'changePassword', body, { headers: headers })
      .map(this.data)
      .catch((error: any) => Observable.of(error));
  }

  changeImage(body: any){
    let headers = new Headers({ 'content-type': 'application/json', 'auth-token': this.auth_token });
    return this.http.post(this.url + 'changeUserImage', body, { headers: headers })
      .map(this.data)
      .catch((error: any) => Observable.of(error));
  }

  getCurrentContact() {
    return this.contact;
  }

  setCurrentContact(contact: any) {
    this.contact = contact;
  }

  getNewMessages() {
    let headers = new Headers({ 'content-type': 'application/json', 'auth-token': this.auth_token });
    return this.http.get(this.url + "getNewMessages", { headers: headers })
      .map(this.data)
      .catch((error: any) => Observable.of(error));
  }

  getNewMessagesPeriodically = (interval) => {
    let headers = new Headers({ 'content-type': 'application/json', 'auth-token': this.auth_token });
    return Observable
      .interval(interval)
      .flatMap((i) => this.http.get(this.url + "getNewMessages", { headers: headers })
        .map(this.data)
        .catch((error: any) => Observable.of(error)));
  }

  getMessagesFromUser(message: any) {
    let body = message;
    let headers = new Headers({ 'content-type': 'application/json', 'auth-token': this.auth_token });
    return this.http.post(this.url + 'getMessagesFromUser', body, { headers: headers })
      .map(this.data)
      .catch((error: any) => Observable.of(error));
  }

  getNewestMessagesFromUser(message: any) {
    let body = message;
    let headers = new Headers({ 'content-type': 'application/json', 'auth-token': this.auth_token });
    return this.http.post(this.url + 'getNewestMessagesFromUser', body, { headers: headers })
      .map(this.data)
      .catch((error: any) => Observable.of(error));
  }

  sendMessagesToUser(message: any) {
    let body = message;
    let headers = new Headers({ 'content-type': 'application/json', 'auth-token': this.auth_token });
    return this.http.post(this.url + 'newMessages', body, { headers: headers })
      .map(this.data)
      .catch((error: any) => Observable.of(error));
  }

  changeUnreadStatus(message: any) {
    let body = message;
    let headers = new Headers({ 'content-type': 'application/json', 'auth-token': this.auth_token });
    return this.http.post(this.url + 'changeUnreadStatus', body, { headers: headers })
      .map(this.data)
      .catch((error: any) => Observable.of(error));
  }

  getFriendContactsFromChatService() {
    let headers = new Headers({ 'content-type': 'application/json', 'auth-token': this.auth_token });
    return this.http.get(this.url + 'getPrivateContacts', { headers: headers })
      .map(this.data)
      .catch((error: any) => Observable.of(error));
  }

  getProfContactsFromChatService() {
    let headers = new Headers({ 'content-type': 'application/json', 'auth-token': this.auth_token });
    return this.http.get(this.url + 'getProfessionalContacts', { headers: headers })
      .map(this.data)
      .catch((error: any) => Observable.of(error));
  }

  getAllContactsFromChatService() {
    let headers = new Headers({ 'content-type': 'application/json', 'auth-token': this.auth_token });
    return this.http.get(this.url + 'getAllContacts', { headers: headers })
      .map(this.data)
      .catch((error: any) => Observable.of(error));
  }

  

  createUser(body: any) {
    let headers = new Headers({ 'content-type': 'application/json', 'auth-token': this.auth_token });
    return this.http.post(this.url + 'addPatient', body, { headers: headers })
      .map(this.data)
      .catch((error: any) => Observable.of(error));
  }

  deleteUser(body: any) {
    let headers = new Headers({ 'content-type': 'application/json', 'auth-token': this.auth_token });
    return this.http.post(this.url + 'deletePatient', body, { headers: headers })
      .map(this.data)
      .catch((error: any) => Observable.of(error));
  }


  private data(res: Response) {
    return res;
  }
}
