import { Component, OnInit, ViewChild, ElementRef, Renderer2, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DataserviceService } from '../../services/dataservice.service';
import { MessageHandlerService } from '../../services/message-handler.service';
import {
  NgbDatepicker,
  NgbInputDatepicker,
  NgbDateStruct,
  NgbCalendar,
  NgbDate,
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbDatepickerI18n
} from '@ng-bootstrap/ng-bootstrap';
import Utils from '../utils';
declare let d3: any;

const now = new Date();
const equals = (one: NgbDateStruct, two: NgbDateStruct) =>
  one && two && two.year === one.year && two.month === one.month && two.day === one.day;

const before = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two ? false : one.year === two.year ? one.month === two.month ? one.day === two.day
    ? false : one.day < two.day : one.month < two.month : one.year < two.year;

const after = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two ? false : one.year === two.year ? one.month === two.month ? one.day === two.day
    ? false : one.day > two.day : one.month > two.month : one.year > two.year;

const I18N_VALUES = {
  'se': {
    weekdays: ['Må', 'Ti', 'On', 'To', 'Fr', 'Lö', 'Sö'],
    months: ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Juni', 'Juli', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  }
  // other languages you would support
};

// Define a service holding the language. You probably already have one if your app is i18ned. Or you could also
// use the Angular LOCALE_ID value
@Injectable()
export class I18n {
  language = 'se';
}

// Define custom service providing the months and weekdays translations
@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {

  constructor(private _i18n: I18n) {
    super();
  }

  getWeekdayShortName(weekday: number): string {
    return I18N_VALUES[this._i18n.language].weekdays[weekday - 1];
  }
  getMonthShortName(month: number): string {
    return I18N_VALUES[this._i18n.language].months[month - 1];
  }
  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }
}

@Component({
  selector: 'app-measurements',
  templateUrl: './measurements.component.html',
  styleUrls: ['./measurements.component.css']
})
export class MeasurementsComponent implements OnInit {
  showWarning: boolean = false;
  today: Date;

  dataAvailable: boolean = false;
  tempData: any;
  weigthData: any;
  pulseData: any;
  saturationData: any;
  options: any;

  calendarContent: Calendar[];
  calenderWeeks1: CalenderWeeks[];
  calenderWeeks2: CalenderWeeks[];
  month: string;
  monthNumber: number;
  yearNumber: number;
  currentMonth: number;
  currentYear: number;

  startDate: string;
  endDate: string;
  startMonth: string;
  endMonth: string;

  defaultDaySpan: number = 14;
  prevMonth: NgbDate;
  hoveredDate: NgbDateStruct;
  fromDate: NgbDate;
  untilDate: NgbDate;
  model: any;

  newItem = {
    StartTime: null,
    EndTime: null
  };

  constructor(private dataservice: DataserviceService, private router: Router, private mhs: MessageHandlerService
    , calendar2: NgbCalendar) {
    this.fromDate = calendar2.getPrev(calendar2.getToday(), 'd', this.defaultDaySpan);
    this.untilDate = calendar2.getToday();
    this.newItem.StartTime = this.fromDate;
    this.newItem.EndTime = this.untilDate;
   }

  ngOnInit() {
    this.showWarning = this.dataservice.patient == "Ingen vald";
    this.loadData();
  }

  isHovered = date =>
    this.fromDate && !this.untilDate && this.hoveredDate && after(date, this.fromDate) && before(date, this.hoveredDate)
  isInside = date => after(date, this.fromDate) && before(date, this.untilDate);
  isFrom = date => equals(date, this.fromDate);
  isTo = date => equals(date, this.untilDate);
  isToday(date: NgbDate) {
    var todayDate = new Date();
    if (date.day == todayDate.getDate()
      && date.month == (todayDate.getMonth() + 1)
      && date.year == todayDate.getFullYear()) {
      return true;
    }
    return false;
  }

  onDateSelectionActivityFrom(date: NgbDateStruct) {
    this.fromDate = Utils.NgbDateStructToNgbDate(date);
    this.loadData();
  }

  onDateSelectionActivityTo(date: NgbDateStruct) {
    this.untilDate = Utils.NgbDateStructToNgbDate(date);
    this.loadData();
  }

  private loadData() {
    if (this.showWarning) {
      return;
    }
    this.dataAvailable = false;
    this.dataservice.getParameterData(Utils.NgbDateToJSDate(this.fromDate), Utils.NgbDateToJSDate(this.untilDate)).subscribe(
      (resp) => {
        if (resp && resp.status == 200) {
          let data = resp.json();
          data = data.sort(function (a, b) {
            return b.date - a.date;
          });
          let weight = new Array();
          let temp = new Array();
          let timezoneOffset = new Date().getTimezoneOffset();
          for (let entry of data) {
            let date = new Date(entry.date);
            let xLabel = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()) + Date.UTC(1970, 0, 1, 0, timezoneOffset, 0, 0);
            if (entry.data.weight != 0) {
              weight.push({
                "label": xLabel,
                "value": entry.data.weight
              });
            }
            if (entry.data.temp != 0) {
              temp.push({
                "label": xLabel,
                "value": entry.data.temp
              });
            }
          }
          this.weigthData = [{
            key: 'vikt (kg)',
            values: weight
          }];
          this.tempData = [{
            key: 'temp (\xB0C)',
            values: temp
          }];
          this.setupChartOptions();
          this.dataAvailable = true;
        }
      });

    let body = {
      from: Utils.NgbDateToJSDate(this.fromDate).getTime(),
      until: Utils.NgbDateToJSDate(this.untilDate).getTime(),
      variant: 'ALL'
    };
    
    this.dataservice.getExtremePoxValuesBetweenDates(body).subscribe( 
      (resp) => {
        if (resp.status == 200) {
          let json = resp.json();
          let pulse = new Array();
          let saturation = new Array();
          let timezoneOffset = new Date().getTimezoneOffset();
          for (let entry of json) {
            let date = new Date(entry.measuredOn);
            let xLabel = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()) + Date.UTC(1970, 0, 1, 0, timezoneOffset, 0, 0);
            if (entry.pulse != -1 && entry.pulse != 0) {
              pulse.push({
                "label": xLabel,
                "value": entry.pulse
              });
              this.dataAvailable = true;
            } 
            if (entry.saturation != -1 && entry.saturation != 0) {
              saturation.push({
                "label": xLabel,
                "value": entry.saturation
              });
              this.dataAvailable = true;
            }
          }
          this.pulseData = [{
            key: 'puls (bpm)',
            values: pulse
          }];
          this.saturationData = [{
            key: 'syresättning (%)',
            values: saturation
          }];
          this.setupChartOptions();
          this.dataAvailable = true;
        }
      }
    );
  }

  private setupChartOptions() {
    this.options = {
      chart: {
        type: 'lineChart',
        x: function (d) { return d.label; },
        y: function (d) { return d.value; },
        useInteractiveGuideline: true,
        clipVoronoi: false,
        xScale: d3.time.scale(),
        xAxis: {
          tickFormat: function (d) {
            return d3.time.format('%y-%m-%d')(new Date(d));
          },
          rotateLabels: -45,
          showMinMax: false
        },
        yAxis:{
          tickFormat: function (d) {
            return d.toFixed(1);
          }
        }
      }
    }
  }
}

interface Calendar {
  day: number;
  thisMonth: boolean;
  markedDay: boolean;
  today: boolean;
}

interface CalenderWeeks {
  day1: Calendar;
  day2: Calendar;
  day3: Calendar;
  day4: Calendar;
  day5: Calendar;
  day6: Calendar;
  day7: Calendar;
}