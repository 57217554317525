import { Component, Input, OnInit } from '@angular/core';
import { DataserviceService } from '../services/dataservice.service';
import { Router } from '@angular/router';
import { MessageHandlerService } from '../services/message-handler.service';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'menu-input',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {
  @Input()
  menuIcon;

  @Input()
  side: number;
  
  showWarning: boolean;
  newMessage: boolean = false;
  mObs;

  constructor(private router: Router, private dataservice: DataserviceService, private mhs : MessageHandlerService, public snackBar: MatSnackBar) { }

  ngOnInit(){
    this.showWarning = this.dataservice.patient == "Ingen vald";
    if (this.side != 4){
      this.repeatedCheckIfNewMessages();
    }    
  }

  ngOnDestroy(){ 
    if (this.mObs && this.side!=4){
      this.mObs.unsubscribe();
    }
  }

  private repeatedCheckIfNewMessages() {
    this.mhs.getNewMessages().subscribe(
      (resp) => {
        if (resp._body.includes("message")) {
          this.newMessage = true;
        }
      });
    this.mObs = this.mhs.getNewMessagesPeriodically(15000).subscribe(
      (resp) => {
        if (resp._body.includes("message")) {
          this.newMessage = true;
        }
      });
  }

  hover(name: string) {
    this.menuIcon = name;
    return false;
  }

  loadPage(path: string) {
    this.router.navigate([path]);
    return false;
  }

  logout() {
    this.dataservice.logOutCall().subscribe(
      (resp) => {
        this.router.navigate(['']);
      });
    return false;
  }

  downloadXmlFileForPatient() {
    if (this.dataservice.patient == "Ingen vald") {
      this.snackBar.open('Du måste först välja en patient', 'Error', { duration: 4500 });
      return false;
    }

    this.dataservice.downloadExcelFileForPatient().subscribe( 
       response => {
      var contentDisposition= response.headers.get('content-disposition');
      var fileName = contentDisposition.substring(20, contentDisposition.length);
      let downloadLink = document.createElement('a');
      let blob:any = new Blob([response.blob()], { type: 'application/vnd.ms-excel' });
      downloadLink.href = window.URL.createObjectURL(blob);
      downloadLink.setAttribute('download', fileName);
      document.body.appendChild(downloadLink);
      downloadLink.click();
		 }), error => console.log('Error downloading the file'),
                  () => console.info('File downloaded successfully');
    return false;
  }

}
