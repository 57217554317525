import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DataserviceService } from '../../services/dataservice.service';
import { MessageHandlerService } from '../../services/message-handler.service';

@Component({
  selector: 'app-qr-code',
  templateUrl: './qr-code.component.html',
  styleUrls: ['./qr-code.component.css']
})
export class QrCodeComponent implements OnInit {
  qrURL: string;
  maximized: boolean = false;
  showWarning: boolean = false;

  constructor(private dataservice: DataserviceService, private router: Router, private mhs: MessageHandlerService) { }

  ngOnInit() {
    this.qrURL = this.dataservice.url;
    this.showWarning = this.dataservice.patient == "Ingen vald";
  }

  print(): void {
    let printContents, popupWin;
    printContents = document.getElementById('print-section').innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>QR-kod</title>
          <style>
          //........Customized style.......
          </style>
        </head>
    <body onload="window.print();window.close()">${printContents}</body>
      </html>`
    );
    popupWin.document.close();
  }

  switchView(max: boolean) {
    this.maximized = max;
    return false;
  }
}
