import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DataserviceService } from '../../services/dataservice.service';
import { MessageHandlerService } from '../../services/message-handler.service';
import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { MatSnackBar } from '@angular/material';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.css']
})
export class VideoComponent implements OnInit {
  menuIcon: string;
  username: string;
  newMessage: boolean = false;
  plannedQuest: boolean = false;
  newMsgNr: number = 0;

  contacts: Contact[];

  @ViewChild('myVideo', { static: true }) myVideo: any;
  @ViewChild('mySnap', { static: true }) mySnap: any;
  snap: any;
  obs1;
  obs2;
  canvas: HTMLCanvasElement;
  errorMsg: string;

  webcamStarted: boolean = false;
  videoSession: boolean = false;
  showMyVideo: boolean = true;
  currentContact: Contact;
  current: string = "Välj kontakt";

  constructor(private dataservice: DataserviceService, private router: Router, private mhs: MessageHandlerService,
    private http: Http, public snackBar: MatSnackBar) { }

  ngOnInit() {
    // standard node module
    /*var execFile = child.execFile;

    // this launches the executable and returns immediately
    var child = execFile("../../services/webconfero/WebConferoTcp.exe", ["arg1", "arg2"],
      function (error, stdout, stderr) {
        // This callback is invoked once the child terminates
        // You'd want to check err/stderr as well!
        console.log("Here is the complete output of the program: ");
        console.log(stdout);
        console.log(stderr);
      });*/

    if (this.dataservice.username == 'undefined' || !this.dataservice.username) {
      this.router.navigate(['']);
      return;
    }
    this.dataservice.pingServer().subscribe(
      (resp) => {
        if (resp && (resp == 401 || resp == 503)) {
          this.router.navigate(['']);
          return;
        }
      });
    this.username = this.dataservice.username;
    this.repeatedCheckIfNewMessages();
    this.setupContactList();

    this.canvas = document.createElement('canvas');
    this.canvas.width = 320;
    this.canvas.height = 240;
    //this.startWebcam();
    this.shouldPerformAssessment();
  }

  private shouldPerformAssessment() {
    this.dataservice.getTodaysActivities().subscribe(
      (resp) => {
        if (resp && resp.status == 200) {
          let data = resp.json();
          for (let entry of data) {
            if (entry.type == "CATAssessment" && !entry.completed) {
              this.plannedQuest = true;
            }
          }
        }
      });
  }

  private repeatedCheckIfNewMessages() {
    this.mhs.getNewMessages().subscribe(
      (resp) => {
        if (resp._body.includes("message")) {
          this.newMessage = true;
          let data = resp.json();
          this.newMsgNr = data.length;
        }
        else {
          this.newMsgNr = 0;
        }
      });
    this.mhs.getNewMessagesPeriodically(15000).subscribe(
      (resp) => {
        if (resp._body.includes("message")) {
          this.newMessage = true;
          let data = resp.json();
          this.newMsgNr = data.length;
        }
        else {
          this.newMsgNr = 0;
        }
      });
  }

  private setupContactList() {
    this.mhs.getAllContactsFromChatService().subscribe(
      (resp) => {
        if (resp && resp.status == 200) {
          let allContacts = resp.json();
          this.contacts = new Array();
          for (let entry of allContacts) {
            this.contacts.push({
              id: entry.contactId,
              instance: entry.instance,
              name: entry.name,
              sessionId: entry.sessionId,
              displayName: entry.name + " (" + entry.instance + ")",
              isPatient: entry.isPatient
            });
          }

          let c = this.mhs.getCurrentContact();
          if (c) {
            for (let contact of this.contacts) {
              if (c.displayName == contact.displayName) {
                this.current = c.sessionId;
              }
            }
            this.mhs.setCurrentContact(null);
          }
        }
      });
  }

  private startWebcam() {
    this.myVideo.nativeElement.width = 320;
    this.myVideo.nativeElement.height = 240;
    let video = this.myVideo.nativeElement;
    let constraints = {
      audio: false,
      video: true
    };
    let popup = this.snackBar;
    navigator.mediaDevices.getUserMedia(constraints)
      .then(function (stream) {
        let videoTracks = stream.getVideoTracks();
        console.log('Got stream with constraints:', constraints);
        console.log('Using video device: ' + videoTracks[0].label);
        stream.onremovetrack = function () {
          console.log('Stream ended');
        };
        video.src = window.URL.createObjectURL(stream);
        video.srcObject = stream;
        video.play();
      })
      .catch(function (error) {
        if (error.name === 'ConstraintNotSatisfiedError') {
          console.log('The resolution is not supported by your device.');
          popup.open('Upplösningen stöds inte av din enhet', 'ERROR', { duration: 4500 });
        } else if (error.name === 'PermissionDeniedError') {
          console.log('Permissions have not been granted to use your camera and ' +
            'microphone, you need to allow the page access to your devices in ' +
            'order for the demo to work.');
          popup.open('Tillstånd har inte beviljats för att använda din kamera', 'ERROR', { duration: 4500 });
        }
        else {
          console.log('getUserMedia error: ' + error.name, error);
          popup.open(error, 'ERROR', { duration: 4500 });
        }
      });
  }

  private startMilesApp() {
    this.obs1 = this.startMiles().subscribe(
      (data) => {
        if (data.status == 200) {
          this.handleApplicationCalls();
        }
      });
  }
  private handleApplicationCalls() {
    /*this.obs1 = this.getVideo().subscribe(
      (data) => {
        if (data.status == 200) {
          this.mySnap.nativeElement.src = data._body;
          //this.snap = 'data:image/png;base64,' + data._body;
        }
      });*/

    this.obs2 = this.setVideo().subscribe(
      (data) => {
      });
  }
  startMiles = () => {
    let body = {
      sessionName: 'Alkit1', //this.currentContact.sessionId,
      width: 320,
      height: 240,
      fps: 10
    };
    return this.http.post('http://localhost:6677/startMiles', body)
      .map(this.data)
      .catch((error: any) => Observable.of(error));
  }
  stopMiles = () => {
    let body = "";
    return this.http.post('http://localhost:6677/endCall', body)
      .map(this.data)
      .catch((error: any) => Observable.of(error));
  }
  getVideo = () => {
    let body = "{}";
    let headers = new Headers({ 'Content-Type': 'undefined' });
    let options = new RequestOptions({ headers: headers, withCredentials: false });
    return Observable
      .interval(100)
      .flatMap((i) => this.http.post("http://localhost:6677/getVideo", body, options)
        .map(this.data)
        .catch((error: any) => Observable.of(error)));
  }
  setVideo = () => {
    return Observable
      .interval(1000)
      .flatMap((i) => this.http.post("http://localhost:6677/setVideo", this.getCurrentImage())
        .map(this.data)
        .catch((error: any) => Observable.of(error)));
  }
  private getCurrentImage(): any {
    var ctx = this.canvas.getContext('2d');
    ctx.drawImage(this.myVideo.nativeElement, 0, 0, 320, 240);
    var data = this.canvas.toDataURL('image/jpeg');
    /*this.canvas.getContext('2d').drawImage(this.myVideo.nativeElement, 0, 0, 320, 240);
    var data = canvas.toDataURL('image/jpeg');*/

    //var data = ctx.getImageData(0, 0, 320, 240).data;
    //console.log(ctx.getImageData(0, 0, 320, 240).data.length);
    //console.log(data);
    //var test = [].slice.call(data);
    return data; //[].slice.call(ctx.getImageData(0, 0, 320, 240).data);
  }
  private data(res: Response) {
    return res;
  }

  testWebcam() {
    if (this.webcamStarted) {
      return false;
    }
    this.myVideo.nativeElement.width = 320;
    this.myVideo.nativeElement.height = 240;
    this.webcamStarted = true;
    let video = this.myVideo.nativeElement;
    let constraints = {
      audio: false,
      video: true
    };
    let errorMsg = this.errorMsg;
    let webcamStarted = this.webcamStarted;
    let popup = this.snackBar;
    navigator.mediaDevices.getUserMedia(constraints)
      .then(function (stream) {
        let videoTracks = stream.getVideoTracks();
        console.log('Got stream with constraints:', constraints);
        console.log('Using video device: ' + videoTracks[0].label);
        video.src = window.URL.createObjectURL(stream);
        video.srcObject = stream;
        video.play();
      })
      .catch(function (error) {
        errorMsg = 'Kunde inte starta webb-kameran!';
        webcamStarted = false;
        video.width = 0;
        video.height = 0;
        if (error.name === 'ConstraintNotSatisfiedError') {
          console.log('The resolution is not supported by your device.');
          popup.open('Upplösningen stöds inte av din enhet', 'ERROR', { duration: 4500 });
        } else if (error.name === 'PermissionDeniedError') {
          console.log('Permissions have not been granted to use your camera and ' +
            'microphone, you need to allow the page access to your devices in ' +
            'order for the demo to work.');
          popup.open('Tillstånd har inte beviljats för att använda din kamera', 'ERROR', { duration: 4500 });
        }
        else {
          console.log('getUserMedia error: ' + error.name, error);
          popup.open(error, 'ERROR', { duration: 4500 });
        }
      });
  }

  hideMyVideo(hide: boolean) {
    if (hide) {
      this.showMyVideo = false;
      this.myVideo.nativeElement.width = 0;
      this.myVideo.nativeElement.height = 0;
    }
    else {
      this.showMyVideo = true;
      this.myVideo.nativeElement.width = 320;
      this.myVideo.nativeElement.height = 240;
    }
  }

  startConferoSession() {
    if (this.current == "Välj kontakt") {
      this.errorMsg = "Välj en kontakt i listan först!";
      return false;
    }
    this.errorMsg = 'undefined';
    for (let c of this.contacts) {
      if (c.sessionId == this.current) {
        this.currentContact = c;
      }
    }
    this.videoSession = true;
    if (!this.webcamStarted) {
      this.startWebcam();
    }
    this.startMilesApp();
  }

  endCall() {
    if (this.videoSession) {
      this.videoSession = false;
      this.myVideo.nativeElement.width = 0;
      this.myVideo.nativeElement.height = 0;
      this.myVideo.nativeElement.srcObject.getVideoTracks()[0].stop();
      this.myVideo.nativeElement.srcObject = null;
      this.stopMiles().subscribe(
        (data) => {
          this.obs1.unsubscribe();
          this.obs2.unsubscribe();
          this.obs1 = null;
          this.obs2 = null;
        });
    }
    else if (this.webcamStarted && this.myVideo.nativeElement.srcObject) {
      this.myVideo.nativeElement.srcObject.getVideoTracks()[0].stop();
    }
  }

  snapShot() {
    var canvas = document.createElement('canvas');
    canvas.width = 320;
    canvas.height = 240;
    var ctx = canvas.getContext('2d');

    ctx.drawImage(this.myVideo.nativeElement, 0, 0, 320, 240);
    var dataURI = canvas.toDataURL('image/jpeg');

    this.mySnap.nativeElement.src = dataURI;
  }

  hover(name: string) {
    this.menuIcon = name;
    return false;
  }
  loadPage(path: string) {
    this.endCall();
    this.router.navigate([path]);
    return false;
  }
  logout() {
    this.endCall();
    this.dataservice.logOutCall().subscribe(
      (resp) => {
        this.router.navigate(['']);
      });
    return false;
  }

  downloadXmlFileForPatient() {
    
  }

}


interface Contact {
  id: number;
  instance: string;
  name: string;
  displayName: string;
  sessionId: string;
  isPatient: boolean;
}