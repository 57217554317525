import { Injectable } from '@angular/core';

@Injectable()
export class GameInformationService {

  constructor() { }

  availableBenefits: any[] = [{
    index: 1,
    name: "Rörlighet"
  }, 
  {
    index: 2,
    name: "Balans"
  }, 
  {
    index: 3,
    name: "Ben"
  }, 
  {
    index: 4,
    name: "Bål-stabilitet"
  }, 
  {
    index: 5,
    name: "Höft"
  },
  {
    index: 6,
    name: "Kognitiv"
  }, 
  {
    index: 7,
    name: "Koordination"
  }, 
  {
    index: 8,
    name: "Styrka"
  }, 
  {
    index: 9,
    name: "Uthållighet"
  }, 
  {
    index: 10,
    name: "Axlar"
  }, 
  {
    index: 11,
    name: "Armar"
  },
  {
    index: 12,
    name: "Vader"
  },
  {
    index: 13,
    name: "Framsida lår"
  }, ];

  allGameInformation: InformationContent[] = [{
    title: "Plocka bananer",
    information: ["Bananerna fångas ovanför huvudet växelvis med höger och vänster hand. Mängden repetitioner ökar och tiden mellan objekten minskar gradvis för varje nivå. Övningen ger rörlighetsträning i axellederna samt stabilitetsträning för bål/rygg.",
      "Sitt på en stol med ryggstöd, sitt på stol/pall utan ryggstöd eller stå upp med axelbredds avstånd mellan fötterna. Utgångsläge för händerna är under midjehöjd.",
      "Sitt eller stå med rak/sträckt rygg, indragen haka och sänkta axlar. Försök bibehålla denna hållning under hela övningen. Plocka så många bananer som möjligt växelvis med vänster och höger hand. Efter varje plockad banan måste båda händerna sänkas ner under midjan innan nästa banan dyker upp på skärmen.",
      "Om armen inte sträcks rakt uppåt för att plocka bananen ges instruktioner i text på skärmen och via röstkommando för att korrigera rörelsen. Vägledning ges även då användaren inte sänker händerna under midjan efter plockad banan, samt om användaren sträcker sig med motsatt hand mot bananen. I slutet av spelet presenteras den spelade nivå, snabbaste hastigheten, medelhastigheten och antalet plockade bananer i text på skärmen.",
      "Antal bananer som dyker upp"],
    picture: "assets/img/banana.png",
    searchParameters: "plocka bananer rörlighet armar 2d sittande stående",
    benefits: "Rörlighet axellederna",
    benefitSearchIndexes: [1, 10],
    choosen: false,
    dbName: "PickBananaExercise",
    positions: ["Sittande", "Stående", "Valfri"],
    activePos: "Valfri",
    variant: "EXERCISE",
  }, {
    title: "Plocka äpplen",
    information: ["Äpplena fångas på sidorna om kroppen i höjd med axlarna växelvis med motsatt hand. Mängden repetitioner och avståndet från axlarna ökar, och tiden mellan objekten minskar gradvis för varje nivå. Övningen ger rörlighetsträning i axellederna samt stabilitetsträning för bål/rygg. Om användaren står upp vid utförandet av de högre nivåerna ger övningen också benstyrka eller balans beroende på fötternas separationsavstånd.",
      "Sitt på en stol med ryggstöd, sitt på stol/pall utan ryggstöd eller stå upp med fötterna ihop (för balansträning) eller med längre avstånd mellan fötterna (för att träna benstyrka).",
      "Sitt eller stå med rak/sträckt rygg, indragen haka och sänkta axlar. Försök bibehålla denna hållning under hela övningen. Plocka så många äpplen vid sidan om kroppen att så snabbt som möjligt. Äpplena på höger sida plockas med vänster hand och äpplena på vänster sida plockas med höger hand. Mellan varje plockat äpple måste båda händerna föras tillbaka till mitten av kroppen innan nästa äpple visas.",
      "Vägledning ges om användaren försöker ta äpplet med fel hand. I slutet av spelet presenteras den spelade nivå, snabbaste hastigheten, medelhastigheten och antalet tagna äpplen i text på skärmen.",
      "Antal äpplen som dyker upp"],
    picture: "assets/img/apple.png",
    searchParameters: "plocka äpplen rörlighet armar balans 2d sittande stående",
    benefits: "Rörlighet axellederna, balans/benstyrka",
    benefitSearchIndexes: [1, 10, 8, 3, 2],
    choosen: false,
    dbName: "PickAppleExercise",
    positions: ["Sittande", "Stående", "Valfri"],
    activePos: "Valfri",
    variant: "EXERCISE",
  }, {
    title: "Boxning",
    information: ["Slå måltavlorna med händerna. Mängden repetitioner och avståndet till måltavlorna ökar och tiden mellan objekten minskar gradvis för varje nivå. Övningen ger rörlighetsträning i axellederna samt uthållighetsstyrka i arm/skuldermuskulaturen. ",
      "Sitt på stol med ryggstöd, sitt på stol/pall utan ryggstöd eller stå upp med axelbredds avstånd mellan fötterna. Utgångsläge för händerna är ”i försvarsläge” framför kroppen (armarna parallella).",
      "Sitt eller stå med rak/sträckt rygg, indragen haka och sänkta axlar. Försök bibehålla denna hållning under hela övningen. Måltavlor dyker upp på slumpmässig plats inom ett begränsat område, med lika stor mängd placerade på vänster och höger sida av skärmen. Boxa växelvis med höger och vänster hand mot målet så snabbt du kan. Efter att ha träffat målet måste båda händerna åter igen placeras i försvarsläge innan nästa måltavla visas.",
      "Vägledning ges om användaren sänker ner händerna eller inte håller händerna försvarsläge. I slutet av spelet presenteras den spelade nivån, den snabbaste hastigheten, medelhastigheten och antalet träffade måltavlor i text på skärmen.",
      "Antal mål att slå som dyker upp"],
    picture: "assets/img/boxing.png",
    searchParameters: "boxning uthållighet armar 2d sittande stående",
    benefits: "Rörlighet axellederna, uthållighet",
    benefitSearchIndexes: [1, 10, 9],
    choosen: false,
    dbName: "BoxingExercise",
    positions: ["Sittande", "Stående", "Valfri"],
    activePos: "Valfri",
    variant: "EXERCISE",
  }, {
    title: "Huvud, axlar, knä & tå",
    information: ["Sträck båda armarna uppåt, för dem till axlarna, knäna och fötterna (underbenen) osv. Mängden repetitioner ökar och fotmarkeringen placeras längre ner för varje nivå. Övningen ger rörlighetsträning i axlar, armar, bål och höfter.",
      "Sitt på stol/pall utan ryggstöd eller stå upp med axelbredds avstånd mellan fötterna.",
      "Sitt eller stå med rak/sträckt rygg, indragen haka och sänkta axlar. En röd linje är dragen mellan händerna. Förflytta händerna så att det röda sträcket hamnar inom de blå markerade områdena. Först vid huvudet, sedan vid axlarna, knäna, fötterna och så vidare. Sträck på ryggen/kroppen igen efter sista fot-markeringen.",
      "I slutet av spelet presenteras den spelade nivån och antalet tagna markeringar i text på skärmen.",
      "Antal gånger användaren övningen"],
    picture: "assets/img/hakt.png",
    searchParameters: "huvud, axlar, knä & tå rörlighet 2d sittande stående",
    benefits: "Rörlighet",
    benefitSearchIndexes: [1],
    choosen: false,
    dbName: "HsktExercice",
    positions: ["Sittande", "Stående", "Valfri"],
    activePos: "Valfri",
    variant: "EXERCISE",
  }, {
    title: "Sparka bollar",
    information: ["Sparka fotbollar i ungefär knähöjd med fötterna. Mängden repetitioner och höjdplaceringen av bollarna från marken ökar, och tiden mellan objekten minskar gradvis för varje nivå. Övningen ger uthållighetsstyrketräning i quadricep (lårets framsida) samt stabilitetsträning för bål/rygg. Om användaren står upp vid utförandet av övningen utan stöd fås även balansträning.",
      "Sitt på stol med ryggstöd, sitt på stol/pall utan ryggstöd eller stå upp med axelbredds avstånd mellan fötterna.",
      "Sitt eller stå med rak/sträckt rygg, indragen haka och sänkta axlar. Försök bibehålla denna hållning under hela övningen. Sparka med valfri fot på bollen när den dyker upp. Sparka så många bollar som möjligt och så snabbt du kan. Sätt ner fötterna på marken mellan sparkarna för att nästa boll skall dyka upp.",
      "Vägledning ges om användaren drastiskt förändrar positionen relativt till kameran under spelets gång. I slutet av spelet presenteras den spelade nivån, snabbaste hastigheten, medelhastigheten och antalet sparkade bollar i text på skärmen.",
      "Antal bollar som dyker upp"],
    picture: "assets/img/fotboll.png",
    searchParameters: "sparka bollar stabilitet uthållighetsstyrka balans 2d sittande stående",
    benefits: "Styrka/uthållighet framsida lår, bål-stabilitet",
    benefitSearchIndexes: [8, 9, 4, 13],
    choosen: false,
    dbName: "KickBallsExercise",
    positions: ["Sittande", "Stående", "Valfri"],
    activePos: "Valfri",
    variant: "EXERCISE",
  }, {
    title: "Fotboll",
    information: ["Trixa med bollen för att hålla den levande i luften så länge som möjligt. Bollens hastighet ökar gradvis för varje nivå. Övningen ger uthållighetsstyrketräning i quadricep (lårets framsida), balansträning samt stabilitetsträning för bål/rygg. Vid längre spelsessioner fås även en del konditionsträning.",
      "Stå upp med axelbredds avstånd mellan fötterna.",
      "Stå med indragen haka och sänkta axlar. Försök bibehålla denna hållning under hela övningen. Håll fotbollen levande i luften så länge som möjligt genom att kicka den med knäna eller fötterna. Förflytta kroppen i sidled för att anpassa positionen efter bollens bana i luften. Sätt ner fötterna på marken mellan sparkarna.",
      "Vägledning ges om användaren drastiskt förändrar positionen relativt till kameran under spelets gång. I slutet av spelet presenteras den spelade nivån, antalet sparkar och den totala speltiden med bollen i luften i text på skärmen.",
      "Antal bollar som man får chans att trixa med"],
    picture: "assets/img/fotboll.png",
    searchParameters: "fotboll uthållighetsstyrka kondition balans 2d stående",
    benefits: "Uthållighetsstyrka framsida lår, balans, koordination",
    benefitSearchIndexes: [9, 13, 2, 7],
    choosen: false,
    dbName: "FootballExercise",
    positions: ["Stående"],
    activePos: "Stående",
    variant: "EXERCISE",
  }, {
    title: "Kicka bollar",
    information: ["Kicka bollar går ut på att kicka så många bollar med knäna som möjligt under den angivna tiden. Bollarnas höjd och tiden mellan objekten ökar respektive minskar gradvis för varje nivå. Övningen ger styrkeuthållighet i quadriceps (lårets framsida), balansträning i stående läge utan stöd och konditionsträning vid högre nivåer.",
      "Stå upp med axelbredds avstånd mellan fötterna.",
      "Stå med rak rygg, indragen haka och sänkta axlar. Kicka bollarna så snabbt som möjligt växelvis med höger respektive vänster knä. Bollarna på höger sida tas med höger knä och vice versa. Försök bibehålla en upprätt hållning under hela övningen.",
      "Vägledning ges om användaren drastiskt förändrar positionen relativt till kameran under spelets gång, antingen genom att gå för mycket framåt eller genom att tappa hållningen. I slutet av spelet presenteras den spelade nivån, snabbaste hastigheten, medelhastigheten och antalet kickade bollar i text på skärmen.",
      "Antal sekunder övningen skall utföras"],
    picture: "assets/img/blueball.png",
    searchParameters: "kicka bollar styrka ben balans 2d stående",
    benefits: "Styrka ben/höft, balans",
    benefitSearchIndexes: [8, 3, 5, 2],
    choosen: false,
    dbName: "KneeBallsExercise",
    positions: ["Stående"],
    activePos: "Stående",
    variant: "EXERCISE",
  }, {
    title: "Höja knäna",
    information: ["Höja knäna går ut på att kicka bollar med knäna. Mängden repetitioner ökar och tiden mellan objekten minskar gradvis för varje nivå. Övningen ger styrkeuthållighet i quadriceps (lårets framsida) och konditionsträning vid högre nivåer.",
      "Sitt på en stol/pall med fötterna relativt nära varandra.",
      "Sitt med rak rygg, indragen haka och sänkta axlar. Kicka bollarna så snabbt som möjligt växelvis med höger respektive vänster knä. Bollarna på höger sida tas med höger knä och vice versa. Försök bibehålla en upprätt hållning under hela övningen.",
      "Vägledning ges om användaren försöker ta bollarna med händerna. I slutet av spelet presenteras den spelade nivån, snabbaste hastigheten, medelhastigheten och antalet kickade bollar i text på skärmen.",
      "Antal bollar som dyker upp"],
    picture: "assets/img/blueball.png",
    searchParameters: "höja knäna styrka ben 2d sittande",
    benefits: "Styrka ben/höft",
    benefitSearchIndexes: [8, 3, 5],
    choosen: false,
    dbName: "KneeBallsSittingExercise",
    positions: ["Sittande"],
    activePos: "Sittande",
    variant: "EXERCISE",
  }, {
    title: "Trampa svamp",
    information: ["Trampa på svamparna vid sidorna med fötterna. Mängden repetitioner och avståndet mellan fötterna och svamparna ökar, och tiden mellan objekten minskar gradvis för varje nivå. Övningen ger rörlighet och styrka i höfter, styrka i quadriceps (lårets framsida), samt stabilitetsträning i bålen/ryggen. Vid stående utförande utan stöd fås även balansträning.",
      "Sitt på stol/pall utan ryggstöd eller stå upp. Placera fötterna nära varandra som utgångsposition.",
      "Sitt eller stå med rak/sträckt rygg, indragen haka och sänkta axlar. Försök bibehålla denna hållning under hela övningen. Förflytta en fot i taget i sidled för att trampa på svamparna så snabbt som möjligt. Sätt ihop fötterna åter igen efter att ha trampat på svampen.",
      "Vägledning ges om användaren förflyttar båda fötterna för att ta svampen eller inte sätter ihop fötterna igen efter att ha trampat på svampen. I slutet av spelet presenteras den spelade nivån, snabbaste hastigheten, medelhastigheten och antalet trampade svampar i text på skärmen.",
      "Antal svampar som dyker upp"],
    picture: "assets/img/mushroom.png",
    searchParameters: "trampa svamp höft rörlighet styrka balans 2d stående sittande",
    benefits: "Höft, rörlighet, balans, styrka",
    benefitSearchIndexes: [5, 1, 2, 8],
    choosen: false,
    dbName: "StompMushroomsExercise",
    positions: ["Sittande", "Stående", "Valfri"],
    activePos: "Valfri",
    variant: "EXERCISE",
  }, {
    title: "Undvik flygplan (stillastående)",
    information: ["Ducka för flygplanen som flyger över skärmen. Mängden repetitioner ökar och flygplanet flyger lägre för varje nivå. Övningen ger styrketräning i benen.",
      "Stå upp med axelbredds avstånd mellan fötterna.",
      "Stå med indragen haka och sänkta axlar. Försök bibehålla denna hållning under hela övningen. Undvik flygplanen genom att böja på knäna så att flygplanet kan passera utan förhinder ovanför huvudet. Tänk på att verkligen böja på knäna och inte bara luta överkroppen framåt.",
      "Vägledning ges om användaren inte böjer ordentligt på knäna. Om användaren förflyttar sig utanför kamerans synfält under spelets gång pausas flygplanets färd tills spelaren blir synlig igen. I slutet av spelet presenteras den spelade nivån, antalet korrekt undvikta flygplan och totala antalet undvikta flygplan i text på skärmen.",
      "Antal flygplan att undvika"],
    picture: "assets/img/flygplan2.png",
    searchParameters: "undvik flygplan undvik styrka ben 2d stående",
    benefits: "Styrka ben",
    benefitSearchIndexes: [8, 3],
    choosen: false,
    dbName: "AvoidAirplanesStillExercise",
    positions: ["Stående"],
    activePos: "Stående",
    variant: "EXERCISE",
  }, {
    title: "Undvik flygplan (med sidorörelse)",
    information: ["Förflytta kroppen i sidled till markerade områdena och ducka för flygplanen som flyger över skärmen. Mängden repetitioner och sidoförflyttningarnas avstånd ökar, och flygplanet flyger gradvis lägre för varje nivå. Övningen ger styrketräning i benen samt konditionsträning.",
      "Stå upp med axelbredds avstånd mellan fötterna.",
      "Stå med indragen haka och sänkta axlar. Försök bibehålla denna hållning under hela övningen. Förflytta kroppen i sidled till de markerade områdena på skärmens sidor. Efter att ha placerat kroppen innanför markerat område dyker ett flygplan upp som ska undvikas genom att böja på knäna. Tänk på att verkligen böja på knäna och inte bara luta överkroppen framåt. Flytta till nästa markerade område på andra sidan skärmen efter att flygplanet har passerat.",
      "Vägledning ges om användaren inte böjer ordentligt på knäna och vid behov vid sidoförflyttningarna. I slutet av spelet presenteras den spelade nivån, totala antalet undvikta flygplan och antalet korrekt undvika flygplan (med böjda knän) i text på skärmen.",
      "Antal flygplan att undvika"],
    picture: "assets/img/flygplan.png",
    searchParameters: "undvik flygplan undvik styrka ben 2d stående",
    benefits: "Styrka ben",
    benefitSearchIndexes: [8, 3],
    choosen: false,
    dbName: "AvoidAirplanesExercise",
    positions: ["Stående"],
    activePos: "Stående",
    variant: "EXERCISE",
  }, {
    title: "Nicka ballonger",
    information: ["Nicka ballongerna ovanför kroppen med huvudet genom uppresning från sittande läge. Mängden repetitioner ökar och tiden mellan objekten minskar gradvis för varje nivå. Övningen ger styrketräning i quadriceps (lårets framsida).",
      "Sitt på en stol med ryggstöd/sidostöd eller sitt på stol/pall utan stöd.",
      "Sitt med rak/sträckt rygg, indragen haka och sänkta axlar. Res dig upp så snabbt som möjligt och nicka ballongerna som dyker upp. Sitt ner på stolen igen mellan varje uppresning för att nästa ballong skall dyka upp.",
      "Vägledning ges om användaren försöker ta ballongen med handen, inte sträcker på sig fullt ut för att nicka ballongen eller om användaren inte sätter sig ner efter tagen ballong. I slutet av spelet presenteras den spelade nivån, snabbaste hastigheten, medelhastigheten och antalet tagna ballonger i text på skärmen.",
      "Antal ballonger att nicka"],
    picture: "assets/img/balloon.png",
    searchParameters: "nicka ballonger styrka ben 2d uppresning",
    benefits: "Styrka ben",
    benefitSearchIndexes: [8, 3],
    choosen: false,
    dbName: "BalloonExercise",
    positions: ["Sittande"],
    activePos: "Sittande",
    variant: "EXERCISE",
  }, {
    title: "Tåhävningar",
    information: ["Placera ananasen ovanför den blåa linjen genom att göra tåhävningar. Mängden repetitioner ökar och tiden mellan objekten minskar gradvis för varje nivå. Övningen ger träning av vadmuskulaturen samt balans.",
      "Stå stadigt med hela fotsulan i marken med axelbredds avstånd mellan fötterna.",
      "Stå med rak/sträckt rygg, indragen haka och sänkta axlar. Försök bibehålla denna hållning under hela övningen. En ananas är placerad ovanför spelarens huvud och en blå linje är dragen en bit ovanför. Gör tåhävningar genom att skjuta ifrån golvet och kom upp på tå för att placera ananasen ovanför den blåa linjen. Sänk ner hälen sakta mot marken efter att ha passerat den blåa linjen.",
      "Vägledning ges om användaren drastiskt förändrar positionen relativt till kameran under spelets gång. I slutet av spelet presenteras den spelade nivån och antalet ananaser detekterade ovanför linjen i text på skärmen.",
      "Antal tåhävningar att utföra"],
    picture: "assets/img/Pineapple.png",
    searchParameters: "tåhävningar styrka vader 2d stående",
    benefits: "Styrka vader",
    benefitSearchIndexes: [8, 12],
    choosen: false,
    dbName: "HeelRaisesExercise",
    positions: ["Stående"],
    activePos: "Stående",
    variant: "EXERCISE",
  }, {
    title: "Fruktplockning",
    information: ["Fånga så många av frukterna på skärmen som möjligt och undvik de andra objekten. Antal objekt, avståndet mellan objekten och speltiden ökar gradvis för varje nivå. Övningen ger kognitiv träning och uthållighetsstyrka i arm/skuldermuskulaturen.",
      "Sitt på stol med ryggstöd, sitt på stol/pall utan ryggstöd eller stå upp med axelbredds avstånd mellan fötterna. Håll upp händerna framför kroppen.",
      "Ett antal olika objekt dyker upp på skärmen. Målet är att fånga på så många frukter som möjligt genom att sträcka händerna framåt. Plocka en frukt i taget och för tillbaka handen efter varje fångat objekt. Undvik att fånga objekt som inte är frukter, dessa ger minus poäng. Förflytta kroppen i sidled för att nå objekten vid kanterna av skärmen.",
      "Vägledning ges om händerna inte förs tillbaka mot kroppen mellan plockningen. I slutet av spelet presenteras den spelade nivån, antalet plockade frukter, antal feltagna objekt samt den totala poängen.",
      "Antal frukter som dyker upp"],
    picture: "assets/img/fruits.png",
    searchParameters: "fruktplockning uthållighet armar kognitiv 2d stående sittande",
    benefits: "Uthållighet/Rörlighet armar",
    benefitSearchIndexes: [9, 1, 11],
    choosen: false,
    dbName: "PickFruitsExercise",
    positions: ["Sittande", "Stående", "Valfri"],
    activePos: "Valfri",
    variant: "EXERCISE",
  }, {
    title: "Plattform-hoppning",
    information: ["Styr kaninen på skärmen uppåt genom att hoppa på plattformarna som dyker upp. Kaninen hoppar automatiskt och styrs till höger eller vänster med benlyft åt sidan. Avståndet mellan plattformarna ökar och speciella effekter läggs till för att göra övningen gradvis svårare för varje nivå. Övningen ger styrketräning för höften och balans om inget stöd används.",
      "Stå med rak/sträckt rygg, indragen haka och sänkta axlar. Placera fötterna med axelbredds avstånd.",
      "Styr den hoppande kaninen uppåt genom att hoppa på plattformarna som dyker upp på skärmen. Sträck höger ben åt sidan eller bakåt för att svänga åt höger och vice versa för att svänga åt vänster. Plattformarna har olika färger som representerar olika beteenden och svårighetsgrader. Det går även att transportera kaninen från den ena sidan av skärmen till den andra genom att styra kaninen genom väggen. Övningen avslutas då kaninen missar alla plattformarna och faller ner genom den nedre delen av skärmen.",
      "Vägledning ges om kaninen inte har nått högre höjder inom en viss tid. I slutet av spelet presenteras den spelade nivån, totala poängen (som motsvarar hur högt upp man kom) och hur länge spelet varade i text på skärmen.",
      "Tid i sekunder som spelet ska utföras."],
    picture: "assets/img/Fluffy_Bunny.png",
    searchParameters: "plattform-hoppning höfter styrka balans kognitiv 2d stående",
    benefits: "Styrka höft, balans",
    benefitSearchIndexes: [8, 5, 2],
    choosen: false,
    dbName: "PlatformJumpingExercise",
    positions: ["Stående"],
    activePos: "Stående",
    variant: "EXERCISE",
  }, {
    title: "Ormen",
    information: ["Styr ormen mot maten så att ormen växer och undvik att korsa sin egen svans. Ju högre nivån är desto längre avstånd är det till maten. Det här är en planering och utförande variant av kognitivträning. Övningen ger även rörlighetsträning i arm/skulderområdet och längre spelsessioner bidrar till uthållighetsstyrka i arm/skulderområdet.",
      "Sitt på stol/pall utan ryggstöd eller stå upp med axelbredds avstånd mellan fötterna.",
      "Kontrollera ormens huvud med handen och för den mot maten (rosa prickarna på skärmen). För varje tagen matbit ökar ormens längd och tar därmed upp mer plats på skärmen. Ormen får inte korsa sin egen svans under spelets gång, dvs om ormen biter sin egen svans så avslutas spelomgången. Till och från dyker det upp specialobjekt på skärmen: ett hjärta, en mus eller en bomb. Hjärtat minskar längden på ormen, musen är värd mer poäng men ger en utbuktnad på svansen och bomben exploderar efter några sekunder och skadar ormen om den är för nära (vilket ger minus poäng). Första spelomgången styrs ormen med höger hand och i andra omgången styrs den med vänster hand.",
      "I slutet av spelet presenteras den spelade nivån, ormens längd med höger hand, ormens längd med vänster hand och speltiden på höger respektive vänster sida i text på skärmen.",
      "Har ingen inverkan på övningen"],
    picture: "assets/img/snake.png",
    searchParameters: "ormen armar rörlighet kognitiv 2d stående sittande",
    benefits: "Rörlighet/uthållighetsstyrka armar, koordination",
    benefitSearchIndexes: [1, 9, 8, 11, 7],
    choosen: false,
    dbName: "SnakeExercise",
    positions: ["Sittande", "Stående", "Valfri"],
    activePos: "Valfri",
    variant: "EXERCISE",
  }, {
    title: "Fånga rektanglar",
    information: ["Fånga så många rektanglar som möjligt med den aktiva handen. Storleken på rektanglarna minskar och dess hastighet ökar gradvis för varje nivå. Det här är en planering och utförande variant av kognitivträning som utformats speciellt för strokepatienter.",
      "Sitt på stol/pall utan ryggstöd eller stå upp med axelbredds avstånd mellan fötterna.",
      "Röda rektanglar i varierande storlek färdas längs med skärmen. När de röda rektanglarna passerat halva skärmen skiftar de färg till blått. Ta de blåa rektanglarna med den aktiva handen, markerad med en rosa prick. Skifta aktiv hand genom att överföra den rosa pricken till andra handen genom handkontakt.",
      "I slutet av spelet presenteras den spelade nivån, totala antalet tagna rektanglar, antal rektanglar tagna med höger hand och antalet rektanglar tagna med vänster hand i text på skärmen.",
      "Antal rektanglar som dyker upp"],
    picture: "assets/img/rect.png",
    searchParameters: "fånga rektanglar kognitiv 2d stående sittande",
    benefits: "Kognitiv träning",
    benefitSearchIndexes: [6],
    choosen: false,
    dbName: "CaptureRectanglesExercise",
    positions: ["Sittande", "Stående", "Valfri"],
    activePos: "Valfri",
    variant: "EXERCISE",
  }, {
    title: "Studsa bollen",
    information: ["Kontrollera bollen med händerna och se till att den befinner sig innanför ramens kanter. Bollens hastighet ökar och ramens storlek minskar gradvis för varje nivå. Det här är en planering och utförande variant av kognitivträning som utformats speciellt för strokepatienter.",
      "Sitt på stol/pall utan ryggstöd eller stå upp med axelbredds avstånd mellan fötterna.",
      "Använd händerna för att hålla den blåa bollen innanför den vita ramens kanter. Bollen färdas i motsatt vinkelrät riktning från där handen nuddade kanten. Spelet avslutas när bollen passerar en av kanterna på ramen.",
      "I slutet av spelet presenteras den spelade nivån, längsta speltiden och antalet beröringar på bollen under den speltiden i text på skärmen.",
      "Antal bollar som får komma utanför ramen innan spelet tar slut"],
    picture: "assets/img/ball.png",
    searchParameters: "studsa bollen kognitiv 2d stående sittande",
    benefits: "Kognitiv träning",
    benefitSearchIndexes: [6],
    choosen: false,
    dbName: "BounceBallExercise",
    positions: ["Sittande", "Stående", "Valfri"],
    activePos: "Valfri",
    variant: "EXERCISE",
  }, {
    title: "Jonglering",
    information: ["Trixa med bollarna för att hålla dem levande i luften så länge som möjligt. Antalet bollar samt dess hastighet ökar gradvis för varje nivå. Övningen ger rörlighetsträning i arm/skulderområdet och koordinationsträning.",
      "Sitt på stol/pall utan ryggstöd eller stå upp med axelbredds avstånd mellan fötterna.",
      "Använd händerna för att hålla bollarna levande i luften så länge som möjligt. Ju högre nivå desto fler bollar hanteras samtidigt. Vid nivå 4 - 5 kolliderar även bollarna med varandra så att riktningen på bollarna kan ändras i luften. Övningen avslutas då samtliga bollar har hamnat på marken.",
      "I slutet av spelet presenteras den spelade nivån och speltiden med bollarna i luften i text på skärmen.",
      "Har ingen inverkan på övningen"],
    picture: "assets/img/Tennisboll.png",
    searchParameters: "jonglering kognitiv koordination 2d stående sittande",
    benefits: "Kognitiv träning, koordination",
    benefitSearchIndexes: [6, 7],
    choosen: false,
    dbName: "JugglingExercise",
    positions: ["Sittande", "Stående", "Valfri"],
    activePos: "Valfri",
    variant: "EXERCISE",
  }, {
    title: "Boule",
    information: ["Kasta bollarna (med en underarms sving) så nära som möjligt den lilla röda bollen placerad en bit bort. Terrängen ändras gradvis för att göra det svårare och mer fart behövs i svingen vid högre nivåer. Övningen ger koordnitationsträning.",
      "Stå upp med axelbredds avstånd mellan fötterna.",
      "Målet med övningen är att kasta bollarna så att de hamnar så nära den röda lilla bollen som möjligt. Håll upp handen du vill kasta med ovanför huvudet för att få kontroll över bollen. För handen bakåt och svinga därefter handen framåt för att kasta.",
      "Vägledning ges om handen inte förs tillbaka ordentligt innan kastet. I slutet av spelet presenteras den spelade nivån, kortaste avståndet och medelavståndet till den lilla röda bollen i text på skärmen.",
      "Antal bollar att kasta"],
    picture: "assets/img/boule.png",
    searchParameters: "boule koordination 3d stående",
    benefits: "Koordination",
    benefitSearchIndexes: [7],
    choosen: false,
    dbName: "BouleExercise",
    positions: ["Stående"],
    activePos: "Stående",
    variant: "EXERCISE",
  }, {
    title: "Jage i skogen",
    information: ["Spring runt i skogen och fånga så många av de gömda objekten som möjligt. För varje nivå förlängs speltiden, antalet eftersökta objekt minskar och efter nivå 3 förflyttar sig även objekten under tiden. Övningen ger uthållighetsträning och uthållighetsstyrka i benen.",
      "Stå upp med axelbredds avstånd mellan fötterna. Försök bibehålla en upprätt hållning under hela övningen.",
      "Din uppgift är att hitta så många av de gömda objekten i skogen som möjligt (fjärilar eller kaniner). Gå på stället med höjda knän för att flytta ramåt i spelet. Desto snabbare frekvens på benen desto snabbare förflyttningar framåt. Sträck ut höger arm åt sidan för att svänga åt höger och vänster arm utsträckt för att gå åt vänster.",
      "Vägledning ges om fötterna inte är synliga för sensorn och om ingen förflyttning skett under en viss period. I slutet av spelet presenteras den spelade nivån, antal fångade objekt och speltiden i text på skärmen.",
      "Tid i sekunder som spelet ska utföras."],
    picture: "assets/img/hqdefault.jpg",
    searchParameters: "jage i skogen uthållighet styrka ben 3d stående",
    benefits: "Uthållighetsstyrka ben",
    benefitSearchIndexes: [9, 8, 3],
    choosen: false,
    dbName: "ForestRunningExercise",
    positions: ["Stående"],
    activePos: "Stående",
    variant: "EXERCISE",
  }, {
    title: "Bågskytte",
    information: ["Skjut pilar mot måltavlan. Desto närmare mitten av tavlan pilen träffar, desto mer poäng ges. Övningen ger koordnination- och kognitiv träning.",
      "Stå upp med axelbredds avstånd mellan fötterna.",
      "Sträck fram höger eller vänster arm för att sikta på måltavlan. Placera den andra handen bredvid den utsträckta handen, som att du laddar upp för att skjuta iväg en pil. Dra den andra handen sakta bakåt mot axeln med armbågen vinklad utåt i axelhöjd. Pilen skjuts iväg då handen är i närheten av axeln.",
      "Vägledning ges om armbågen inte är vinklad ut från kroppen när pilen skjuts iväg eller om ingen siktet är utanför det markerade området. I slutet av spelet presenteras den spelade nivån, antal skjutna pilar och totala poängen.",
      "Antal pilar att skjuta"],
    picture: "assets/img/targetboard.png",
    searchParameters: "bågskytte koordniation armar styrka 3d stående",
    benefits: "Koordination",
    benefitSearchIndexes: [7],
    choosen: false,
    dbName: "ArrowExercise",
    positions: ["Stående"],
    activePos: "Stående",
    variant: "EXERCISE",
  }, {
    title: "Rymdspel",
    information: ["Styr rymdfarkosten genom asteroidbältet och samla poäng genom att skjuta på objekten (ateroider, meteroiter, stridsskepp osv). För varje nivå ökar antalet objekt och dess hastighet, samt att det tillkommer nya/svårare objekt. Övningen ger styrketräning i biceps och koordinationsträning.",
      "Sitt på en stol med ryggstöd, sitt på stol/pall utan ryggstöd eller stå upp med axelbredds avstånd mellan fötterna. Håll överarmarna parallellt med kroppen. Det rekommenderas att ha vikter eller liknande i händerna vid övningens utförande.",
      "Spelet går ut på att undvika asteroider och fiendeskepp genom att styra rymdfarkosten i sidled eller genom att skjuta. Farkosten styrs genom att köra bicepcurls med höger eller vänster arm. Håll överarmen stilla parallellt med kroppen och för handen upp mot axeln. Skjut genom att separera knäna på valfritt sätt eller genom att gapa med munnen.",
      "Vägledning ges om handen inte förs upp framför/parallellt med kroppen eller om händerna inte sänks ner ordentligt mellan lyften. I slutet av spelet presenteras den spelade nivån, totala poängen och speltiden i text på skärmen.",
      "Tid i sekunder som spelet ska utföras."],
    picture: "assets/img/spaceship.png",
    searchParameters: "rymdspel koordniation armar styrka 3d stående",
    benefits: "Styrka armar",
    benefitSearchIndexes: [8, 11],
    choosen: false,
    dbName: "SpaceShooterExercise",
    positions: ["Sittande", "Stående", "Valfri"],
    activePos: "Valfri",
    variant: "EXERCISE",
  }, {
    title: "Bubbelattack",
    information: ["Styr avataren med kroppen genom fältet av färgade objekt och samla så många poäng som möjligt. För varje nivå ökar antalet och hastigheten på de förbifarande objekten. Övningen ger kognitiv- och koordinationsträning samt uthållighet och rörlighet för hela kroppen.",
      "Stå upp med axelbredds avstånd mellan fötterna.",
      "Styr avataren och fånga så många gröna former som möjligt och undik samtidigt alla röda och gula former. Styr avataren genom att röra dig själv i sidled, böja knäna eller luta på kroppen. Spelet avslutas efter att 10 st gröna markeringar har passerat bakom avataren. Varje fångad grön form ger 1 poäng och de röda och gula formerna ger minus poäng.",
      "Vägledning ges i form av ljud när rätt/fel objekt tas. I slutet av spelet presenteras den spelade nivån, totala poängen och speltiden i text på skärmen.",
      "Tid i sekunder som spelet ska utföras."],
    picture: "assets/img/ball.png",
    searchParameters: "bubbelattack kognitiv koordniation kroppen uthållighet rörlighet 3d stående",
    benefits: "Kognitiv träning, rörlighet, uthållighet",
    benefitSearchIndexes: [6, 1, 9],
    choosen: false,
    dbName: "BubbleInvaderExercise",
    positions: ["Stående"],
    activePos: "Stående",
    variant: "EXERCISE",
  }, {
    title: "Plocka apelsiner",
    information: [
      "Stå på gummibandet och håll ett handtag i var hand. Lyft ena armen snett framför kroppen. Armen skall vara lätt böjd och lyftas till axelhöjd, inte över 90 grader. Nudda apelsinen och upprepa. Kan även göras i sittande.",
      "Övningen kan göras i sittande eller stående. " + 
        "Placera mittendelen av gummibandet under fötterna och håll ett handtag i var hand. Lyft ena armen snett framför kroppen.",
      "Armen skall vara lätt böjd och lyftas ungefär till axelhöjd, inte över 90 grader. " +
        "Tänk på att ha stolt hållning och spänna bålen under hela övningen. Gummibandet skall hålla spänt under hela övningen." +
        "Utför samtliga repetitioner på ena sidan och byt sedan till andra armen." +
        "Övningen kan göras i sittande eller stående." + 
        "Ifall övningen görs i sittande behöver gummibandet kortas något för att kunna hållas spänt under hela rörelsen.",
      "Vägledning ges i form av val av rätt band. Feedback ges i form av ljud vid tagna objekt. I slutet av spelet presenteras den spelade nivån, totala poängen och speltiden i text på skärmen.",
      "Totalt antal apelsiner som ska tas."],
    picture: "assets/img/orange.png",
    searchParameters: "plocka apelsiner armar sittande styrka stående",
    benefits: "Styrka armar axlar",
    benefitSearchIndexes: [10, 11, 8],
    choosen: false,
    dbName: "PickOrangesExercise",
    positions: ["Sittande", "Stående", "Valfri"],
    activePos: "Valfri",
    variant: "EXERCISE",
  }, {
    title: "Sparka stjärnor",
    information: [
      "Utförs i sittande med ryggen mot ryggstödet. Sätt utprovat gummiband runt anklarna. Sparka det ena benet rakt fram så att foten nuddar stjärnan. Knät skall bli rakt. Återgå långsamt och upprepa.",
      "Placera händerna vid sidan av stolen så att du har något att hålla fast i. Sparka det ena benet rakt " +
      "fram tills knät är helt rakt, den andra foten skall vara kvar i marken.",
      "Sätt dig långt in på en stol och stöd ryggen mot ryggstödet. Sätt utprovat gummiband runt vristerna. " +
        "Placera händerna vid sidan av stolen så att du har något att hålla fast i. Sparka det ena benet rakt " +
        "fram tills knät är helt rakt, den andra foten skall vara kvar i marken. Återgå långsamt och upprepa. " +
        "Utför samtliga repetitioner på ena sidan och byt sedan till andra benet.",
      "Vägledning ges i form av val av rätt band. Feedback ges i form av ljud vid tagna objekt. I slutet av spelet presenteras den spelade nivån, totala poängen och speltiden i text på skärmen.",
      "Totalt antal stjärnor som ska tas."],
    picture: "assets/img/star.png",
    searchParameters: "sparka stjärnor armar sittande styrka stående",
    benefits: "Styrka ben",
    benefitSearchIndexes: [3, 8],
    choosen: false,
    dbName: "KickStarsExercise",
    positions: ["Sittande"],
    activePos: "Sittande",
    variant: "EXERCISE",
  }, {
    title: "Kombocurl",
    information: [
      "Stå på gummibandet och håll ett handtag i var hand. Armbågarna placeras vid sidan av kroppen. Dra en hand i taget mot axeln genom att böja i armbågen. Övningen kan också göras i sittande.",
      "Placera gummibandet under fötterna och håll ett handtag i var hand. " +
        "Håll armbågarna stilla längs med sidan av kroppen och dra en hand i taget mot axeln genom att böja i armbågen. Du skall nudda alla klot på vägen. ",
      "Placera gummibandet under fötterna och håll ett handtag i var hand. " +
        "Håll armbågarna stilla längs med sidan av kroppen och dra en hand i taget mot axeln genom att böja i armbågen. Du skall nudda alla klot på vägen. " +
        "Spänn bålen och ha stolt hållning under hela övningen. Utför samtliga repetitioner på ena sidan och byt sedan till andra armen. " +
        "Övningen kan göras i sittande eller stående. " +
        "Om övningen görs i sittande måste gummibandet kortas något för att hållas spänt under hela rörelsen. ",
      "Vägledning ges i form av val av rätt band. Feedback ges i form av ljud vid tagna objekt. I slutet av spelet presenteras den spelade nivån, totala poängen och speltiden i text på skärmen.",
      "Totalt antal curls som ska göras. En curl räknas som en arm som rör sig från höften till axeln och tillbaka."],
    picture: "assets/img/blueball.png",
    searchParameters: "kombocurl curl armar sittande styrka stående",
    benefits: "Styrka armar",
    benefitSearchIndexes: [11, 8],
    choosen: false,
    dbName: "KomboCurlExercise",
    positions: ["Sittande", "Stående", "Valfri"],
    activePos: "Valfri",
    variant: "EXERCISE",
  }, {
    title: "Rodd med raka armar",
    information: [
      "Sitt eller stå vänd med sidan mot skärmen och din näsa mot gummibandets fästpunkt. Ha lätt böjda armar framför kroppen och dra armarna bakåt längs med sidan av kroppen. Återgå långsamt och upprepa.",
      "Fäst utprovat gummiband i ett dörrhandtag eller annan fästanordning. " +
        "Stå vänd mot fästpunkten med sidan mot kameran. Ta tag i gummibandet och backa så att bandet hålls spänt under hela övningen. " +
        "Ha lätt böjda armar framför kroppen och dra armarna bakåt längs med sidan av kroppen. Återgå långsamt och upprepa. ",
      "Ro så att båten flyttar sig framåt. Fäst utprovat gummiband i ett dörrhandtag eller annan fästanordning. " +
        "Stå vänd mot fästpunkten med sidan mot kameran. Ta tag i gummibandet och backa så att bandet hålls spänt under hela övningen. " +
        "Ha lätt böjda armar framför kroppen och dra armarna bakåt längs med sidan av kroppen. Återgå långsamt och upprepa. " +
        "Tänk på att spänna bålen och skjut frambröstkorgen något. Övningen kan göras i sittande eller stående. ",
      "Vägledning ges i form av val av rätt band samt uppmaningar om att ha raka armar. Feedback ges i form av att båten ros framåt. I slutet av spelet presenteras den spelade nivån, totala poängen och speltiden i text på skärmen.",
      "Totalt antal strokes som ska göras. En stroke räknas som en hand som rör sig från att ha varit framför höften till bakom den."],
    picture: "assets/img/row-straigth.png",
    searchParameters: "rodd axlar armar sittande styrka stående",
    benefits: "Styrka armar axlar",
    benefitSearchIndexes: [11, 8, 10],
    choosen: false,
    dbName: "RowingStraigthExercise",
    positions: ["Sittande", "Stående", "Valfri"],
    activePos: "Valfri",
    variant: "EXERCISE",
  }, {
    title: "Rodd med böjda armar",
    information: [
      "Sitt eller stå vänd med sidan mot skärmen och din näsa mot gummibandets fästpunkt. Håll armarna framför kroppen, dra gummibandet mot bröstet med armbågarna böjda i ca 90 grader. Återgå långsamt och upprepa.",
      "Fäst utprovat gummiband i ett dörrhandtag eller annan fästanordning. " +
        "Stå vänd mot fästpunkten med sidan mot kameran. Ta tag i gummibandet och backa så att bandet hålls spänt under hela övningen. " +
        "Håll armarna framför kroppen och dra gummibandet mot bröstet med armbågarna böjda i ca 90 grader. Låt skulderbladen följa med i rörelsen. ",
      "Ro så att båten flyttar sig framåt. Fäst utprovat gummiband i ett dörrhandtag eller annan fästanordning. " +
        "Stå vänd mot fästpunkten med sidan mot kameran. Ta tag i gummibandet och backa så att bandet hålls spänt under hela övningen. " +
        "Håll armarna framför kroppen och dra gummibandet mot bröstet med armbågarna böjda i ca 90 grader. Låt skulderbladen följa med i rörelsen. " +
        "Återgå långsamt och upprepa. Tänk på att spänna bålen och skjuta frambröstkorgen något. Övningen kan göras i sittande eller stående. ",
      "Vägledning ges i form av val av rätt band samt uppmaningar om att ha raka och böjda armar vid respektive steg i rörelsen. Feedback ges i form av att båten ros framåt. I slutet av spelet presenteras den spelade nivån, totala poängen och speltiden i text på skärmen.",
      "Totalt antal strokes som ska göras. En stroke räknas som en hand som rör sig från att ha varit framför kroppen till bröstet."],
    picture: "assets/img/row-bent.png",
    searchParameters: "rodd axlar armar sittande styrka stående",
    benefits: "Styrka armar axlar",
    benefitSearchIndexes: [11, 8, 10],
    choosen: false,
    dbName: "RowingBentExercise",
    positions: ["Sittande", "Stående", "Valfri"],
    activePos: "Valfri",
    variant: "EXERCISE",
  }, {
    title: "Köra i skog",
    information: [
      "Denna övning kräver att det finns en tillgänglig cykel. Cykla runt bilen runt sjön.", 
      "Ställ cyklen framför systemet och cykla för att starta igång den. Låt systemet koppla upp sig mot cykeln och börja cykla.",
      "Om man väljer att patienten ska uppskatta sin ansträngning och andfåddhet under övningen så sker detta efter spelets avslutas. Om man väljer att patienten skall mäta saturation och puls så görs detta en gång i minuten. Nivån bestämmer initiella trögheten på cykeln. Denna kan användaren sedan sänka under cyklingens gång.",
      "Feedback ges i form av att bilen körs framåt i hastighet relativt hastigheten på cyklandet. I slutet av spelet presenteras den spelade nivån, totala poängen och speltiden i text på skärmen.",
      "Antal minuter som ska cyklas"],
    picture: "assets/img/cycleForest.png",
    searchParameters: "cykla skog köra sittande styrka",
    benefits: "Uthållighet styrka ben",
    benefitSearchIndexes: [3, 8, 9],
    choosen: false,
    dbName: "DriveForestExercise",
    positions: ["Sittande"],
    activePos: "Sittande",
    variant: "EXERCISE",
  }
  ];

  assessmentVersions: any[] = [
    {
      name: "Ballong-test",
      picture: "assets/img/balloon.png",
      description: "Ballong-testet används för att skatta individens benstyrkekapacitet. Ballong-testet är en form av Nicka ballonger övningen, där övningen körs under 30 sekunder med målet att nicka så många ballonger som möjligt under den perioden. Antalet fångade ballonger representerar antalet uppresningar användaren lyckats genomföra under 30 sekunder.",
      dbName: "BalloonTestAssessment",
      benefits: "Benstyrketest",
      choosen: false,
    }, {
      name: "VAS ansträngning",
      picture: "assets/img/effort.png",
      description: "För att skatta individens ansträngningsnivå används en vas-skala från 0-100, där 0 motsvarar minimal ansträngning och 100 motsvarar maximal ansträngning. Det är utövaren själv som skattar sin egen nivå genom att dra en slider till det värdet som bäst passar in på situationen. Skattningen sker antingen direkt efter en specifik övning eller efter att alla planerade övningar för dagen utförts för att skatta nivån för hela passet.",
      dbName: "VASEffortAssessment",
      benefits: "Uppskatta ansträngningsnivå",
      choosen: false,
    }, {
      name: "VAS andfåddhet",
      picture: "assets/img/breath.png",
      description: "För att skatta individens andfåddhetsnivå används en vas-skala från 0-100, där 0 motsvarar minimal andfåddhet och 100 motsvarar maximal andfåddhet. Det är utövaren själv som skattar sin egen nivå genom att dra en slider till det värdet som bäst passar in på situationen. Skattningen sker antingen direkt efter en specifik övning eller efter att alla planerade övningar för dagen utförts för att skatta nivån för hela passet.",
      dbName: "VASBreathAssessment",
      benefits: "Uppskatta andfåddhet",
      choosen: false,
    }, {
      name: "CAT (COPD assessment test)",
      picture: "assets/img/cat.png",
      description: "Frågeformuläret hjälper till att mäta den inverkan KOL (kroniskt obstruktiv lungsjukdom) har på användarens välbefinnande och dagliga liv. Svaren och testresultaten kan användas för att förbättra vården av användarens KOL och för att få bästa utbyte av behandlingen.",
      dbName: "CATAssessment",
      benefits: "Frågeformulär",
      choosen: false,
    }, {
      name: "mMRC",
      picture: "assets/img/lungs.png",
      description: "mMRC-skalan används för att beskriva hur en individs andningssvårigheter påverkar dagliga aktiviteter. Skalan mäter en persons begränsningsbas mellan 1-5. Det slutliga värdet motsvarar personens grad av funktionshinder som orsakas av andfåddhet.",
      dbName: "mMRCAssessment",
      benefits: "Andningssvårigheters påverkan på daglig aktivitet",
      choosen: false,
    }, {
      name: "Ansträngning skala",
      picture: "assets/img/EffortRPE.png",
      description: "Skala som mäter användarens uppskattade ansträngningsgrad på en skala mellan 7 och 20.",
      dbName: "RPEAssesment",
      benefits: "Upplevd ansträngningsgrad under övningar",
      choosen: false,
    }
    , {
      name: "Andfåddhet skala",
      picture: "assets/img/BreathCR10.png",
      description: "Skala som mäter användarens uppskattade andfåddhet på en skala mellan 0 och 11.",
      dbName: "CR10Assesment",
      benefits: "Upplevd andfåddhet under övningar",
      choosen: false,
    }
  ];

  getCompleteInformation(): any[] {
    return this.allGameInformation;
  }

  getPictureForGameName(name: string) : string {
    for(var  i = 0; i < this.allGameInformation.length; i++) {
      if (this.allGameInformation[i].title == name) {
        return this.allGameInformation[i].picture;
      }
    }
    for(var  i = 0; i < this.assessmentVersions.length; i++) {
      if (this.assessmentVersions[i].name == name) {
        return this.assessmentVersions[i].picture;
      }
    }
  }

  gameName(name: string): string {
    if (name === 'PickBananaExercise') {
      return 'Plocka bananer';
    }
    else if (name === 'PickAppleExercise') {
      return 'Plocka äpplen';
    }
    else if (name === 'BoxingExercise') {
      return 'Boxning';
    }
    else if (name === 'HsktExercice') {
      return 'Huvud, axlar, knä & tå';
    }
    else if (name === 'KickBallsExercise') {
      return 'Sparka bollar';
    }
    else if (name === 'FootballExercise') {
      return 'Fotboll';
    }
    else if (name === 'KneeBallsExercise') {
      return 'Kicka bollar';
    }
    else if (name === 'StompMushroomsExercise') {
      return 'Trampa svamp';
    }
    else if (name === 'AvoidAirplanesStillExercise') {
      return 'Undvik flygplan (stillastående)';
    }
    else if (name === 'AvoidAirplanesExercise') {
      return 'Undvik flygplan (med sidorörelse)';
    }
    else if (name === 'BalloonExercise') {
      return 'Nicka ballonger';
    }
    else if (name === 'HeelRaisesExercise') {
      return 'Tåhävningar';
    }
    else if (name === 'SnakeExercise') {
      return 'Ormen';
    }
    else if (name === 'CaptureRectanglesExercise') {
      return 'Fånga rektanglar';
    }
    else if (name === 'BounceBallExercise') {
      return 'Studsa Bollen';
    }
    else if (name === 'JugglingExercise') {
      return 'Jonglering';
    }
    else if (name === 'KneeBallsSittingExercise') {
      return 'Höja knäna';
    }
    else if (name === 'PickFruitsExercise') {
      return 'Fruktplockning';
    }
    else if (name === 'PlatformJumpingExercise') {
      return 'Plattform-hoppning';
    }
    else if (name === 'BouleExercise') {
      return 'Boule';
    }
    else if (name === 'ForestRunningExercise') {
      return 'Jage i skogen';
    }
    else if (name === 'ArrowExercise') {
      return 'Bågskytte';
    }
    else if (name === 'SpaceShooterExercise') {
      return 'Rymdspel';
    }
    else if (name === 'BubbleInvaderExercise') {
      return 'Bubbelattack';
    }
    else if (name === 'BalloonTestAssessment') {
      return 'Ballong-test';
    }
    else if (name === 'VASEffortAssessment') {
      return 'VAS ansträngning';
    }
    else if (name === 'VASBreathAssessment') {
      return 'VAS andfåddhet';
    }
    else if (name === 'CATAssessment') {
      return 'CAT (COPD assessment test)';
    }
    else if (name === 'mMRCAssessment') {
      return 'mMRC';
    }
    else {
      for (let index = 0; index < this.allGameInformation.length; index++) {
        if (this.allGameInformation[index].dbName === name) {
          return this.allGameInformation[index].title;
        }
      }
      for (let index = 0; index < this.assessmentVersions.length; index++) {
        if (this.assessmentVersions[index].dbName === name) {
          return this.assessmentVersions[index].name;
        }
      }
    }
    
    return name;
  }

  positionType(pos: string): string {
    if (pos === 'SITTING') {
      return 'Sittande';
    }
    else if (pos === 'STANDING') {
      return 'Stående';
    }
    return 'Valfri';
  }

  getCATQuestionair(): any[] {
    let quest = [
      {
        minText: "Jag hostar aldrig",
        maxText: "Jag hostar ständigt",
        values: [0, 1, 2, 3, 4, 5],
        currentValue: -1,
      }, {
        minText: "Jag har inte något slem i bröstet alls",
        maxText: "Mitt bröst är helt fyllt med slem",
        values: [0, 1, 2, 3, 4, 5],
        currentValue: -1
      }, {
        minText: "Jag känner inte alls något tryck över bröstet",
        maxText: "Jag känner mycket tryck över bröstet",
        values: [0, 1, 2, 3, 4, 5],
        currentValue: -1
      }, {
        minText: "När jag går uppför en backe eller en trappa blir jag inte andfådd",
        maxText: "När jag går uppför en backe eller en trappa blir jag mycket andfådd",
        values: [0, 1, 2, 3, 4, 5],
        currentValue: -1
      }, {
        minText: "Jag är inte begränsad när det gäller att utföra några aktiviteter i hemmet",
        maxText: "Jag är mycket begränsad när det gäller att utföra aktiviteter i hemmet",
        values: [0, 1, 2, 3, 4, 5],
        currentValue: -1
      }, {
        minText: "Jag känner mig trygg att lämna mitt hem trots mitt lungtillstånd",
        maxText: "Jag känner mig inte alls trygg att lämna mitt hem på grund av mitt lungtillstånd",
        values: [0, 1, 2, 3, 4, 5],
        currentValue: -1
      }, {
        minText: "Jag sover bra",
        maxText: "Jag sover inte bra på grund av mitt lungtillstånd",
        values: [0, 1, 2, 3, 4, 5],
        currentValue: -1
      }, {
        minText: "Jag har massor av energi",
        maxText: "Jag har inte någon energi alls",
        values: [0, 1, 2, 3, 4, 5],
        currentValue: -1
      }];
    return quest;
  }

  getmMRCQuestionair(): any[] {
    let mMRC = [{
      text: "Ingen andnöd annat än vid mycket kraftig ansträngning",
      choosen: false,
    }, {
      text: "Andnöd vid snabb promenad eller vid gång i uppförsbacke",
      choosen: false,
    }, {
      text: "Går långsammare än personer i samma ålder på grund av andnöd eller behov av att stanna och hämta andan vid gång i egen takt på plan mark",
      choosen: false,
    }, {
      text: "Stannar efter ca 100 meters promenad eller några minuters gång på plan mark på grund av andnöd",
      choosen: false,
    }, {
      text: "Kan inte lämna huset på grund av andnöd eller andnöd vid på- och avklädning",
      choosen: false,
    }];
    return mMRC;
  }
}

interface InformationContent {
  title: string;
  information: string[];
  picture: string;
  searchParameters: string;
  benefits: string;
  benefitSearchIndexes: number[];
  choosen: boolean;
  dbName: string;
  positions: string[];
  activePos: string;
  variant: string;
}