import { Component, OnInit, ViewChild } from '@angular/core';
import { DataserviceService } from '../../services/dataservice.service';
import { MessageHandlerService } from '../../services/message-handler.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.css']
})
export class MessageComponent implements OnInit {
  name: string = '';
  messages: Message[];
  contacts: Contact[];
  currentContact: Contact;
  messageText: string;
  loadMoreMsg: boolean = true;
  plannedQuest: boolean = false;
  observe: any;

  @ViewChild('myImage', { static: false }) myImage: any;
  showImg: boolean = false;

  constructor(private dataservice: DataserviceService, private router: Router, private mhs: MessageHandlerService) { }

  ngOnInit() {
    this.setupContactList();
    this.repeatedCheckIfNewMessages();
  }

  private setupContactList() {
    this.mhs.getNewMessages().subscribe(
      (resp) => {
        if (resp && resp.status == 200) {
          let newMessages = resp.json();
          this.mhs.getAllContactsFromChatService().subscribe(
            (resp) => {
              if (resp && resp.status == 200) {
                let allContacts = resp.json();
                this.contacts = new Array();
                for (let entry of allContacts) {
                  this.contacts.push({
                    id: entry.contactId,
                    instance: entry.instance,
                    name: entry.name,
                    newMessage: false,
                    displayName: entry.name + " (" + entry.instance + ")",
                    isPatient: entry.isPatient,
                    isActive: entry.isActive,
                    hasPicture: entry.picture != null,
                    picture: 'data:image/png;base64,' + entry.picture
                  });
                }
                for (let msg of newMessages) {
                  for (let i = 0; i < this.contacts.length; i++) {
                    if (this.contacts[i].id == msg.from) {
                      this.contacts[i].newMessage = true;
                    }
                  }
                }

                this.contacts.sort(function (a, b) {
                  if (a.displayName < b.displayName) { return -1; }
                  if (a.displayName > b.displayName) { return 1; }
                  return 0;
                })

                let c = this.mhs.getCurrentContact();
                if (c) {
                  for (let contact of this.contacts) {
                    if (c.displayName == contact.displayName) {
                      this.displayMessageFrom(contact);
                    }
                  }
                  this.mhs.setCurrentContact(null);
                }
              }
            });
        }
      });
  }

  private repeatedCheckIfNewMessages() {
    this.observe = this.mhs.getNewMessagesPeriodically(5000).subscribe(
      (resp) => {
        let newMessages = resp.json();
        for (let msg of newMessages) {
          for (let i = 0; i < this.contacts.length; i++) {
            if (this.contacts[i].id == msg.from) {
              this.contacts[i].newMessage = true;
              if (this.currentContact.id == this.contacts[i].id) {
                this.displayMessageFrom(this.contacts[i]);
              }
            }
          }
        }
        this.contacts.sort(function (a, b) {
          if (a.displayName < b.displayName) { return -1; }
          if (a.displayName > b.displayName) { return 1; }
          return 0;
        })
      });
  }

  private timeString(date: number) {
    let time = new Date(date);
    let hour = time.getHours().toString().length == 1 ? '0' + time.getHours() : time.getHours();
    let minute = time.getMinutes().toString().length == 1 ? '0' + time.getMinutes() : time.getMinutes();
    return "(" + time.toLocaleDateString() + " " + hour + ":" + minute + ")";
  }

  onScroll(event: any) {
    if (event.target.scrollTop == 0 && this.loadMoreMsg) {
      let request = {
        name: this.currentContact.name,
        instance: this.currentContact.instance,
        timestamp: this.messages[0].timestamp
      }
      this.mhs.getMessagesFromUser(request).subscribe(
        (resp) => {
          if (resp && resp.status == 200) {
            if (!resp._body.includes("message")) {
              this.loadMoreMsg = false;
              return false;
            }
            let data = resp.json();
            for (let entry of data) {
              this.messages.push({
                text: entry.message,
                timestamp: entry.timestamp,
                fromMe: entry.from != this.currentContact.id,
                date: this.timeString(entry.timestamp)
              });
            }
            this.messages = this.messages.sort(function (x, y) {
              return x.timestamp - y.timestamp;
            });
          }
        });
    }
    return false;
  }

  displayMessageFrom(contact: Contact) {
    this.currentContact = contact;
    this.name = contact.displayName;
    this.loadMoreMsg = true;
    let request = {
      name: contact.name,
      instance: contact.instance,
    }
    this.mhs.getNewestMessagesFromUser(request).subscribe(
      (resp) => {
        if (resp && resp.status == 200) {
          let data = resp.json();
          this.messages = new Array();
          for (let entry of data) {
            this.messages.push({
              text: entry.message,
              timestamp: entry.timestamp,
              fromMe: entry.fromIsPatient ? false : true,
              date: this.timeString(entry.timestamp)
            });
          }
          this.messages = this.messages.sort(function (x, y) {
            return x.timestamp - y.timestamp;
          });

          if (contact.newMessage) {
            contact.newMessage = false;
            let request2 = {
              name: contact.name,
              instance: contact.instance,
            }
            this.mhs.changeUnreadStatus(request2).subscribe((resp) => {
              this.mhs.getNewMessages().subscribe(
                (resp) => {
                  if (resp && resp.status == 200) {
                    let newMessages = resp.json();
                  }
                });
            });
          }
        }
      });
    return false;
  }

  sendMessageToFriend() {
    if (this.currentContact && this.messageText != null && this.messageText != 'undefined') {
      let request = {
        name: this.currentContact.name,
        instance: this.currentContact.instance,
        message: this.messageText,
        timestamp: new Date().getTime()
      };
      this.mhs.sendMessagesToUser(request).subscribe(
        (resp) => {
          if (resp && resp.status == 200) {
            this.messages.push({
              fromMe: true,
              text: this.messageText,
              timestamp: request.timestamp,
              date: this.timeString(request.timestamp)
            });
            this.messageText = '';
          }
        });
    }
  }

  textChanged(event: any) {
    this.messageText = event.target.value;
    return false;
  }

  getTwoWeeksBack(fromDate: Date): number {
    if (fromDate.getDate() > 14) {
      return new Date(fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate() - 14).getTime();
    }
    let lastMonth = fromDate.getMonth() > 0 ? fromDate.getMonth() - 1 : 11;
    let year = lastMonth == 11 ? fromDate.getFullYear() - 1 : fromDate.getFullYear();
    let daysInPreviousMonth = this.dataservice.daysInMonth(lastMonth);
    return new Date(year, lastMonth, daysInPreviousMonth - (14 - fromDate.getDate())).getTime();
  }
}

interface Message {
  text: string;
  timestamp: number;
  date: string;
  fromMe: boolean;
}

interface Contact {
  id: number;
  instance: string;
  name: string;
  displayName: string;
  newMessage: boolean;
  isPatient: boolean;
  isActive: boolean;
  hasPicture: boolean;
  picture: string;
}