import { Injectable } from '@angular/core';
import { DataserviceService } from '../services/dataservice.service';

@Injectable()
export class CalendarService {
  calendarContent: any[];

  constructor(private dataservice : DataserviceService) { }

  setupCalender(date: Date, currentlyChoosen : Date) : any[] {
    let firstDay = new Date(Date.UTC(date.getFullYear(), date.getMonth(), 1)).getDay();
    this.calendarContent = new Array();

    // Fill days from previous month in gray.
    if (firstDay == 0) {
      let days = this.dataservice.daysInMonth(date.getMonth());
      for (let i = 5; i >= 0; i--) {
        this.calendarContent.push({
          day: days - i,
          thisMonth: false,
          markedDay: false,
          today: false
        });
      }
    }
    else if (firstDay > 1) {
      let days = this.dataservice.daysInMonth(date.getMonth());
      for (let i = firstDay - 2; i >= 0; i--) {
        this.calendarContent.push({
          day: days - i,
          thisMonth: false,
          markedDay: false,
          today: false,
        });
      }
    }


    // Add all days on this month
    let today = new Date();
    let days = this.dataservice.daysInMonth(date.getMonth() + 1);
    for (let j = 1; j <= days; j++) {
      this.calendarContent.push({
        day: j,
        thisMonth: true,
        today: (j == today.getDate() && date.getMonth() == today.getMonth()),
        markedDay: (j == currentlyChoosen.getDate() && date.getMonth() == currentlyChoosen.getMonth()),
      });
    }

    // Sum up the weeks
    let weeks = new Array();
    for (let k = 0; k < this.calendarContent.length; k += 7) {
      weeks.push({
        day1: this.calendarContent[k] || null,
        day2: this.calendarContent[k + 1] || null,
        day3: this.calendarContent[k + 2] || null,
        day4: this.calendarContent[k + 3] || null,
        day5: this.calendarContent[k + 4] || null,
        day6: this.calendarContent[k + 5] || null,
        day7: this.calendarContent[k + 6] || null
      });
    }
    return weeks;
  }
}
