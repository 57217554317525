import { Component, OnInit, Inject, Injectable, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { DataserviceService } from '../../services/dataservice.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { MessageHandlerService } from '../../services/message-handler.service';
import { GameInformationService } from '../../services/game-information.service';
import { Observable } from 'rxjs/Rx';
import {
  NgbDatepicker,
  NgbInputDatepicker,
  NgbDateStruct,
  NgbCalendar,
  NgbDate,
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbDatepickerI18n
} from '@ng-bootstrap/ng-bootstrap';
declare let d3: any;

const now = new Date();
const equals = (one: NgbDateStruct, two: NgbDateStruct) =>
  one && two && two.year === one.year && two.month === one.month && two.day === one.day;

const before = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two ? false : one.year === two.year ? one.month === two.month ? one.day === two.day
    ? false : one.day < two.day : one.month < two.month : one.year < two.year;

const after = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two ? false : one.year === two.year ? one.month === two.month ? one.day === two.day
    ? false : one.day > two.day : one.month > two.month : one.year > two.year;


@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.css']
})
export class HomePageComponent implements OnInit {
  newMessage: boolean = false;
  patients: any[];
  mObs;

  constructor(private dataservice: DataserviceService, private router: Router, private dialog: MatDialog,
    public snackBar: MatSnackBar, private mhs: MessageHandlerService) { }

  ngOnInit() {
    this.setupPatientList();
  }

  /*setupPatientList() {
    this.dataservice.getPatientList().subscribe(
      (resp) => {
        if (resp && resp.status != 200) {
          this.router.navigate(['']);
          return;
        }
        else {
          let response = resp.json();
          this.patients = new Array();
          for (let patient of response) {
            this.dataservice.getPatientInformation(patient.id).subscribe(
              (resp) => {
                if (resp.status == 200) {
                  let dateInfo = resp.json();
                  this.patients.push({
                    id: patient.id,
                    name: patient.username,
                    nextTime: dateInfo.nextDate,
                    untilDate: dateInfo.plannedUntil,
                    qrURL: patient.qrUrl
                  });
                }
              }
            );
          }
        }
      });
  }*/

  setupPatientList() {
    this.dataservice.getPatientList().subscribe(
      (resp) => {
        if (resp && resp.status != 200) {
          this.router.navigate(['']);
          return;
        }
        else {
          let response = resp.json();
          this.patients = new Array();
          let now = Date.now();
          let oneWeekBack = this.oneWeekBack();
          for (let patient of response) {
            let body = {
              from: oneWeekBack,
              until: now,
              id: patient.id,
              variant: 'ALL'
            };
            this.dataservice.getFullInformation(body).subscribe(
              (resp) => {
                if (resp.status == 200) {
                  let fi = resp.json();
                  this.dataservice.getExtremePoxValuesBetweenDates(body).subscribe(
                    (resp) => {
                      if (resp.status == 200) {
                        let poxData = resp.json();
                        this.dataservice.getStepsDataBetweenDates(body).subscribe(
                          (resp) => {
                            if (resp.status == 200) {
                              let steps = resp.json();
                              this.dataservice.getActiveMinutesDataBetweenDates(body).subscribe(
                                (resp) => {
                                  if (resp.status == 200) {
                                    this.setupRowOfInfo(patient.id, patient.username, patient.qrUrl, fi, poxData, steps, resp.json(), patient.currentlyActive, patient.parameters.parameters);
                                  } else {
                                    this.setupRowOfInfo(patient.id, patient.username, patient.qrUrl, fi, poxData, steps, null, patient.currentlyActive, patient.parameters.parameters);
                                  }
                                }
                              )
                            } else {
                              this.setupRowOfInfo(patient.id, patient.username, patient.qrUrl, fi, poxData, null, null, patient.currentlyActive, patient.parameters.parameters);
                            }
                          }
                        )
                      } else {
                        this.setupRowOfInfo(patient.id, patient.username, patient.qrUrl, fi, null, null, null, patient.currentlyActive, patient.parameters.parameters);
                      }
                    }
                  )
                  
                }
              }
            );
            //TODO Get activity tracker info here from seperate service
          }
        }
      });
  }

  setupRowOfInfo(id: number, name: string, url: string, diaryData: any, poxData: any, stepsData: any, activeMinutesData: any, active: boolean, parameters: any) {
    let cat = new Array();
    let mrc = new Array();
    let temp = new Array();
    let pulse = new Array();
    let weight = new Array();
    let sat = new Array();
    let activity = 0;
    let complete = 0;
    let steps = new Array();
    let activeMinutes = new Array();

    // Special check. Serialising an empty list get interpreted as a list of length 1 with first element 0...
    if (stepsData != null && ((stepsData.length == 1 && stepsData[0] == 0) == false)) {
      for(let entry of stepsData) {
        steps.push(entry.value);
      }
    }

    if (activeMinutesData != null && ((activeMinutesData.length == 1 && activeMinutesData[0] == 0) == false)) {

      for(let entry of activeMinutesData) {
        activeMinutes.push(entry.value);
      }
      
    }

    for (let entry of diaryData) {
      if (entry.diary != null) {
        if (entry.diary.temp != 0) { temp.push(entry.diary.temp); }
        if (entry.diary.pulse != 0) { pulse.push(entry.diary.pulse); }
        if (entry.diary.weight != 0) { weight.push(entry.diary.weight); }
        if (entry.diary.saturation != 0) { sat.push(entry.diary.saturation); }
      }
      else {
        if (entry.variant == 'EXERCISE') {
          ++complete;
        }
        if (entry.activity != null) {
          if (entry.variant == 'EXERCISE' && entry.activity.length > 0) {
            ++activity;
          }
          for (let res of entry.activity) {
            if (entry.type == 'mMRCAssessment') {
              mrc.push(res.score);
            }
            else if (entry.type == 'CATAssessment') {
              cat.push(res.score);
            }
          }
        }
      }
    }

    for (let entry of poxData) {
      if (entry.pulse != 0) { pulse.push(entry.pulse); }
      if (entry.saturation != 0) { sat.push(entry.saturation); }
    }

    this.patients.push({
      id: id,
      name: name,
      mrc: {
        text: mrc.length > 0 ? (mrc.reduce((a, b) => a + b, 0) / mrc.length).toFixed(1) : "--",
        color: mrc.length > 0 ? '#222' : 'rgba(0,0,0,0.0)',
        indication: this.getRotationAngle(mrc)
      },
      cat: {
        text: cat.length > 0 ? (cat.reduce((a, b) => a + b, 0) / cat.length).toFixed(0) : "--",
        color: cat.length > 0 ? '#222' : 'rgba(0,0,0,0.0)',
        indication: this.getRotationAngle(cat)
      },
      weight: {
        text: weight.length > 0 ? (weight.reduce((a, b) => a + b, 0) / weight.length).toFixed(1) : "--",
        color: this.getIndicationColor(parameters, weight.length > 0 ? (weight.reduce((a, b) => a + b, 0) / weight.length) : -1, 'WEIGHT'),
        indication: this.getRotationAngle(weight)
      },
      pulse: {
        text: pulse.length > 0 ? (pulse.reduce((a, b) => a + b, 0) / pulse.length).toFixed(0) : "--",
        color: this.getIndicationColor(parameters, pulse.length > 0 ? (pulse.reduce((a, b) => a + b, 0) / pulse.length) : -1, 'PULSE'),
        indication: this.getRotationAngle(pulse)
      },
      temp: {
        text: temp.length > 0 ? (temp.reduce((a, b) => a + b, 0) / temp.length).toFixed(1) : "--",
        color: this.getIndicationColor(parameters, temp.length > 0 ? (temp.reduce((a, b) => a + b, 0) / temp.length) : -1, 'TEMPERATURE'),
        indication: this.getRotationAngle(temp)
      },
      sat: {
        text: sat.length > 0 ? (sat.reduce((a, b) => a + b, 0) / sat.length).toFixed(0) : "--",
        color: this.getIndicationColor(parameters, sat.length > 0 ? (sat.reduce((a, b) => a + b, 0) / sat.length) : -1, 'SATURATION'),
        indication: this.getRotationAngle(sat)
      },
      steps: {
        text: steps.length > 0 ? (steps.reduce((a, b) => a + b, 0) / steps.length).toFixed(0) : "--",
        color: this.getIndicationColor(parameters, steps.length > 0 ? (steps.reduce((a, b) => a + b, 0) / steps.length) : -1, 'STEPS'),
        indication: this.getRotationAngle(steps)
      },
      activeMinutes: {
        text: activeMinutes.length > 0 ? (activeMinutes.reduce((a, b) => a + b, 0) / activeMinutes.length).toFixed(0) : "--",
        color: this.getIndicationColor(parameters, activeMinutes.length > 0 ? (activeMinutes.reduce((a, b) => a + b, 0) / activeMinutes.length) : -1, 'ACTIVE_MINUTES'),
        indication: this.getRotationAngle(activeMinutes)
      },
      qrURL: url,
      currentlyActive: active,
      activity: activity,
      completeNumber: complete,
      choosen: name == this.dataservice.patient,
      parameters: parameters
    });
    this.patients = this.patients.sort(function (a, b) {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    });
  }

  choosePatient(patient: any) {
    let body = {
      username: patient.name
    }
    this.dataservice.selectActivePatient(body).subscribe(
      (resp) => {
        if (resp.status == 200) {
          this.dataservice.patientObject = patient;
          this.dataservice.patient = patient.name;
          this.dataservice.url = patient.qrURL;
          this.router.navigate(["overview"]);
        }
      });
  }

  displayGraph(title: string, version: number, user: string, id: number) {
    for (let pat of this.patients) {
      if (pat.id == id) {
        if (version == 6) {
          let dialogRef = this.dialog.open(PopupGraphDialog, {
            width: 'auto',
            data: {
              id: id, title: title, username: user, from: this.oneWeekBack(), until: Date.now(), version: version, completeNumber: pat.completeNumber, number: pat.activity
            }
          });
        } else {
          let dialogRef = this.dialog.open(PopupGraphDialog, {
            width: 'auto',
            data: {
              id: id, title: title, username: user, from: this.oneWeekBack(), until: Date.now(), version: version, parameterSettings: pat.parameters
            }
          });
        }
      }
    }
  }

  createNewPatient() {
    let dialogRef = this.dialog.open(CreatePatientDialog, {
      data: {
        checkpoint: false
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.setupPatientList();
    });
    return false;
  }

  checkpoint(patient: any) {
    let dialogRef = this.dialog.open(CreatePatientDialog, {
      width: 'auto',
      data: {
        checkpoint: true, name: patient.name
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        this.deletePatient(patient);
      }
    });
    return false;
  }

  deletePatient(patient: any) {
    this.dataservice.patient = "Ingen vald";
    this.dataservice.patientObject = null;
    let body = {
      id: patient.id,
      username: patient.name,
      type: 1
    }
    this.dataservice.deletePatientUser(body).subscribe(
      (resp) => {
        if (resp.status == 200) {
          let user = {
            name: patient.name,
            instance: this.dataservice.care_instance
          }
          this.mhs.deleteUser(user).subscribe(
            (resp) => {
              if (resp.status == 200) {
                this.snackBar.open('Användare ' + patient.name + ' raderad!', 'Raderad', { duration: 4500 });
                this.setupPatientList();
              }
            });
        }
      });
  }

  private oneWeekBack(): number {
    let now = new Date();
    if (now.getDate() > 7) {
      return new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7, 0, 0, 0).getTime();
    }
    let month = now.getMonth() == 0 ? 11 : now.getMonth() - 1;
    let year = month == 11 ? now.getFullYear() - 1 : now.getFullYear();
    return new Date(year, month, (this.dataservice.daysInMonth(month) + now.getDate()) - 7, 0, 0, 0).getTime();
  }

  private getIndicationColor(parameters: any, median: number, version: string): string {
    if (parameters == null) {
      return '#222';
    }
    else if (median < 0) {
      return 'rgba(0,0,0,0.0)';
    }
    var min = 0; var max = 0;
    for (let settings of parameters) {
      if (settings.key == version) {
        min = settings.low;
        max = settings.high;
      }
    }
    if (min == 0 || max == 0) {
      return '#222';
    }
    else { // #e9c43c
      var p = (median - min) / (max - min);
      return median <= min ? '#bb0f0f' : median >= max ? '#bb0f0f' : p < 0.2 ? '#e9c43c' : p > 0.8 ? '#e9c43c' : '#4CAF50';
    }
  }

  private getRotationAngle(values: any): number {
    if (values.length < 1) {
      return 0;
    }
    var ind = 0;
    for (let v = 1; v < values.length; v++) {
      if (values[v] > values[v - 1]) {
        ++ind;
      }
      else if (values[v] < values[v - 1]) {
        --ind;
      }
    }
    if (ind >= 5) {
      return -90;
    }
    else if (ind <= -5) {
      return 90;
    }
    var angle = Math.abs(90.0 * (ind / 7.5));
    return ind < 0 ? angle : -1 * angle;
  }
}

@Component({
  selector: 'create-patient-dialog',
  templateUrl: 'create-patient-dialog.html',
  styleUrls: ['./home-page.component.css']
})
export class CreatePatientDialog {
  warning: string;
  showWarning: boolean;
  information: string;
  showInformation: boolean;

  measurement: string[] = ["Vikt", "Temperatur", "Puls", "Saturation", "Alla"];
  measure: boolean[] = new Array(5).fill(false);

  constructor(
    public dialogRef: MatDialogRef<CreatePatientDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dataservice: DataserviceService, private router: Router,
    private messageservice: MessageHandlerService) { }

  ngOnInit() {
    this.data.low = new Array(5).fill('');
    this.data.high = new Array(5).fill('');
  }

  onNoClick(): void {
    this.measure = new Array(4).fill(false);
    this.dialogRef.close();
  }

  changeMeasureValue(nr: number) {
    if (nr == this.measurement.length - 1) {
      this.measure = new Array(5).fill(!this.measure[nr]);
      this.data.low = new Array(5).fill('');
      this.data.high = new Array(5).fill('');
    }
    else {
      this.measure[nr] = !this.measure[nr];
      if (!this.measure[nr]) {
        this.data.low[nr] = '';
        this.data.high[nr] = '';
      }
      this.measure[this.measure.length - 1] = false;
    }
    this.showWarning = false;
    this.showInformation = false;
    return false;
  }

  createPatient() {
    this.showWarning = false;
    this.showInformation = false;
    if (this.data.username == 'undefined' || this.data.username == null || this.data.username == '') {
      this.warning = 'Fyll i alla fälten markerade med *';
      this.showWarning = true;
      return false;
    }

    let param = new Array();
    if (this.measure[0]) {
      param.push({
        key: 'WEIGHT',
        low: +this.data.low[0].replace(',', '.'),
        high: +this.data.high[0].replace(',', '.')
      })
    }
    if (this.measure[1]) {
      param.push({
        key: 'TEMPERATURE',
        low: +this.data.low[1].replace(',', '.'),
        high: +this.data.high[1].replace(',', '.'),
      })
    }
    if (this.measure[2]) {
      param.push({
        key: 'PULSE',
        low: +this.data.low[2].replace(',', '.'),
        high: +this.data.high[2].replace(',', '.'),
      })
    }
    if (this.measure[3]) {
      param.push({
        key: 'SATURATION',
        low: +this.data.low[3].replace(',', '.'),
        high: +this.data.high[3].replace(',', '.'),
      })
    }
    for (let input of param) {
      if (input.low == 0 || input.high == 0) {
        this.warning = 'Fyll i lägre och högre gränsvärden för de ikryssade parametrarna';
        this.showWarning = true;
        break;
      }
      else if (input.low >= input.high) {
        this.warning = 'Det lägre gränsvärdet måste vara mindre än det högre gränsvärdet';
        this.showWarning = true;
        break;
      }
    }
    if (this.showWarning) { return false; }

    let body = {
      username: this.data.username,
      firstName: this.data.firstName,
      lastName: this.data.lastName,
      email: this.data.email,
      phone: this.data.phone,
      parameters: { parameters: param },
      serverName: this.dataservice.care_instance,
    }
    this.dataservice.createNewPatientUser(body).subscribe(
      (resp) => {
        if (resp.status == 200) {
          let user = {
            name: this.data.username,
            caregiver: this.dataservice.username,
            instance: this.dataservice.care_unit_name,
            password: resp._body,
            type: 'PATIENT'
          };
          this.messageservice.createUser(user).subscribe(
            (resp) => {
              if (resp.status == 200) {
                this.data.username = "";
                this.data.firstName = "";
                this.data.lastName = "";
                this.data.phone = "";
                this.data.email = "";
                this.measure = new Array(4).fill(false);
                this.dialogRef.close();
              }
            });
        }
        else {
          this.warning = 'Kunde inte skapa en användare med det namnet';
          this.showWarning = true;
        }
      }
    );
  }

  deleteCheck(deleteUser: boolean) {
    this.dialogRef.close(deleteUser);
  }
}

const I18N_VALUES = {
  'se': {
    weekdays: ['Må', 'Ti', 'On', 'To', 'Fr', 'Lö', 'Sö'],
    months: ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Juni', 'Juli', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  }
  // other languages you would support
};

// Define a service holding the language. You probably already have one if your app is i18ned. Or you could also
// use the Angular LOCALE_ID value
@Injectable()
export class I18n {
  language = 'se';
}

// Define custom service providing the months and weekdays translations
@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {

  constructor(private _i18n: I18n) {
    super();
  }

  getWeekdayShortName(weekday: number): string {
    return I18N_VALUES[this._i18n.language].weekdays[weekday - 1];
  }
  getMonthShortName(month: number): string {
    return I18N_VALUES[this._i18n.language].months[month - 1];
  }
  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }
}

@Component({
  selector: 'popup-graph-dialog',
  templateUrl: 'popup-graph-dialog.html',
  styleUrls: ['./home-page.component.css'],
  providers: [I18n, { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n }] // define custom NgbDatepickerI18n provider
})
export class PopupGraphDialog {
  warning: string;
  showWarning: boolean;
  information: string;
  total: number;
  completed: any;
  vas;

  dataAvailable: boolean = false;
  plotData: any;
  options: any;

  date: string;
  currentData: any[];
  questionair: any[];
  sum: number;
  extendedCAT: boolean = false;
  extendedMRC: boolean = false;
  my_obs;
  static index;
  calendarContent: any[];
  calenderWeeks1: any[];
  calenderWeeks2: any[];
  endDate: string;
  startMonth: string;
  endMonth: string;
  
  defaultDaySpan: number = 7;
  prevMonth: NgbDate;
  hoveredDate: NgbDateStruct;
  fromDate: NgbDate;
  untilDate: NgbDate;
  model: any;
  @ViewChild("d", { static: false }) input: NgbInputDatepicker;
  @ViewChild('myRangeInput', { static: false }) myRangeInput: ElementRef;

  newItem = {
    StartTime: null,
    EndTime: null
  };

  constructor(
    public dialogRef: MatDialogRef<PopupGraphDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dataservice: DataserviceService, private router: Router, private gi: GameInformationService,
    calendar2: NgbCalendar, private renderer: Renderer2, private _parserFormatter: NgbDateParserFormatter) {
    this.fromDate = calendar2.getPrev(calendar2.getToday(), 'd', this.defaultDaySpan);
    this.untilDate = calendar2.getToday();
    this.newItem.StartTime = this.fromDate;
    this.newItem.EndTime = this.untilDate;
  }

  ngOnInit() {
    let date = new Date();
    this.prevMonth = new NgbDate(date.getFullYear(), date.getMonth(), 1);
    if (this.data.version < 2) {
      this.information = 'I grafen nedan presenteras resultatet för utförda bedömningstester som ' + this.data.username + ' har utfört under den angivna perioden. Klicka på en av punkterna i grafen för att få mer utförlig information om testet.';
    }
    else if (this.data.version < 6) {
      this.information = 'I grafen nedan presenteras värden uppmätta för ' + this.data.username + ' under den angivna perioden.';
    }
    this.loadNewData();
  }

  isHovered = date =>
    this.fromDate && !this.untilDate && this.hoveredDate && after(date, this.fromDate) && before(date, this.hoveredDate)
  isInside = date => after(date, this.fromDate) && before(date, this.untilDate);
  isFrom = date => equals(date, this.fromDate);
  isTo = date => equals(date, this.untilDate);

  NgbDateToNgbDateStruct(ngbDate: NgbDate) {
    const date: NgbDateStruct = { year: ngbDate.year, month: ngbDate.month, day: ngbDate.day };
    return date;
  }

  NgbDateStructToNgbDate(ngbDateStruct: NgbDateStruct) {
    return new NgbDate(ngbDateStruct.year, ngbDateStruct.month, ngbDateStruct.day);
  }

  onDateSelectionGraph(date: NgbDateStruct) {
    if (!this.fromDate && !this.untilDate) {
      this.fromDate = this.NgbDateStructToNgbDate(date);
    } else if (this.fromDate && !this.untilDate && after(date, this.NgbDateToNgbDateStruct(this.fromDate))) {
      this.untilDate = this.NgbDateStructToNgbDate(date);
      this.input.close();
      this.loadNewData();
    } else {
      this.untilDate = null;
      this.fromDate = this.NgbDateStructToNgbDate(date);
    }
    if (this.fromDate) {
      this.prevMonth = this.fromDate;
    }
    this.PaintDateRangeText();
  }

  onDateSelectionActivityFrom(date: NgbDateStruct) {
    this.fromDate = this.NgbDateStructToNgbDate(date);
    this.loadNewData();
  }

  onDateSelectionActivityTo(date: NgbDateStruct) {
    this.untilDate = this.NgbDateStructToNgbDate(date);
    this.loadNewData();
  }

  PaintDateRangeText() {
    if (this.myRangeInput == null) return; // If we are not using the element atm, don't do anything
    let parsed = '';
    if (this.fromDate) {
      parsed += this._parserFormatter.format(this.fromDate);
    }
    if (this.untilDate) {
      parsed += ' - ' + this._parserFormatter.format(this.untilDate);
    }
    this.renderer.setProperty(this.myRangeInput.nativeElement, 'value', parsed);
  }

  NgbDateToJSDate(ngbDate: NgbDate) {
    let date = new Date();
    date.setDate(ngbDate.day);
    date.setMonth(ngbDate.month - 1);
    date.setFullYear(ngbDate.year);
    return date;
  }

  JSDateToNgbDate(date: Date) {
    if (date == null) return null;
    let ngbDate = new NgbDate(date.getFullYear(), date.getMonth() + 1, date.getDate());
    return ngbDate;
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || date.equals(this.untilDate) || this.isInside(date) || this.isHovered(date);
  }

  isToday(date: NgbDate) {
    var todayDate = new Date();
    if (date.day == todayDate.getDate()
      && date.month == (todayDate.getMonth() + 1)
      && date.year == todayDate.getFullYear()) {
      return true;
    }
    return false;
  }

  getJSFromDate() {
    return new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day, 0, 0, 0, 0);
  }

  getJSUntilDate() {
    return new Date(this.untilDate.year, this.untilDate.month - 1, this.untilDate.day, 23, 59, 59, 99);
  }

  loadNewData() {
    this.dataAvailable = false;
    this.getJSFromDate();
    let body = {
      from: this.getJSFromDate().getTime(),
      until: this.getJSUntilDate().getTime(),
      id: this.data.id,
      variant: this.data.version == 6 ? 'ALL' : 'ASSESSMENT'
    };
    this.currentData = new Array();
    if (this.data.version == 3 || this.data.version == 5) {
      this.dataservice.getExtremePoxValuesBetweenDates(body).subscribe( 
        (resp) => {
          if (resp.status == 200) {
            if (this.data.version == 3) {
              if (this.data.parameterSettings != null) {
                var min = 0; var max = 0;
                for (let settings of this.data.parameterSettings) {
                  if (settings.key == 'PULSE') {
                    min = settings.low;
                    max = settings.high;
                  }
                }
                this.measurementChart(resp.json(), ' bpm', 1, min, max);
              } else {
                this.measurementChart(resp.json(), ' bpm', 1, 0, 0);
              }
            }
            else if (this.data.version == 5) {
              if (this.data.parameterSettings != null) {
                var min = 0; var max = 0;
                for (let settings of this.data.parameterSettings) {
                  if (settings.key == 'SATURATION') {
                    min = settings.low;
                    max = settings.high;
                  }
                }
                this.measurementChart(resp.json(), ' %', 3, min, max);
              } else {
                this.measurementChart(resp.json(), ' %', 3, 0, 0);
              }
            }
            this.PaintDateRangeText(); // This must be placed here to allow for the element to be created
          }
        }
      )
    } else if (this.data.version == 7) {
      this.dataservice.getStepsDataBetweenDates(body).subscribe(
        (resp) => {
          if (resp.status == 200) {
            if (this.data.version == 7) {
              if (this.data.parameterSettings != null) {
                var min = 0; var max = 0;
                for (let settings of this.data.parameterSettings) {
                  if (settings.key == 'STEPS') {
                    min = settings.low;
                    max = settings.high;
                  }
                }
                this.measurementChart(resp.json(), ' steps', 7, min, max);
              } else {
                this.measurementChart(resp.json(), ' steps', 7, 0, 0);
              }
            }
            this.PaintDateRangeText(); // This must be placed here to allow for the element to be created
          }
        }
      )
    } else if (this.data.version == 8) {
      this.dataservice.getActiveMinutesDataBetweenDates(body).subscribe(
        (resp) => {
          if (resp.status == 200) {
            if (this.data.version == 8) {
              if (this.data.parameterSettings != null) {
                var min = 0; var max = 0;
                for (let settings of this.data.parameterSettings) {
                  if (settings.key == 'ACTIVE_MINUTES') {
                    min = settings.low;
                    max = settings.high;
                  }
                }
                this.measurementChart(resp.json(), ' minutes', 8, min, max);
              } else {
                this.measurementChart(resp.json(), ' minutes', 8, 0, 0);
              }
            }
            this.PaintDateRangeText(); // This must be placed here to allow for the element to be created
          }
        }
      )
    } else {
      this.dataservice.getFullInformation(body).subscribe(
        (resp) => {
          if (resp.status == 200) {
            switch (this.data.version) {
              case 0:
                this.assessmentChart(resp.json(), 'mMRC', 'mMRCAssessment', 0, 5);
                this.my_obs = Observable.interval(300).subscribe(x => {
                  this.extendedCheck();
                });
                break;
              case 1:
                this.assessmentChart(resp.json(), 'CAT', 'CATAssessment', 0, 40);
                this.my_obs = Observable.interval(300).subscribe(x => {
                  this.extendedCheck();
                });
                break;
              case 2:
                if (this.data.parameterSettings != null) {
                  var min = 0; var max = 0;
                  for (let settings of this.data.parameterSettings) {
                    if (settings.key == 'WEIGHT') {
                      min = settings.low;
                      max = settings.high;
                    }
                  }
                  this.measurementChart(resp.json(), ' kg', 0, min, max);
                } else {
                  this.measurementChart(resp.json(), ' kg', 0, 0, 0);
                }
                break;
              case 4:
                if (this.data.parameterSettings != null) {
                  var min = 0; var max = 0;
                  for (let settings of this.data.parameterSettings) {
                    if (settings.key == 'TEMPERATURE') {
                      min = settings.low;
                      max = settings.high;
                    }
                  }
                  this.measurementChart(resp.json(), ' \xB0C', 2, min, max);
                } else {
                  this.measurementChart(resp.json(), ' \xB0C', 2, 0, 0);
                }
                break;
              case 6:
                this.exerciseChart(resp.json());
                break;
            }
            this.PaintDateRangeText(); // This must be placed here to allow for the element to be created
          }
        }
      );
    }
  }

  private assessmentChart(json: any, lable: string, test: string, min: number, max: number) {
    var plotValues = new Array();
    let timezoneOffset = new Date().getTimezoneOffset();

    for (let entry of json) {
      if (entry.diary == null && entry.activity != null) {
        for (let res of entry.activity) {
          if (entry.type == test) {
            this.currentData.push(entry);
            let date = new Date(entry.timestamp);
            let xLabel = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()) + Date.UTC(1970, 0, 1, 0, timezoneOffset, 0, 0);
            plotValues.push({
              "label": xLabel,
              "value": res.score
            });
            this.dataAvailable = true;
          }
        }
      }
    }
    this.plotData = [{
      key: lable,
      values: plotValues
    }];
    this.setupChartOptions(min, max);
  }

  private measurementChart(json: any, lable: string, version: number, min: number, max: number) {
    var plotValues = new Array();
    let timezoneOffset = new Date().getTimezoneOffset();
    if (version == 1 || version == 3) {
      for (let entry of json) {
        let date = new Date(entry.measuredOn);
        let xLabel = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()) + Date.UTC(1970, 0, 1, 0, timezoneOffset, 0, 0);
        if (version == 1 && entry.pulse != -1 && entry.pulse != 0) {
          plotValues.push({
            "label": xLabel,
            "value": entry.pulse
          });
          this.dataAvailable = true;
        } else if (version == 3 && entry.saturation != -1 && entry.saturation != 0) {
          plotValues.push({
            "label": xLabel,
            "value": entry.saturation
          });
          this.dataAvailable = true;
        }
      }
    } if (version == 7 || version == 8) {
      for (let entry of json) {
        let date = new Date(entry.dateTime);
        let xLabel = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()) + Date.UTC(1970, 0, 1, 0, timezoneOffset, 0, 0);
        if (entry.value != -1 && entry.value != 0) {
          plotValues.push({
            "label": xLabel,
            "value": entry.value
          });
          this.dataAvailable = true;
        }
      }
    } else {
      for (let entry of json) {
        if (entry.diary != null) {
          let date = new Date(entry.timestamp);
          let xLabel = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()) + Date.UTC(1970, 0, 1, 0, timezoneOffset, 0, 0);
          switch (version) {
            case 0:
              if (entry.diary.weight != 0) {
                plotValues.push({
                  "label": xLabel,
                  "value": entry.diary.weight
                });
              }
              break;
            case 2:
              if (entry.diary.temp != 0) {
                plotValues.push({
                  "label": xLabel,
                  "value": entry.diary.temp
                });
              }
              break;
          }
          this.dataAvailable = true;
        }
      }
    }
    
    if (min > 0 && max > 0) {
      var minValues = new Array();
      var maxValues = new Array();
      minValues.push({
        'label': this.getJSFromDate().getTime(),
        'value': min
      });
      minValues.push({
        'label': this.getJSUntilDate().getTime(),
        'value': min
      });
      maxValues.push({
        'label': this.getJSFromDate().getTime(),
        'value': max
      });
      maxValues.push({
        'label': this.getJSUntilDate().getTime(),
        'value': max
      });
      this.plotData = [{
        key: lable,
        values: plotValues
      }, {
        key: 'Övre gräns',
        values: maxValues,
        color: '#ff7f0e',
        strokeWidth: 4,
        classed: 'dashed',
      }, {
        key: 'Nedre gräns',
        values: minValues,
        color: '#ff7f0e',
        strokeWidth: 4,
        classed: 'dashed',

      }];
    }
    else {
      this.plotData = [{
        key: lable,
        values: plotValues
      }];
    }
    this.setupChartOptionsUndefined();
  }

  private exerciseChart(json: any) {
    this.vas = new Array();
    var map = new Map();
    var total = 0;
    var comp = 0;
    let timezoneOffset = new Date().getTimezoneOffset();

    json.sort((n1,n2) => {
      return n1.timestamp - n2.timestamp;
    });

    for (let entry of json) {
      if (entry.variant == 'EXERCISE') {
        ++total;
        let date = new Date(entry.timestamp);
        let xLabel = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()) + Date.UTC(1970, 0, 1, 0, timezoneOffset, 0, 0);
        let completed = entry.activity != null && entry.activity.length > 0;
        let fullycompleted = completed && entry.activity.length == entry.sets;
        if (map.has(xLabel)) {
          ++map.get(xLabel)[fullycompleted ? 0 : completed ? 1 : 2];
        }
        else {
          let data = [fullycompleted ? 1 : 0, !fullycompleted && completed ? 1 : 0, !completed ? 1 : 0];
          map.set(xLabel, data);
        }
        if (completed) {
          ++comp;
          for (let res of entry.activity) {
            if (res.vasBreath != 0 || res.vasEffort != 0) {
              var time = new Date(res.activityEnded);
              this.vas.push({
                date: time.getFullYear() + "-" + time.getMonth() + "-" + time.getDate() + " kl " + time.getHours() + ":" + (time.getMinutes() < 10 ? '0' + time.getMinutes() : time.getMinutes()),
                activity: this.gi.gameName(entry.type),
                as: res.vasBreath == 0 ? '--' : res.vasBreath,
                af: res.vasEffort == 0 ? '--' : res.vasEffort
              });
            }
          }
        }
      }
      else if (entry.activity != null && entry.type != null && (entry.type == 'VASBreathAssessment' || entry.type == 'VASEffortAssessment')) {
        for (let res of entry.activity) {
          var time = new Date(res.activityEnded == 0 ? entry.timestamp : res.activityEnded);
          this.vas.push({
            date: time.getFullYear() + "-" + time.getMonth() + "-" + time.getDate() + " kl " + time.getHours() + ":" + (time.getMinutes() < 10 ? '0' + time.getMinutes() : time.getMinutes()),
            activity: 'Övningstillfällets slut',
            as: entry.type == 'VASEffortAssessment' ? res.score : '--',
            af: entry.type == 'VASBreathAssessment' ? res.score : '--'
          });
        }
      }
    }
    this.total = total;
    this.completed = ((comp / total) * 100).toFixed(0);
    if (total > 0) {
      this.dataAvailable = true;
      var value1 = new Array();
      var value2 = new Array();
      var value3 = new Array();
      map.forEach(function (item, key, mapObj) {
        value1.push({
          "label": key,
          "value": item[0]
        });
        value2.push({
          "label": key,
          "value": item[1]
        });
        value3.push({
          "label": key,
          "value": item[2]
        });
      });
      this.plotData = [{
        key: "Ej utfört",
        color: 'red',
        values: value3
      }, {
        key: "Delvis utfört",
        color: 'yellow',
        values: value2
      }, {
        key: "Utfört helt",
        color: 'green',
        values: value1
      }];
      this.setupMultiBarChart();
    }
  }

  private setupChartOptionsUndefined() {
    this.options = {
      chart: {
        type: 'lineChart',
        showLegend: false,
        height: 350,
        x: function (d) { return d.label; },
        y: function (d) { return d.value; },
        useInteractiveGuideline: true,
        clipVoronoi: false,
        xScale: d3.time.scale(),
        xAxis: {
          tickFormat: function (d) {
            return d3.time.format('%y-%m-%d')(new Date(d));
          },
          rotateLabels: -45,
          showMinMax: false
        },
        yAxis: {
          tickFormat: function (d) {
            return d.toFixed(1);
          }
        },
        interactiveLayer: {
          tooltip: {
            contentGenerator: function (record) {
              var date = d3.time.format('%y-%m-%d')(new Date(record.series[0].data.label));
              var str = '<p>' + date + '<br />';
              str = str + '<span style="background-color:' + record.series[0].color + ';display: inline-block; height:12px; width:12px;vertical-align: middle;"></span>&nbsp;' + record.series[0].value + record.series[0].key + '<br />';
              return str + '</p>';
            },
            headerEnabled: true,
          }
        }
      }
    }
  }

  private setupChartOptions(min: number, max: number) {
    this.options = {
      chart: {
        type: 'lineChart',
        height: 350,
        x: function (d) { return d.label; },
        y: function (d) { return d.value; },
        useInteractiveGuideline: true,
        clipVoronoi: false,
        xScale: d3.time.scale(),
        xAxis: {
          tickFormat: function (d) {
            return d3.time.format('%y-%m-%d')(new Date(d));
          },
          rotateLabels: -45,
          showMinMax: false
        },
        yAxis: {
          tickFormat: function (d) {
            return d.toFixed(0);
          }
        },
        yDomain: [min, max],
        lines: {
          dispatch: {
            elementClick: function (e) { PopupGraphDialog.index = e[0].pointIndex; }
          }
        },
      }
    }
  }

  private setupMultiBarChart() {
    this.options = {
      chart: {
        type: 'multiBarChart',
        height: 350,
        x: function (d) { return d.label; },
        y: function (d) { return d.value; },
        useInteractiveGuideline: true,
        transitionDuration: 500,
        clipEdge: true,
        stacked: true,
        xAxis: {
          tickFormat: function (d) {
            return d3.time.format('%y-%m-%d')(new Date(d));
          },
          rotateLabels: -45,
          showMinMax: false
        },
        yAxis: {
          tickFormat: function (d) {
            return d.toFixed(0);
          }
        },
        interactiveLayer: {
          tooltip: {
            contentGenerator: function (record) {
              var date = d3.time.format('%y-%m-%d')(new Date(record.series[0].data.label));
              var str = '<p>' + date + '<br />';
              for (let i = record.series.length - 1; i >= 0; i--) {
                str = str + '<span style="background-color:' + record.series[i].color + ';display: inline-block; height:12px; width:12px;vertical-align: middle;"></span>&nbsp;' + record.series[i].value + '<br />';
              }
              return str + '</p>';
            },
            headerEnabled: true,
          }
        }
      }
    }
  }

  public extendedCheck() {
    if (PopupGraphDialog.index != 'undefined' && PopupGraphDialog.index != null) {
      var entryClicked = this.currentData[PopupGraphDialog.index];
      PopupGraphDialog.index = null;
      var time = new Date(entryClicked.timestamp);
      this.date = time.getFullYear() + "-" + (time.getMonth() + 1) + "-" + time.getDate();

      if (entryClicked.type == 'CATAssessment') {
        this.questionair = this.gi.getCATQuestionair();
        if (entryClicked.activity && entryClicked.activity[0]) {
          for (let i = 0; i < entryClicked.activity[0].assessmentAnswers.length; i++) {
            this.questionair[i].currentValue = entryClicked.activity[0].assessmentAnswers[i];
          }
          this.sum = entryClicked.activity[0].score;
        }
        this.extendedCAT = true;
      }
      else if (entryClicked.type == 'mMRCAssessment') {
        this.questionair = this.gi.getmMRCQuestionair();
        this.sum = entryClicked.activity[0] != null ? entryClicked.activity[0].score : 0;
        this.extendedMRC = true;
      }
    }
  }
}