import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DataserviceService } from '../../services/dataservice.service';
import { GameInformationService } from '../../services/game-information.service';
import { MessageHandlerService } from '../../services/message-handler.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import {
  NgbDatepicker,
  NgbInputDatepicker,
  NgbDateStruct,
  NgbCalendar,
  NgbDate,
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbDatepickerI18n
} from '@ng-bootstrap/ng-bootstrap';
import {animate, state, style, transition, trigger} from '@angular/animations';
import Utils from '../utils';

const now = new Date();
const equals = (one: NgbDateStruct, two: NgbDateStruct) =>
  one && two && two.year === one.year && two.month === one.month && two.day === one.day;

const before = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two ? false : one.year === two.year ? one.month === two.month ? one.day === two.day
    ? false : one.day < two.day : one.month < two.month : one.year < two.year;

const after = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two ? false : one.year === two.year ? one.month === two.month ? one.day === two.day
    ? false : one.day > two.day : one.month > two.month : one.year > two.year;

@Component({
  selector: 'app-overview-activity',
  templateUrl: './overview-activity.component.html',
  styleUrls: ['./overview-activity.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0', display: 'none'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class OverviewActivityComponent implements OnInit {
  newMessage: boolean = false;
  showWarning: boolean = false;
  ascendingDate: boolean = true;
  ascendingName: boolean = false;

  allActivities: any[];
  activities: any[];
  mObs;
  p: number = 1;

  fromDate: NgbDate;
  untilDate: NgbDate;
  defaultDaySpanBefore: number = 7;
  defaultDaySpanAfter: number = 7;
  prevMonth: NgbDate;
  hoveredDate: NgbDateStruct;
  model: any;
  @ViewChild("d", { static: false }) input: NgbInputDatepicker;

  newItem = {
    StartTime: null,
    EndTime: null
  };

  currentState: string = "ALLA AKTIVITETER"
  stateTypes: string[] = ["ALLA AKTIVITETER", "UTFÖRDA AKTIVITETER", "PLANERADE AKTIVITETER", "BEDÖMNINGSTESTER"];

  dataSource: ExpandedElement[];
  columnsToDisplay = [ 
    {title: "Datum",
    attribute: 'date'}, 
    {title: "Antal övningar",
    attribute: 'numberOfExercises'}, 
   ];
  expandedElement: ExpandedElement;
  selectedRow: any = {date : ""};
  
  constructor(private dataservice: DataserviceService, private router: Router, private gi: GameInformationService,
    private mhs: MessageHandlerService, private dialog: MatDialog, public snackBar: MatSnackBar, calendar: NgbCalendar) { 
      this.fromDate = calendar.getPrev(calendar.getToday(), 'd', this.defaultDaySpanBefore);
      this.untilDate = calendar.getNext(calendar.getToday(), 'd', this.defaultDaySpanAfter);

      this.newItem.StartTime = this.fromDate;
      this.newItem.EndTime = this.untilDate;
    }

  ngOnInit() {
    this.showWarning = this.dataservice.patient == "Ingen vald";    
    if (!this.showWarning) {
      let now = new Date();
      this.setupCompleteTable();
    }
  }

  rowSelected(element: any) {
    this.selectedRow = element;
  }

  getColumnsToDisplayAttributes() {
    var coulmnAttributes:String[] = new Array()  
    this.columnsToDisplay.forEach(element => {
      coulmnAttributes.push(element.attribute)
    });
    return coulmnAttributes;
  }

  isHovered = date =>
    this.fromDate && !this.untilDate && this.hoveredDate && after(date, this.fromDate) && before(date, this.hoveredDate)
  isInside = date => after(date, this.fromDate) && before(date, this.untilDate);
  isFrom = date => equals(date, this.fromDate);
  isTo = date => equals(date, this.untilDate);

  NgbDateToNgbDateStruct(ngbDate: NgbDate) {
    const date: NgbDateStruct = { year: ngbDate.year, month: ngbDate.month, day: ngbDate.day };
    return date;
  }

  NgbDateStructToNgbDate(ngbDateStruct: NgbDateStruct) {
    return new NgbDate(ngbDateStruct.year, ngbDateStruct.month, ngbDateStruct.day);
  }

  onDateSelectionActivityFrom(date: NgbDateStruct) {
    this.fromDate = this.NgbDateStructToNgbDate(date);
    this.setupCompleteTable();
  }

  onDateSelectionActivityTo(date: NgbDateStruct) {
    this.untilDate = this.NgbDateStructToNgbDate(date);
    this.setupCompleteTable();
  }

  NgbDateToJSDate(ngbDate: NgbDate) {
    let date = new Date();
    date.setDate(ngbDate.day);
    date.setMonth(ngbDate.month - 1);
    date.setFullYear(ngbDate.year);
    return date;
  }

  JSDateToNgbDate(date: Date) {
    if (date == null) return null;
    let ngbDate = new NgbDate(date.getFullYear(), date.getMonth() + 1, date.getDate());
    return ngbDate;
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || date.equals(this.untilDate) || this.isInside(date) || this.isHovered(date);
  }

  isToday(date: NgbDate) {
    var todayDate = new Date();
    if (date.day == todayDate.getDate()
      && date.month == (todayDate.getMonth() + 1)
      && date.year == todayDate.getFullYear()) {
      return true;
    }
    return false;
  }

  getJSFromDate() {
    return new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day, 0, 0, 0, 0);
  }

  getJSUntilDate() {
    return new Date(this.untilDate.year, this.untilDate.month - 1, this.untilDate.day, 23, 59, 59, 99);
  }

  sortTableDates() {
    this.ascendingDate = !this.ascendingDate;
    if (this.ascendingDate) {
      this.activities.sort(function (a, b) {
        return a.scheduledFor - b.scheduledFor;
      });
    }
    else {
      this.activities.sort(function (a, b) {
        return b.scheduledFor - a.scheduledFor;
      });
    }
  }

  sortTableNames() {
    this.ascendingName = !this.ascendingName;
    if (this.ascendingName) {
      this.activities.sort(function (a, b) {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      });
    }
    else {
      this.activities.sort(function (a, b) {
        if (a.name > b.name) return -1;
        if (a.name < b.name) return 1;
        return 0;
      });
    }
  }

  sortTablePositions() {
    this.ascendingName = !this.ascendingName;
    if (this.ascendingName) {
      this.activities.sort(function (a, b) {
        if (a.position < b.position) return -1;
        if (a.position > b.position) return 1;
        return 0;
      });
    }
    else {
      this.activities.sort(function (a, b) {
        if (a.position > b.position) return -1;
        if (a.position < b.position) return 1;
        return 0;
      });
    }
  }

  filterActivityTypes(variant: number){
    var tmp = new Array();
    switch(variant){
      case 0:
        tmp = this.allActivities;
      break;
      case 1:
        for(let entry of this.allActivities){
          if (entry.completed > 0){
            tmp.push(entry);
          }
        }
      break;
      case 2:
        for(let entry of this.allActivities){
          if (entry.completed == 0 && !entry.dateHasPassed){
            tmp.push(entry);
          }
        }
      break;
      case 3:
        for(let entry of this.allActivities){
          if (entry.variant == 'ASSESSMENT'){
            tmp.push(entry);
          }
        }
      break;
    }
    this.activities = tmp;
    this.dataSource = this.getActivitiesAsExpandableElements();
  }

  setupCompleteTable() {
    let body = {
      from: this.getJSFromDate().getTime(),
      until: this.getJSUntilDate().getTime(),
      variant: "ALL"
    };
    this.dataservice.getAllActivitiesBetweenDates(body).subscribe(
      (resp) => {
        if (resp.status == 200) {
          this.activities = new Array();
          let now = new Date();
          let startOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0);
          let startOfYesterDay = new Date(now.getFullYear(), now.getMonth(), now.getDate()-Utils.GetNmbrOfDaysExerciseStillDoable(), 0, 0, 0);
          let data = resp.json();
          if (this.ascendingDate) {
            data.sort(function (a, b) {
              return a.scheduledFor - b.scheduledFor;
            });
          }
          else {
            data.sort(function (a, b) {
              return b.scheduledFor - a.scheduledFor;
            });
          }
          let prevDay : Date;
          let isDateColored : boolean = false;
          for (let act of data) {
            let tmp = new Date(act.scheduledFor);
            if (prevDay != null) {
              if (this.isDateDifferent(prevDay, tmp)) {
                isDateColored = !isDateColored;
              }
            }
            prevDay = tmp;
            let day = tmp.getFullYear() + "-" + (tmp.getMonth() + 1) + "-" + tmp.getDate();
            let hasComment = (act.comment != null && act.comment != 'undefined' && act.comment != '') ? true : false;

            let partiallyDone = act.result.length>0 && act.result.length<act.settings.sets;

            if (act.type == "DriveForestExercise") {
              act.result.forEach(element => {
                if(element.totalSecondsSpentPlaying < (act.settings.repetitions * 60) - act.settings.repetitions) { // -repetitions because it is somtimes off with a few sec but should still be treated as fin.
                  partiallyDone = true;
                }
              });
            }

            this.activities.push({
              date: day,
              scheduledFor: act.scheduledFor,
              name: this.gi.gameName(act.type),
              dbName: act.type,
              level: act.settings.level == 0 ? "--" : act.settings.level,
              sets: act.settings.sets,
              repetitions: act.settings.repetitions == 0 ? "--" : act.settings.repetitions,
              position: this.gi.positionType(act.settings.positionType),
              variant: act.variant,
              completed: act.result.length,
              results: act.result,
              dateHasSoonPassed: act.scheduledFor <= startOfDay && !(act.scheduledFor <= startOfYesterDay),
              dateHasPassed: act.scheduledFor <= startOfYesterDay,
              id: act.id,
              comment: act.comment,
              settings: act.settings,
              hasComment: hasComment,
              selfMade: act.plannedBy == "NONE",
              isDateColored: true,
              partiallyFinished: partiallyDone,
              levelEdited: false,
              setsEdited: false,
              repetitionsEdited: false,
              picture: this.gi.getPictureForGameName(this.gi.gameName(act.type))
            });

            this.allActivities = this.activities;
            this.filterActivityTypes(this.stateTypes.indexOf(this.currentState));
          }
          this.dataSource = this.getActivitiesAsExpandableElements();

          this.dataservice.getAllActivitiesThatInitiatedEditBetweenDates(body).subscribe(
            (resp) => {
              if (resp.status == 200) {

                let data = resp.json();
                for (let act of this.allActivities) {
                  for(let editEvent of data) {
                    if (editEvent.activityId == act.id) {
                      act.levelEdited = editEvent.levelBefore == editEvent.levelAfter ? false : true;
                      act.setsEdited = editEvent.setsBefore == editEvent.setsAfter ? false : true;
                      act.repetitionsEdited = editEvent.repetitionsBefore == editEvent.repetitionsAfter ? false : true;
                      break;
                    }
                  };
                }
                this.filterActivityTypes(this.stateTypes.indexOf(this.currentState));
                this.dataSource = this.getActivitiesAsExpandableElements();
              }
            }
          );
        }
      }
    );
  }

  getActivitiesAsExpandableElements() {
    var tmp = new Array();
    let isAdded;
    for (let act of this.activities) {
      isAdded = false;
      for(let element of tmp) {
        if (element.date == act.date) {
          element.numberOfExercises++;
          element.exercises.push(act);
          isAdded = true;
          break;
        }
      }
      if (isAdded == false) {
        tmp.push(
          {
            date: act.date, 
            numberOfExercises: 1,
            exercises: [act]
          }
        );
      }
    }
    return tmp;
  }

  // Does not compare hours!
  isDateDifferent(date1:Date, date2:Date) {
    if (date1.getFullYear() != date2.getFullYear() 
      || date1.getMonth() != date2.getMonth()
      || date1.getDate() != date2.getDate()) {
      return true;
    }
    return false;
  }

  viewFullInfo(activity: any) {
    let dialogRef;
    if (activity.dbName == "CATAssessment") {
      let questions = this.gi.getCATQuestionair();
      if (activity.results && activity.results[0]) {
        for (let i = 0; i < activity.results[0].assessmentAnswers.length; i++) {
          questions[i].currentValue = activity.results[0].assessmentAnswers[i];
        }
      }
      let sum = 0;
      for (let q of questions) {
        if (q.currentValue >= 0) {
          sum = sum + parseInt(q.currentValue);
        }
      }
      let planned = activity.completed ? false : activity.dateHasPassed ? false : true;
      dialogRef = this.dialog.open(ResultsViewDialog, {
        width: 'fit-content',
        data: {
          assessment: true, name: activity.name, date: activity.date, comment: activity.comment, questionair: questions, sum: sum, id: activity.id,
          dbName: activity.dbName, scheduledFor: activity.scheduledFor, variant: activity.variant, settings: activity.settings, planned: planned
        }
      });
    }
    else if (activity.dbName == "mMRCAssessment") {
      let questions = this.gi.getmMRCQuestionair();
      let sum = activity.results[0] != null ? activity.results[0].score : 0;
      let planned = activity.completed ? false : activity.dateHasPassed ? false : true;
      dialogRef = this.dialog.open(ResultsViewDialog, {
        width: 'fit-content',
        data: {
          assessment: true, name: activity.name, date: activity.date, comment: activity.comment, questionair: questions, sum: sum,
          id: activity.id, dbName: activity.dbName, scheduledFor: activity.scheduledFor, variant: activity.variant, settings: activity.settings, planned: planned
        }
      });
    }
    else {
      let sets = new Array();
      let measure = false;
      let UsesBorg = false;
      let pulseGraph = false;
      let saturationGraph = false;
      for (let i = 0; i < activity.sets; i++) {
        if (activity.results && activity.results[i]) {
          let plotDataSat;
          let plotDataPulse;
          if ( activity.results[i].saturation != null && activity.results[i].saturation.length > 0 && this.notAllValuesZero(activity.results[i].saturation)) {
            saturationGraph = true;
            var plotValuesSat = new Array();
            for (let x = 0; x < activity.results[i].saturation.length; x++) {
              plotValuesSat.push({
                "label": x,
                "value": activity.results[i].saturation[x]
              });
            }
            plotDataSat = [{
              key: " %",
              values: plotValuesSat
            }];
          }
          if (activity.results[i].pulse != null && activity.results[i].pulse.length > 0 && this.notAllValuesZero(activity.results[i].pulse)) {
            pulseGraph = true;
            var plotValues = new Array();
            for (let x = 0; x < activity.results[i].saturation.length; x++) {
              plotValues.push({
                "label": x,
                "value": activity.results[i].pulse[x]
              });
            }
            plotDataPulse = [{
              key: " bpm",
              values: plotValues
            }];
          }
          let duration = this.gameTime((activity.results[i].activityEnded - activity.results[i].activityStarted) / 1000);
          sets.push({
            level: activity.level == 0 ? "--" : activity.level,
            time: duration,
            score: activity.results[i].score,
            breath: activity.results[i].vasBreath == 0 ? "--" : activity.results[i].vasBreath,
            effort: activity.results[i].vasEffort == 0 ? "--" : activity.results[i].vasEffort,
            rpe: activity.results[i].rpe == 0 ? "--" : activity.results[i].rpe,
            cr10: activity.results[i].cr10 == 0 ? "--" : activity.results[i].cr10, 
            saturationPlotData: plotDataSat, 
            pulsePlotData: plotDataPulse, 
            planned: false,
            completed: true,
            info: activity.results[i].additionalText == null || activity.results[i].additionalText == ""  ? 
              'Poäng: ' + activity.results[i].score + "/" + activity.results[i].possibleScore : 
              activity.results[i].additionalText
          });
          if (activity.results[i].vasBreath != 0 || activity.results[i].vasEffort != 0){
            measure = true;
          }
          if (activity.results[i].rpe != 0 || activity.results[i].cr10 != 0){
            UsesBorg = true;
          }
        }
        else {
          sets.push({
            level: activity.level == 0 ? "--" : activity.level,
            time: "--",
            score: "--",
            breath: "--",
            effort: "--",
            planned: !activity.dateHasPassed,
            completed: false
          });
        }
      }

      dialogRef = this.dialog.open(ResultsViewDialog, {
        width: 'fit-content',
        data: {
          assessment: false, name: activity.name, date: activity.date, comment: activity.comment, activities: sets, id: activity.id,
          dbName: activity.dbName, scheduledFor: activity.scheduledFor, variant: activity.variant, settings: activity.settings, vas: measure
          , borg: UsesBorg, hasPulseGraph: pulseGraph, hasSaturationGraph: saturationGraph
        }
      });
    }

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.setupCompleteTable();
        this.snackBar.open('Övningar ändrade!', 'Sparat', { duration: 4500 });
      }
    });
    return false;
  }

  private notAllValuesZero(listPossiblyAllZeros : number[]) {
    let allZeroes = true;
    listPossiblyAllZeros.forEach(element => {
      if (element != 0) allZeroes = false;
    });
    return !allZeroes;
  }

  private gameTime(time: number): string {
    if (time / 60 > 1) {
      return Math.round(time / 60).toString() + ' min ' + Math.round(time % 60) + ' s';
    }
    else if (time <= 0) {
      return "--";
    }
    return Math.round(time) + ' s';
  }
}



@Component({
  selector: 'results-view',
  templateUrl: 'results-view.html',
  styleUrls: ['./overview-activity.component.css']
})
export class ResultsViewDialog {
  checkpoint: boolean = false;
  deleteAll: boolean = false;
  erase: boolean = false;

  plotDataSat: any;
  options: any;

  constructor(
    public dialogRef: MatDialogRef<ResultsViewDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any, private dataservice: DataserviceService) {
      this.setupChartOptionsUndefined();
    }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  checkDelete(showWindow: boolean, deleteAll: boolean) {
    this.checkpoint = showWindow;
    this.erase = showWindow;
    this.deleteAll = deleteAll;
  }

  checkSettings(change: boolean) {
    this.checkpoint = change;
    this.erase = !change;
  }

  changeSettings() {
    let body = {
      activityName: [this.data.dbName],
      settings: this.data.settings,
      variant: this.data.variant
    }
    this.dataservice.changeSettingsOfActivities(body).subscribe(
      (resp) => {
        if (resp.status == 200) {
          this.checkpoint = false;
          this.erase = false;
          this.dialogRef.close(true);
        }
      });
  }

  deleteActivities() {
    this.checkpoint = false;
    if (this.deleteAll) {
      let deleteAct = {
        activityName: [this.data.dbName],
        settings: this.data.settings,
        variant: this.data.variant.toLocaleUpperCase()
      };
      this.dataservice.deleteAllOfSameType(deleteAct).subscribe(
        (resp) => {
          if (resp.status == 200) {
            this.dialogRef.close(true);
          }
        });
    }
    else {
      let deleteAct = {
        id: this.data.id,
        activityName: [this.data.dbName],
        scheduledFrom: this.data.scheduledFor,
        variant: this.data.variant.toLocaleUpperCase()
      };
      this.dataservice.deleteSingleActivity(deleteAct).subscribe(
        (resp) => {
          if (resp.status == 200) {
            this.dialogRef.close(true);
          }
        });
    }
  }

  private setupChartOptionsUndefined() {
    this.options = {
      chart: {
        type: 'lineChart',
        showLegend: false,
        height: 350,
        width: 300,
        x: function (d) { return d.label; },
        y: function (d) { return d.value; },
        useInteractiveGuideline: true,
        clipVoronoi: false,
        xAxis: {
          tickFormat: function (d) {
            return d;
          },
          showMinMax: false
        },
        yAxis: {
          tickFormat: function (d) {
            return d.toFixed(1);
          }
        },
        interactiveLayer: {
          tooltip: {
            contentGenerator: function (record) {
              var str = '<p>' + "Värde Nr: " + record.series[0].data.label + '<br />';
              str = str + '<span style="background-color:' + record.series[0].color + ';display: inline-block; height:12px; width:12px;vertical-align: middle;"></span>&nbsp;' + record.series[0].value + record.series[0].key + '<br />';
              return str + '</p>';
            },
            headerEnabled: true,
          }
        }
      }
    }
  }
}

export interface ExpandedElement {
  date: string;
  numberOfExercises: number;
  exercises: Array<any>
}