import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions, Response, ResponseContentType } from '@angular/http';
import { Observable } from 'rxjs/Rx';

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

@Injectable()
export class DataserviceService {
  username: string;
  care_instance: string;
  care_unit_name: string;
  patient: string = "Ingen vald";
  patientObject: any;
  url: string;
  serverURL: string = "https://kol.alkit.se/ReactServer/servlet/";//'https://kol.alkit.se/ReactServer/servlet/'; //"http://mandolin:4400/ReactServer/servlet/"; //'http://localhost:8080/ReactServer/servlet/';
  // serverURL: string = "http://kol-demo.alkit.se/ReactServer/servlet/";
  // serverURL: string = "http://192.168.0.174:4400/ReactServer/servlet/";
  auth_token: string;
  identification: string;
  userImg: string = null;

  constructor(public http: Http) {
  }

  setCareUnit(unit: string){
    if (unit == "Angered"){
      this.care_instance = 'angered';
    }  
    else if (unit == 'Skövde'){
      this.care_instance = 'skovde';
    }  
    else if (unit == 'Sahlgrenska'){
      this.care_instance = 'sahlgrenska';    
    } 
    else if (unit == 'Alkit'){
      this.care_instance = 'alkit';
    }   
    this.care_unit_name = unit;
  }

  logInCall(user: string, pw: string): Observable<any> {
    this.username = user;
    this.identification = pw;

    let body = {
      username: user,
      password: pw,
      instance: this.care_instance
    };

    // Set request header and send request.
    let headers = new Headers({ 'Access-Control-Allow-Origin': '*' });
    let options = new RequestOptions({ headers: headers });
    return this.http.post(this.serverURL + 'login', body, options)
      .map(this.serverResponse)
      .catch((error: any) => Observable.of(error));
  }

  logOutCall(): Observable<any> {
    let token = this.auth_token;
    this.username = null;
    this.auth_token = null;
    let headers = new Headers({ 'content-type': 'application/json', 'auth-token': token });
    return this.http.get(this.serverURL + 'logout', { headers: headers })
      .map(this.serverResponse)
      .catch((error: any) => Observable.of(error));
  }

  changePassword(body: any){
    let headers = new Headers({ 'content-type': 'application/json', 'auth-token': this.auth_token });
    return this.http.post(this.serverURL + 'changeUserState', body, { headers: headers })
      .map(this.serverResponse)
      .catch((error: any) => Observable.of(error));
  }

  pingServer() {
    let headers = new Headers({ 'content-type': 'application/json', 'auth-token': this.auth_token });
    return this.http.get(this.serverURL + 'ping', { headers: headers })
      .map(this.checkStatus)
      .catch((error: any) => Observable.of(error.status));
  }

  findNextExercise() {
    let headers = new Headers({ 'content-type': 'application/json', 'auth-token': this.auth_token });
    return this.http.get(this.serverURL + 'nextExerciseDate', { headers: headers })
      .map(this.serverResponse)
      .catch((error: any) => Observable.of(error));
  }

  getResultsOfDay(variant: any) {
    let now = new Date();
    let from = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0).getTime();
    let body = JSON.stringify({
      from: from,
      until: from + (1000 * 60 * 60 * 24),
      variant: variant
    });
    let headers = new Headers({ 'content-type': 'application/json', 'auth-token': this.auth_token });
    return this.http.post(this.serverURL + 'resultBetweenDates', body, { headers: headers })
      .map(this.serverResponse)
      .catch((error: any) => Observable.of(error));
  }

  getResultsOfMonth(from: Date, until: Date, variant: string) {
    let body = {
      from: from.getTime(),
      until: until.getTime(),
      variant: variant
    };
    return this.getActivityResultsBetweenDates(body);
  }

  getActivityResultsBetweenDates(body: any) {
    let headers = new Headers({ 'content-type': 'application/json', 'auth-token': this.auth_token });
    return this.http.post(this.serverURL + 'resultBetweenDates', body, { headers: headers })
      .map(this.serverResponse)
      .catch((error: any) => Observable.of(error));
  }

  lastReportedExerciseDate() {
    let headers = new Headers({ 'content-type': 'application/json', 'auth-token': this.auth_token });
    return this.http.get(this.serverURL + 'lastReportedExerciseDate', { headers: headers })
      .map(this.serverResponse)
      .catch((error: any) => Observable.of(error));
  }

  getTodaysActivities() {
    let headers = new Headers({ 'content-type': 'application/json', 'auth-token': this.auth_token });
    return this.http.get(this.serverURL + 'todaysActivities', { headers: headers })
      .map(this.serverResponse)
      .catch((error: any) => Observable.of(error));
  }

  getPlannedActivitiesBetweenDates(body: any) {
    let headers = new Headers({ 'content-type': 'application/json', 'auth-token': this.auth_token });
    return this.http.post(this.serverURL + 'activitiesBetweenDates', body, { headers: headers })
      .map(this.serverResponse)
      .catch((error: any) => Observable.of(error));
  }

  createNewPatientUser(body: any) {
    let headers = new Headers({ 'content-type': 'application/json', 'auth-token': this.auth_token });
    return this.http.post(this.serverURL + 'createPatientUser', body, { headers: headers })
      .map(this.serverResponse)
      .catch((error: any) => Observable.of(error));
  }

  deletePatientUser(body: any) {
    let headers = new Headers({ 'content-type': 'application/json', 'auth-token': this.auth_token });
    return this.http.post(this.serverURL + 'deletePatientUser', body, { headers: headers })
      .map(this.serverResponse)
      .catch((error: any) => Observable.of(error));
  }

  getPatientList() {
    let headers = new Headers({ 'content-type': 'application/json', 'auth-token': this.auth_token });
    return this.http.get(this.serverURL + 'patientList', { headers: headers })
      .map(this.serverResponse)
      .catch((error: any) => Observable.of(error));
  }

  getPatientInformation(id: number) {
    let body = {
      id: id
    };
    let headers = new Headers({ 'content-type': 'application/json', 'auth-token': this.auth_token });
    return this.http.post(this.serverURL + 'getDateInformation', body, { headers: headers })
      .map(this.serverResponse)
      .catch((error: any) => Observable.of(error));
  }

  getFullInformation(body: any) {    
    let headers = new Headers({ 'content-type': 'application/json', 'auth-token': this.auth_token });
    return this.http.post(this.serverURL + 'fullInformation', body, { headers: headers })
      .map(this.serverResponse)
      .catch((error: any) => Observable.of(error));
  }

  getStepsDataBetweenDates(body: any) {    
    let headers = new Headers({ 'content-type': 'application/json', 'auth-token': this.auth_token });
    return this.http.post(this.serverURL + 'getStepsDataBetweenDates', body, { headers: headers })
      .map(this.serverResponse)
      .catch((error: any) => Observable.of(error));
  }

  getActiveMinutesDataBetweenDates(body: any) {    
    let headers = new Headers({ 'content-type': 'application/json', 'auth-token': this.auth_token });
    return this.http.post(this.serverURL + 'getActiveMinutesBetweenDates', body, { headers: headers })
      .map(this.serverResponse)
      .catch((error: any) => Observable.of(error));
  }

  getExtremePoxValuesBetweenDates(body: any) {
    let headers = new Headers({ 'content-type': 'application/json', 'auth-token': this.auth_token });
    return this.http.post(this.serverURL + 'getExtremePoxValuesBetweenDates', body, { headers: headers })
      .map(this.serverResponse)
      .catch((error: any) => Observable.of(error));
  }

  getAllActivitiesThatInitiatedEditBetweenDates(body: any) {
    let headers = new Headers({ 'content-type': 'application/json', 'auth-token': this.auth_token });
    return this.http.post(this.serverURL + 'allEditsBetweenDates', body, { headers: headers })
      .map(this.serverResponse)
      .catch((error: any) => Observable.of(error));
  }
  
  selectActivePatient(body: any) {
    let headers = new Headers({ 'content-type': 'application/json', 'auth-token': this.auth_token });
    return this.http.post(this.serverURL + 'chooseActivePatient', body, { headers: headers })
      .map(this.serverResponse)
      .catch((error: any) => Observable.of(error));
  }

  scheduleActivity(body: any) {
    let headers = new Headers({ 'content-type': 'application/json', 'auth-token': this.auth_token });
    return this.http.post(this.serverURL + 'planActivity', body, { headers: headers })
      .map(this.serverResponse)
      .catch((error: any) => Observable.of(error));
  }

  getAllActivitiesBetweenDates(body: any) {
    let headers = new Headers({ 'content-type': 'application/json', 'auth-token': this.auth_token });
    return this.http.post(this.serverURL + 'allActivitiesBetweenDates', body, { headers: headers })
      .map(this.serverResponse)
      .catch((error: any) => Observable.of(error));
  }

  changeSettingsOfActivities(body: any) {
    let headers = new Headers({ 'content-type': 'application/json', 'auth-token': this.auth_token });
    return this.http.post(this.serverURL + 'changeSettings', body, { headers: headers })
      .map(this.serverResponse)
      .catch((error: any) => Observable.of(error));
  }

  deleteSingleActivity(body: any) {
    let headers = new Headers({ 'content-type': 'application/json', 'auth-token': this.auth_token });
    return this.http.post(this.serverURL + 'deleteSingleActivity', body, { headers: headers })
      .map(this.serverResponse)
      .catch((error: any) => Observable.of(error));
  }

  deleteAllOfSameType(body: any) {
    let headers = new Headers({ 'content-type': 'application/json', 'auth-token': this.auth_token });
    return this.http.post(this.serverURL + 'deleteAllOfSameTypeActivity', body, { headers: headers })
      .map(this.serverResponse)
      .catch((error: any) => Observable.of(error));
  }

  getPlanningSettings() {
    let headers = new Headers({ 'content-type': 'application/json', 'auth-token': this.auth_token });
    return this.http.get(this.serverURL + 'getPlanningSettings', { headers: headers })
      .map(this.serverResponse)
      .catch((error: any) => Observable.of(error));
  }

  setPlanningSettings(body: any) {
    let headers = new Headers({ 'content-type': 'application/json', 'auth-token': this.auth_token });
    return this.http.post(this.serverURL + 'setPlanningSettings', body, { headers: headers })
      .map(this.serverResponse)
      .catch((error: any) => Observable.of(error));
  }

  getPreBuiltPlan() {
    let headers = new Headers({ 'content-type': 'application/json', 'auth-token': this.auth_token });
    return this.http.get(this.serverURL + 'getPreBuiltPlan', { headers: headers })
      .map(this.serverResponse)
      .catch((error: any) => Observable.of(error));
  }

  savePreBuiltPlan(body: any) {
    let headers = new Headers({ 'content-type': 'application/json', 'auth-token': this.auth_token });
    return this.http.post(this.serverURL + 'setPreBuiltPlan', body, { headers: headers })
      .map(this.serverResponse)
      .catch((error: any) => Observable.of(error));
  }

  getConferoSessionUrl() {
    let headers = new Headers({ 'content-type': 'application/json', 'auth-token': this.auth_token });
    return this.http.get(this.serverURL + 'conferoSession', { headers: headers })
      .map(this.serverResponse)
      .catch((error: any) => Observable.of(error));
  }

  downloadExcelFileForPatient(): Observable<any>{// TODO Detta borde fungera...
    let headers = new Headers({ 'content-type': 'application/json', 'auth-token': this.auth_token, 'Access-Control-Allow-Origin': '*' });
		return this.http.post(this.serverURL + 'getDataAsExcelForPatient', this.patient, {responseType: ResponseContentType.Blob, headers: headers});
  }

  downloadConfero(): Observable<any>{// TODO Detta borde fungera...
    let headers = new Headers({ 'content-type': 'application/json', 'auth-token': this.auth_token, 'Access-Control-Allow-Origin': '*' });
		return this.http.post(this.serverURL + 'downloadConferoSessionFile', this.patient, {responseType: ResponseContentType.Blob, headers: headers});
  }


  // Measurments


  getDiariesFromDate(date:number) {
    let body = {
      from: date,
      until: 0,
      variant: 'ASSESSMENT'
    };
    let headers = new Headers({ 'content-type': 'application/json', 'auth-token': this.auth_token });
    return this.http.post(this.serverURL + 'diariesFromDate', body, { headers: headers })
      .map(this.serverResponse)
      .catch((error: any) => Observable.of(error));
  }

  getParameterData(from: Date, until: Date) {
    let body = {
      from: Date.UTC(from.getFullYear(), from.getMonth(), from.getDate()),
      until: Date.UTC(until.getFullYear(), until.getMonth(), until.getDate(), 23, 59, 59),
      variant: "ASSESSMENT"
    };
    let headers = new Headers({ 'content-type': 'application/json', 'auth-token': this.auth_token });
    return this.http.post(this.serverURL + 'diariesBetweenDates', body, { headers: headers })
      .map(this.serverResponse)
      .catch((error: any) => Observable.of(error));
  }






  private checkStatus(res: Response) {
    let reply = res.status;
    return reply;
  }

  private serverResponse(res: Response) {
    return res;
  }

  daysInMonth(month: number): number {
    if (month == -1 || month == 1 || month == 3 || month == 5 || month == 7 || month == 8 || month == 10 || month == 12) {
      return 31;
    }
    else if (month == 2) {
      let year = new Date().getFullYear();
      if (((year % 4 == 0) && (year % 100 != 0)) || (year % 400 == 0)) {
        return 29;
      }
      return 28;
    }
    return 30;
  }

  shortMonthName(month: number): string {
    if (month == 0) {
      return 'Jan';
    }
    else if (month == 1) {
      return 'Feb';
    }
    else if (month == 2) {
      return 'Mar';
    }
    else if (month == 3) {
      return 'Apr';
    }
    else if (month == 4) {
      return 'Maj';
    }
    else if (month == 5) {
      return 'Jun';
    }
    else if (month == 6) {
      return 'Jul';
    }
    else if (month == 7) {
      return 'Aug';
    }
    else if (month == 8) {
      return 'Sep';
    }
    else if (month == 9) {
      return 'Okt';
    }
    else if (month == 10) {
      return 'Nov';
    }
    else if (month == 11) {
      return 'Dec';
    }
  }

  monthName(month: number): string {
    if (month == 0) {
      return 'Januari';
    }
    else if (month == 1) {
      return 'Februari';
    }
    else if (month == 2) {
      return 'Mars';
    }
    else if (month == 3) {
      return 'April';
    }
    else if (month == 4) {
      return 'Maj';
    }
    else if (month == 5) {
      return 'Juni';
    }
    else if (month == 6) {
      return 'Juli';
    }
    else if (month == 7) {
      return 'Augusti';
    }
    else if (month == 8) {
      return 'September';
    }
    else if (month == 9) {
      return 'Oktober';
    }
    else if (month == 10) {
      return 'November';
    }
    else if (month == 11) {
      return 'December';
    }
  }
}
