import { Component, OnInit, Inject, ViewChild, ElementRef, Injectable } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { DataserviceService } from '../../services/dataservice.service';
import { GameInformationService } from '../../services/game-information.service';
import { MessageHandlerService } from '../../services/message-handler.service';
import { NgbDate, NgbCalendar, NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material';

const I18N_VALUES = {
  'se': {
    weekdays: ['Må', 'Ti', 'On', 'To', 'Fr', 'Lö', 'Sö'],
    months: ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Juni', 'Juli', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  }
  // other languages you would support
};

// Define a service holding the language. You probably already have one if your app is i18ned. Or you could also
// use the Angular LOCALE_ID value
@Injectable()
export class I18n {
  language = 'se';
}

// Define custom service providing the months and weekdays translations
@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {

  constructor(private _i18n: I18n) {
    super();
  }

  getWeekdayShortName(weekday: number): string {
    return I18N_VALUES[this._i18n.language].weekdays[weekday - 1];
  }
  getMonthShortName(month: number): string {
    return I18N_VALUES[this._i18n.language].months[month - 1];
  }
  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }
}

export interface Activity {
  game: any;
  id: number;
  level: number;
  repetitions: number;
  sets: number;
  satAndPulse: boolean;
  effortScale: boolean;
  breathScale: boolean;
  effortVAS: boolean;
  breathVAS: boolean;
}

@Component({
  selector: 'app-plan-activity',
  templateUrl: './plan-activity.component.html',
  styleUrls: ['./plan-activity.component.css'],
  providers: [I18n, { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n }] // define custom NgbDatepickerI18n provider
})
export class PlanActivityComponent implements OnInit {
  @ViewChild('scroller', { static: true }) private myScrollContainer: ElementRef;
  newMessage: boolean = false;
  showWarning: boolean = false;

  settingPage: boolean = false;
  shownGames: InformationContent[] = new Array();
  choosenGames: any[];
  days: string[] = ["Mån", "Tis", "Ons", "Tor", "Fre", "Lör", "Sön", "Alla"];
  choosenDays: boolean[] = new Array(8).fill(false);
  set: number = 2;
  repetioner: number = 10;
  level: number = 3;
  defaultDaySpan: number = 14;
  measureSatAndPul: boolean = false;
  performVasBreath: boolean = false;
  performVasEffort: boolean = false;
  performRpe: boolean = false;
  performCR10: boolean = false;

  calendarContent: any[];
  calenderWeeks1: any[];
  calenderWeeks2: any[];
  startDate: string;
  endDate: string;
  startMonth: string;
  endMonth: string;
  fromDate: Date;
  untilDate: Date;

  hoveredDate: NgbDate; // NgbDate is a date for the calendar
  model: NgbDateStruct;

  mObs;

  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};

  displayedColumns: string[] = ['name', 'level', 'sets', 'repetitions', 'satAndPulse', 'effortScale', 'breathScale', 'effortVAS', 'breathVAS'];
  gamesToPlan: Activity[] = [];
  dataSource = new MatTableDataSource<Activity>(this.gamesToPlan);
  selection = new SelectionModel<Activity>(true, this.gamesToPlan);

  assigned: string = '';

  constructor(private dataservice: DataserviceService, private router: Router, private gi: GameInformationService,
    private dialog: MatDialog, public snackBar: MatSnackBar, private mhs: MessageHandlerService, calendar2: NgbCalendar) {
    this.fromDate = this.NgbDateToJSDate(calendar2.getToday());
    this.untilDate = this.NgbDateToJSDate(calendar2.getNext(calendar2.getToday(), 'd', this.defaultDaySpan));
  }

  onDateSelection(ngbDate: NgbDate) {
    let date = this.NgbDateToJSDate(ngbDate);
    if (!this.fromDate && !this.untilDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.untilDate && ngbDate.after(this.JSDateToNgbDate(this.fromDate))) {
      this.untilDate = date;
    } else {
      this.untilDate = null;
      this.fromDate = date;
    }
  }

  NgbDateToJSDate(ngbDate: NgbDate) {
    let date = new Date();
    date.setDate(ngbDate.day);
    date.setMonth(ngbDate.month - 1);
    date.setFullYear(ngbDate.year);
    return date;
  }

  JSDateToNgbDate(date: Date) {
    if (date == null) return null;
    let ngbDate = new NgbDate(date.getFullYear(), date.getMonth() + 1, date.getDate());
    return ngbDate;
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.untilDate && this.hoveredDate && date.after(this.JSDateToNgbDate(this.fromDate))
      && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return date.after(this.JSDateToNgbDate(this.fromDate)) && date.before(this.JSDateToNgbDate(this.untilDate));
  }

  isRange(date: NgbDate) {
    return date.equals(this.JSDateToNgbDate(this.fromDate)) || date.equals(this.JSDateToNgbDate(this.untilDate)) || this.isInside(date) || this.isHovered(date);
  }

  isWeekdayChosen(date: NgbDate) {
    let jsDate = this.NgbDateToJSDate(date);
    if (this.choosenDays[7]) return true;
    let dayNumber = jsDate.getDay();
    if (dayNumber == 0) dayNumber = 7;
    dayNumber--;
    return this.choosenDays[dayNumber]
  }

  isToday(date: NgbDate) {
    var todayDate = new Date();
    if (date.day == todayDate.getDate()
      && date.month == (todayDate.getMonth() + 1)
      && date.year == todayDate.getFullYear()) {
      return true;
    }
    return false;
  }

  ngOnInit() {
    this.showAllGames();
    this.showWarning = this.dataservice.patient == "Ingen vald";

    let availBene: any[] = this.gi.availableBenefits;

    availBene.sort((a: any, b: any) => {
      return a.name > b.name ? 1 : -1
    });

    for (let i = 0; i < availBene.length; i++) {
      this.dropdownList.push(
        { item_id: availBene[i].index, item_text: availBene[i].name }
      );
    }
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Välj Alla',
      unSelectAllText: 'Välj Alla',
      itemsShowLimit: 2,
      allowSearchFilter: true,
      searchPlaceholderText: "Sök"
    };
    console.log('OnInit=', this.assigned);
  }

  SatAndPulseChanged(model: any, ID: any) {
    for (let game of this.gamesToPlan) {
      if (game.id == ID) {
        game.satAndPulse = model.checked;
        break;
      }
    }
  }

  EffortScaleChanged(model: any, ID: any) {
    for (let game of this.gamesToPlan) {
      if (game.id == ID) {
        game.effortScale = model.checked;
        break;
      }
    }
  }

  BreathScaleChanged(model: any, ID: any) {
    for (let game of this.gamesToPlan) {
      if (game.id == ID) {
        game.breathScale = model.checked;
        break;
      }
    }
  }

  EffortVASChanged(model: any, ID: any) {
    for (let game of this.gamesToPlan) {
      if (game.id == ID) {
        game.effortVAS = model.checked;
        break;
      }
    }
  }

  BreathVASChanged(model: any, ID: any) {
    for (let game of this.gamesToPlan) {
      if (game.id == ID) {
        game.breathVAS = model.checked;
        break;
      }
    }
  }

  Assign() {
    //Here i have code to send selected id of checkboxes to my API
    console.log('OnSubmitClick=', this.assigned);
  }

  filterGamesOnBenefit() {
    if (this.selectedItems.length == 0) {
      this.showAllGames();
      return;
    }
    this.shownGames.splice(0, this.shownGames.length);
    let showGame: boolean;
    this.gi.allGameInformation.forEach(game => {
      showGame = true;
      this.selectedItems.forEach(selectedGameItem => {
        if (game.benefitSearchIndexes.includes(selectedGameItem.item_id) == false) {
          showGame = false;
          return;
        }
      });
      if (showGame) this.shownGames.push(game);
    });
  }

  showAllGames() {
    this.shownGames = Object.assign([], this.gi.allGameInformation);
  }
  onItemSelect(item: any) {
    this.filterGamesOnBenefit();
  }
  onItemDeSelect(item: any) {
    this.filterGamesOnBenefit();
  }
  onSelectAll(items: any) {
    this.showAllGames();
  }

  scheduleActivities() {

    let now = new Date();
    let today = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0).getTime();
    if (this.fromDate.getTime() > this.untilDate.getTime() || this.fromDate.getTime() < today) {
      this.snackBar.open('Icke tillåtet datum interval!', 'Error', { duration: 4500 });
      return false;
    }
    let days = new Array();
    for (let i = 0; i < 7; i++) {
      if (this.choosenDays[i]) {
        days.push(i + 1);
      }
    }

    for (let game of this.gamesToPlan) {
      let settings = {
        level: game.level,
        sets: game.sets,
        repetitions: game.repetitions,
        measureSatAndPul: game.satAndPulse,
        performVasBreath: game.breathVAS,
        performVasEffort: game.effortVAS,
        performRpe: game.effortScale,
        performCR10: game.breathScale,
        positionType: this._getDbPosName(game.game.activePos)
      }
      let names = new Array();
      names.push(game.game.dbName);

      let body = {
        activityName: names,
        scheduledFrom: this.fromDate.getTime(),
        scheduledUntil: this.untilDate.getTime(),
        daysOfWeek: days,
        settings: settings,
        variant: "EXERCISE",
      }
      this.dataservice.scheduleActivity(body).subscribe(
        (resp) => {
          if (resp.status == 200) {
            this.snackBar.open('Övningar planerade!', 'Sparat', { duration: 4500 });
            this.goBack();
          }
        });
    }
  }

  private _getDbPosName(activePos: any) {
    if (activePos == "Sittande") {
      return "SITTING"
    }
    else if (activePos == "Stående") {
      return "STANDING"
    }
    else {
      return "NONE"
    }
  }

  getTodayDate() {
    let now = new Date();
    return new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0);
  }

  setupSettingPage() {
    if (this.showWarning) {
      this.snackBar.open('Du måste välja en användare först!', 'ERROR', { duration: 4500 });
      return false;
    }
    this.gamesToPlan = [];
    let i: number = 1;
    for (let game of this.shownGames) {
      if (game.choosen) {
        let activity: Activity = {
          game: game,
          id: i++,
          level: 3,
          repetitions: 10,
          sets: 1,
          satAndPulse: false,
          breathScale: false,
          breathVAS: false,
          effortScale: false,
          effortVAS: false
        }
        this.gamesToPlan.push(activity);
      }
    }
    if (this.gamesToPlan.length < 1) {
      this.snackBar.open('Du måste välja minst en övning att planera!', 'ERROR', { duration: 4500 });
      return false;
    }
    this.dataSource = new MatTableDataSource<Activity>(this.gamesToPlan);
    this.selection = new SelectionModel<Activity>(true, this.gamesToPlan);
    this.dataservice.getPlanningSettings().subscribe(
      (resp) => {
        this.settingPage = true;
        this.myScrollContainer.nativeElement.scrollTop = 0;
        if (resp.status == 200) {
          let settings = resp.json();
          if (settings.fromDate != 0 && settings.untilDate != 0) {
            // Setup the calendars to match the saved settings.
            let today = this.getTodayDate();
            if (new Date(settings.fromDate) >= today) {
              this.fromDate = new Date(settings.fromDate);
            } else {
              this.fromDate = today;
            }
            if (new Date(settings.untilDate) >= today) {
              this.untilDate = new Date(settings.untilDate);
            } else {
              this.untilDate = new Date(today.getTime() + 1000 * 60 * 60 * 24 * this.defaultDaySpan);
            }
            this.startMonth = this.dataservice.shortMonthName(this.fromDate.getMonth()) + ' (' + this.fromDate.getFullYear() + ')';
            this.endMonth = this.dataservice.shortMonthName(this.untilDate.getMonth()) + ' (' + this.untilDate.getFullYear() + ')';
            this.startDate = this.fromDate.getDate() + " " + this.dataservice.shortMonthName(this.fromDate.getMonth()) + " " + this.fromDate.getFullYear();
            this.endDate = this.untilDate.getDate() + " " + this.dataservice.shortMonthName(this.untilDate.getMonth()) + " " + this.untilDate.getFullYear();
          }
          for (let nr of settings.daysInWeek) {
            this.choosenDays[nr] = true;
          }
          if (settings.planningSettingsExercises != null) {
            for (let planningSettingsExercise of settings.planningSettingsExercises) {
              for (let game of this.gamesToPlan) {
                if (planningSettingsExercise.activityDbName == game.game.dbName) {
                  game.level = planningSettingsExercise.level;
                  game.sets = planningSettingsExercise.sets;
                  game.repetitions = planningSettingsExercise.repetitions;
                  game.satAndPulse = planningSettingsExercise.measureSatAndPulse;
                  game.breathVAS = planningSettingsExercise.vasBreath;
                  game.effortVAS = planningSettingsExercise.vasEffort;
                  game.breathScale = planningSettingsExercise.CR10;
                  game.effortScale = planningSettingsExercise.RPE;
                }
              }
            }
          }
        }
      });
    return false;
  }

  levelChanged(id: number, level: number) {
    for (let game of this.gamesToPlan) {
      if (game.id == id) {
        game.level = level;
        break;
      }
    }
  }

  setsChanged(id: number, sets: number) {
    for (let game of this.gamesToPlan) {
      if (game.id == id) {
        //game.sets = sets;
        break;
      }
    }
  }

  saveSettings() {
    let days = new Array();
    for (let i = 0; i < 7; i++) {
      if (this.choosenDays[i]) {
        days.push(i);
      }
    }
    let planningSettingsExercises = new Array();
    for (let game of this.gamesToPlan) {
      let planningSettingsExercise = {
        level: game.level,
        sets: game.sets,
        repetitions: game.repetitions,
        measureSatAndPulse: game.satAndPulse,
        vasBreath: game.breathVAS,
        vasEffort: game.effortVAS,
        CR10: game.breathScale,
        RPE: game.effortScale,
        activityDbName: game.game.dbName,
      };
      planningSettingsExercises.push(planningSettingsExercise);
    }
    let body = {
      fromDate: this.fromDate.getTime(),
      untilDate: this.untilDate.getTime(),
      daysInWeek: days,
      planningSettingsExercises: planningSettingsExercises
    }
    this.dataservice.setPlanningSettings(body).subscribe(
      (resp) => {
        if (resp.status == 200) {
          this.snackBar.open('Sparade inställningar för ' + this.dataservice.patient, 'Sparat', { duration: 4500 });
        }
      });
  }

  chooseDay(day: number) {
    if (day == 7) {
      this.choosenDays = new Array(8).fill(!this.choosenDays[7]);
      return false;
    }
    this.choosenDays[7] = false;
    this.choosenDays[day] = !this.choosenDays[day];
    return false;
  }

  selectPreBuiltPlan() {
    this.dataservice.getPreBuiltPlan().subscribe(
      (resp) => {
        if (resp.status == 200) {
          let preBuiltPlan = resp.json();
          for (let shownGame of this.shownGames) {
            shownGame.choosen = false;
          }
          for(let activity of preBuiltPlan.selectedActivityTypes) {
            for (let shownGame of this.shownGames) {
              if (activity == shownGame.dbName) {
                shownGame.choosen = true;
                break;
              }
            }
          }
        }
      });
  }

  updatePreBuiltPlan() {
    let chosenGames = new Array();
    for (let shownGame of this.shownGames) {
      if (shownGame.choosen) {
        chosenGames.push(shownGame.dbName);
      }
    }
    let body = {
      selectedActivityTypes: chosenGames
    }
    this.dataservice.savePreBuiltPlan(body).subscribe(
      (resp) => {
        if (resp.status == 200) {
         this.snackBar.open('Uppdaterade plan', 'Sparat', { duration: 1000 });
        }
      });
  }

  chooseExercise(activity: any) {
    activity.choosen = !activity.choosen;
    return false;
  }

  popupInfo(activity: any) {
    let dialogRef = this.dialog.open(ActivityInfoDialog, {
      width: 'auto',
      data: {
        name: activity.title, picture: activity.picture, information: activity.information[0], performance: activity.information[1],
        instruction: activity.information[2], feedback: activity.information[3], repetitions: activity.information[4]
      }
    });
    return false;
  }

  cleanField() {
    for (let game of this.shownGames) {
      game.choosen = false;
    }
    return false;
  }

  goBack() {
    for (let game of this.shownGames) {
      game.choosen = false;
    }
    this.settingPage = false;
    this.myScrollContainer.nativeElement.scrollTop = 0;
    return false;
  }
}

@Component({
  selector: 'activity-info',
  templateUrl: 'activity-info.html',
  styleUrls: ['./plan-activity.component.css']
})
export class ActivityInfoDialog {

  constructor(
    public dialogRef: MatDialogRef<ActivityInfoDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  onNoClick(): void {
    this.dialogRef.close();
  }
}

interface InformationContent {
  title: string;
  information: string[];
  picture: string;
  searchParameters: string;
  benefits: string;
  choosen: boolean;
  dbName: string;
}